<template>
  <div class="row" v-loading.fullscreen.lock="page_loading">

    <el-drawer title="Attention" :visible.sync="showDelete" direction="rtl" :before-close="handleClose">
      <div style="padding: 10px;">
        <p>Remove this room? <el-link type="danger" @click="deleteItem()" class="red-label">Yes, remove it!</el-link></p>

      </div>
    </el-drawer>

    <el-drawer :title="selected && selected.id ? 'Edit' : 'New'" :visible.sync="showForm" direction="rtl" :size="400"
      :before-close="handleClose">
      <div style="padding: 10px;">
        <meeting-room-form :room="selected" @update="getRooms()"></meeting-room-form>
        <!-- <plan-form :plan="selected" @update="getPlans()"></plan-form> -->
      </div>
    </el-drawer>


    <div class="col-md-12 card" v-loading="loading">
      <div class="card-header">
        <h5 >{{ type == 1 ? 'Desks' : 'Meeting Rooms'}}</h5>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="col-md-6">
          <p-button type="primary" size="sm" style="float:right" @click="handleNew()">
            <i class="fa fa-plus"></i> NEW
          </p-button>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="meeting_rooms" style="width: 100%">
            <el-table-column :min-width="120" class-name="td-actions" label="Name">
              <template slot-scope="props">
                {{ props.row.name }}
              </template>
            </el-table-column>
            <el-table-column :min-width="120" class-name="td-actions" label="Office">
              <template slot-scope="props">
                {{ props.row.office ? props.row.office.name : '' }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="Status">
              <template slot-scope="props">
                {{ props.row.status ? 'Active' : 'Inactive' }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="Rate">
                <template slot-scope="props">
                  $ {{ props.row.price }} per hour
                </template>
              </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="Capacity">
              <template slot-scope="props">
                {{ props.row.capacity }}
              </template>
            </el-table-column>

            <el-table-column :min-width="100" fixed="right" class-name="td-actions" label="Actions">
              <template slot-scope="props">
                <p-button type="primary" size="sm" icon @click="showRoom(props.row)">
                    <i class="fa fa-eye"></i>
                  </p-button>
                <p-button type="primary" size="sm" icon @click="handleEdit(props.row)">
                  <i class="fa fa-pencil"></i>
                </p-button>
                <p-button type="info" size="sm" icon @click="handleDelete(props.row)">
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { MeetingRoom } from '@/resources/meeting_room'

import PPagination from 'src/components/UIComponents/Pagination.vue'
import MeetingRoomForm from './MeetingRoomForm.vue'

export default {
  props: ['type'],
  components: {
    PPagination,
    MeetingRoomForm,
  },
  data() {
    return {
      desk_default_price: 30,
      meeting_room_default_price: 100,
      showDelete: false,
      showForm: false,
      meeting_rooms: [],
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0
      },
      selected: null,
      loading: false,
      term: null,
      page_loading: false
    }
  },
  created: function () {
    this.getRooms()
  },
  methods: {
    showRoom(room) {
      if (!this.type) this.$router.push({ name: 'MeetingRoomView', params: { id: room.id } })
      if (this.type) this.$router.push({ name: 'DeskReservationView', params: { id: room.id } })
    },
    handleClose() {
      this.page_loading = false
      this.showDelete = false
      this.showForm = false
    },
    handleDelete(role) {
      this.showDelete = true
      this.selected = Object.assign({}, role)
    },
    handleNew() {
      this.showForm = true
      this.selected = {
        room_type: this.type,
        price: this.type == 1 ? this.desk_default_price : this.meeting_room_default_price,
        type: this.type
      }
    },
    handleEdit(room) {
      this.showForm = true
      this.selected = Object.assign({}, room)
      this.room_type = this.type
    },
    deleteItem() {
      this.page_loading = true
      MeetingRoom.delete({ id: this.selected.id })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Removed successfully',
          });
          this.getRooms()
        })
        .catch(() => {
 
        }).finally(() => {
          this.loading = false
        })

    },
    getRooms() {
      this.handleClose()
      this.loading = true
      let params = {
        term: this.term, status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        type: this.type
      }
      MeetingRoom.get({ params: params})
        .then(result => {
          if (result.data.rooms)
            this.meeting_rooms = result.data.rooms
          this.pagination = result.data.meta
        })
        .catch(() => {

          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch rooms',
          });

        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
