<template>
  <div class="" v-loading.fullscreen.lock="loading">
    <el-drawer title="Pay Commission" :visible.sync="show_pay_commission" direction="rtl">
      <div style="padding: 10px;" v-if="user">
          <pay-commission :user="user" :total_unclaimed="total_unclaimed" @update="closePayCommission()"></pay-commission>
      </div>
    </el-drawer>

    <div class="row" v-if="user">
      <div class="col-md-12" v-if="!client">
        <el-link @click="$router.go(-1)" ><span class="orange-color"><i class="el-icon-back"></i> Back</span></el-link>
      </div>
      <div class="col-md-12" v-if="client">
        <h3>My Commissions</h3>
        </div>
      <div class="col-md-3">
        <el-card class="mb-2">
          <div>Referral Code: <span class="orange-color"> {{ code }}</span></div>
          <h4>{{ user.name }}</h4>
          <p>{{ user.email }}</p>
        </el-card>

        <el-button size="small" type="success" @click="showPayCommissionDrawer()" v-if="isAllowed('user_commissions', 'create')">Pay commission</el-button>

      </div>
      <div class="col-md-9">
        <div class="row">
          <div class="col-md-4">
            <el-card class="black-bg white-text">
              <div>Total Commissions</div>
              <h2> ${{ total_commissions }}</h2>
            </el-card>
          </div>
          <div class="col-md-4">
            <el-card class="orange-bg white-text">
              <div>Unclaimed Commissions</div>
              <h2> ${{ total_unclaimed }}</h2>
            </el-card>
          </div>
          <div class="col-md-4">
              <el-card class="green-bg white-text">
                <div>Claimed Commissions</div>
                <h2> ${{ total_claimed }}</h2>
              </el-card>
            </div>
          <div class="col-md-12">
            <user-yearly-commissions :code="code"></user-yearly-commissions>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { UserReferral } from '@/resources/user_referral'
import UserYearlyCommissions from './UserYearlyCommissions.vue'
import PayCommission from './PayCommission.vue'

export default {
  components: { UserYearlyCommissions, PayCommission },
  props:['client'],
  data() {
    return {
      loading: false,
      user: null,
      code: null,
      total_commissions: 0,
      total_claimed: 0,
      total_unclaimed: 0,
      show_pay_commission: false
    }
  },
  created: function () {
    this.code = this.$route.params.id
    if (this.client) this.code = this.client.referral_code
    this.getUserByReferralCode(this.code)
  },
  methods: {
    closePayCommission() {
      this.show_pay_commission = false
      this.getUserByReferralCode(this.code)
    },
    showPayCommissionDrawer() {
      this.show_pay_commission = true
    },
    close() {
      this.$emit('update', false)
    },
    getUserByReferralCode(code) {
      this.loading = true
      UserReferral.findByCode({ code: code, total:true, claimed:true, unclaimed:true })
        .then(result => {
          this.user = result.data.user
          this.total_commissions = result.data.total_commissions
          this.total_claimed = result.data.total_claimed
          this.total_unclaimed = result.data.total_unclaimed
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
  }
}
</script>
<style lang="scss"></style>
