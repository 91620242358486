<template>
  <ValidationObserver v-slot="{ handleSubmit }" v-loading="loading">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ form.id ? 'Update Plan' : 'Create new Plan' }}
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
              <label>Level</label>
              <ValidationProvider name="level" rules="required" v-slot="{ passed, failed }">
                <fg-input type="text" :error="failed ? 'The level field is required' : null" :hasSuccess="passed" name="level"
                  v-model="form.level">
                </fg-input>
              </ValidationProvider>
            </div>

          <div class="form-group">
            <label>Name</label>
            <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The name field is required' : null" :hasSuccess="passed" name="name"
                v-model="form.name">
              </fg-input>
            </ValidationProvider>
          </div>

          <div class="form-group">
              <label>Code</label>
              <ValidationProvider name="code" rules="required" v-slot="{ passed, failed }">
                <fg-input type="text" :error="failed ? 'The code field is required' : null" :hasSuccess="passed" name="code"
                  v-model="form.code">
                </fg-input>
              </ValidationProvider>
            </div>

          <div class="form-group">
            <label>Remarks</label>
            <ValidationProvider name="code" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The remarks field is required' : null" :hasSuccess="passed" name="remarks"
                v-model="form.remarks">
              </fg-input>
            </ValidationProvider>
          </div>

          <div class="form-group">
              <label>Setup Fee</label>
              <ValidationProvider name="setup_fee" v-slot="{ passed, failed }">
                <fg-input step=".01" type="number" :error="failed ? 'The setup fee field is required' : null" :hasSuccess="passed" name="setup_fee"
                  v-model="form.setup_fee">
                </fg-input>
              </ValidationProvider>
            </div>

          <div class="form-group">
            <label>Price per month</label>
            <ValidationProvider name="price_per_month" v-slot="{ passed, failed }">
              <fg-input step=".01" type="number" :error="failed ? 'The price per month field is required' : null" :hasSuccess="passed" name="price_per_month"
                v-model="form.price_per_month">
              </fg-input>
            </ValidationProvider>
          </div>

          <div class="form-group">
            <label>Price per year</label>
            <ValidationProvider name="price_per_year" v-slot="{ passed, failed }">
              <fg-input  step=".01" type="number" :error="failed ? 'The price per year field is required' : null" :hasSuccess="passed" name="price_per_year"
                v-model="form.price_per_year">
              </fg-input>
            </ValidationProvider>
          </div>

          <div class="form-group">
            <label>Mails per month</label>
            <ValidationProvider name="mails_per_month" v-slot="{ passed, failed }">
              <fg-input type="number" :error="failed ? 'The price per month field is required' : null" :hasSuccess="passed" name="mails_per_month"
                v-model="form.mails_per_month">
              </fg-input>
            </ValidationProvider>
          </div>

          <div class="form-group">
              <label>Forwarded mails per month</label>
              <ValidationProvider name="forwarded_mails_per_month" v-slot="{ passed, failed }">
                <fg-input type="number" :error="failed ? 'The price per month field is required' : null" :hasSuccess="passed" name="forwarded_mails_per_month"
                  v-model="form.forwarded_mails_per_month">
                </fg-input>
              </ValidationProvider>
            </div>

            <div class="form-group">
                <label>Scanned mails per month</label>
                <ValidationProvider name="scanned_mails_per_month" v-slot="{ passed, failed }">
                  <fg-input type="number" :error="failed ? 'The price per month field is required' : null" :hasSuccess="passed" name="scanned_mails_per_month"
                    v-model="form.scanned_mails_per_month">
                  </fg-input>
                </ValidationProvider>
              </div>

              <div class="form-group">
                  <label>Shredded mails per month</label>
                  <ValidationProvider name="shredded_mails_per_month" v-slot="{ passed, failed }">
                    <fg-input type="number" :error="failed ? 'The price per month field is required' : null" :hasSuccess="passed" name="shredded_mails_per_month"
                      v-model="form.shredded_mails_per_month">
                    </fg-input>
                  </ValidationProvider>
                </div>

            <div class="form-group">
              <label>Details</label>
              <div v-for="detail,i in form.plan_details" :key="i">
                <fg-input type="text"
                    v-model="detail.description">
                  </fg-input>
              </div>

              <div style="margin-top: 15px;">
                <el-input placeholder="Please input" v-model="new_detail.description" class="input-with-select">
                  <el-button slot="append" icon="el-icon-plus" @click="addDetail()">Add</el-button>
                </el-input>
              </div>
              
            </div>


        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Save</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>

import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { Plan } from '@/resources/plan'

extend("email", email);
extend("required", required);

export default {
  props: ['plan'],
  data() {
    return {
      form: {
        name: "",
        code: null,
        mails_per_month: 0,
        forwarded_mails_per_month: 0,
        scanned_mails_per_month: 0,
        shredded_mails_per_month: 0,
        setup_fee: 0,
        plan_details: []
      },
      new_detail: {
        description: null
      },
      loading: false
    };
  },
  created: function () {
    this.form = Object.assign({}, this.plan)
  },
  watch: {
    plan() {
      this.form = Object.assign({}, this.plan)
    }
  },
  methods: {
    addDetail() {
      let detail = Object.assign({}, this.new_detail)
      this.new_detail.description = null
      this.form.plan_details.push(detail)
    },
    submit() {
      this.loading = true

      let request = this.form.id ? Plan.update(this.form) : Plan.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Plan has been saved',
          });

          this.$emit('update', true)
        })
        .catch(error => {
          let message = error.response.data.message || 'Unable to save this time'
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message,
          });
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style></style>
