var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('h5',{staticClass:"info-text"},[_vm._v("Tell us about your company")]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"name","error":failed ? 'The Name field is required' : null,"hasSuccess":passed,"placeholder":"Name (required)","addon-left-icon":"nc-icon nc-single-02"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}])})],1),_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"fein"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"fein","error":failed ? 'The FEIN field is required' : null,"hasSuccess":passed,"placeholder":"FEIN","addon-left-icon":"nc-icon nc-circle-10"},model:{value:(_vm.form.fein),callback:function ($$v) {_vm.$set(_vm.form, "fein", $$v)},expression:"form.fein"}})]}}])})],1)]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-sm-6"},[_c('ValidationProvider',{attrs:{"name":"dba"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"dba","error":failed ? 'The DBA field is required' : null,"hasSuccess":passed,"placeholder":"Doing business as","addon-left-icon":"nc-icon nc-circle-10"},model:{value:(_vm.form.dba),callback:function ($$v) {_vm.$set(_vm.form, "dba", $$v)},expression:"form.dba"}})]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }