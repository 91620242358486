<template>
  <div class="row" v-loading.fullscreen.lock="loading">

    <el-dialog
      title="If you cancel"
      :visible.sync="showCancel"
      width="30%"
      :before-close="handleClose">
      <p>Once you've initiated the cancellation process, please be aware that access to all the services we offer will cease following the expiration date of your current plan.</p>
        
        <hr>
        <div>
          <el-button type="info" plain @click="handleClose()">Close</el-button>
          <el-button type="danger" plain @click="confirmCancel()">Cancel subscription anyway</el-button>
        </div>
    </el-dialog>

    <el-dialog
        title="Cool"
        :visible.sync="showReactivate"
        width="30%"
        :before-close="handleClose">
          <p>Its good to have you back</p>
          <hr>
          <div>
            <el-button type="info" plain @click="handleClose()">Close</el-button>
            <el-button type="success" @click="confirmReactivate()">Reactivate my subscription</el-button>
          </div>
      </el-dialog>

    <div class="col-md-3">
      <el-card class="box-card">
        <h4>{{ subscription.name }}</h4>
        <small>{{ subscription.is_cancel ? '(CANCELLED)':'' }}</small>
        <hr>

        <el-descriptions class="margin-top" title="Information" :column="1">
          <el-descriptions-item label="Started On">{{ getStarted() }}</el-descriptions-item>
          <el-descriptions-item label="Expiration">{{ getExpiration() }}</el-descriptions-item>
          <!-- <el-descriptions-item label="Auto Renew" >{{ subscription.auto_renew}}</el-descriptions-item> -->
          <el-descriptions-item label="Price" >{{ subscription.price }}</el-descriptions-item>
        </el-descriptions>

        <div class="mb-2">
          <!-- <el-alert class="mb-3">Note: Plan upgrade  within 7 days from subscription date will still start on the subscribtion date</el-alert> -->
          <el-button type="success" @click="goToUpgrade()" >Upgrade Plan</el-button>
        </div>
        <el-button type="warning" plain @click="onCancel()" v-if="!subscription.is_cancel">Cancel Subscription</el-button>
        <el-button type="success" plain @click="OnReactivate()" v-if="subscription.is_cancel">Reactivate Subscription</el-button>
      </el-card>
    </div>
    <div class="col-md-3">
      <el-card class="box-card">
        <el-descriptions class="margin-top" title="Details" :column="1">
            <el-descriptions-item v-for="plan_detail in plan.plan_details" :key="plan_detail.id">{{ plan_detail.description }}</el-descriptions-item>
          </el-descriptions>
      </el-card>
    </div>
  </div>
</template>
<script>

import { Subscription } from '@/resources/subscription'
import Swal from 'sweetalert2'


export default {
  data() {
    return {
      showForm: false,
      selected: null,
      loading: false,
      term: null,
      subscription: {},
      showCancel: false,
      showReactivate: false,
      plan: null,
      is_upgradable: false
    }
  },
  created: function () {
    this.subscription = this.$store.state.user?.company?.subscription
    this.subscription.is_cancel = this.$store.state.user?.company?.company_plan?.is_cancel
    this.plan = this.$store.state.user?.company?.plan
    this.is_upgradable = this.$store.state.user?.company?.is_upgradable
  },
  methods: {
    goToUpgrade() {
      this.$router.push({ name: 'UpgradeSubscription' })
    },
    getExpiration() {
      return this.$moment(this.subscription.expiration).format('MMM DD, YYYY h:mm a')
    },
    getStarted() {
      return this.$moment(this.subscription.start).format('MMM DD, YYYY h:mm a')
    },
    onCancel() {
      this.showCancel = true
    }, 
    OnReactivate() {
      this.showReactivate = true
    },
    confirmCancel() {
      this.loading = true

      Subscription.cancel()
        .then(result => {
          this.success('cancel')
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
    confirmReactivate() {
      this.loading = true

      Subscription.reactivate()
        .then(result => {
          this.success('reactivate')
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
    handleClose() {
      this.showCancel = false
      this.showReactivate = false
    },
    async success(option) {
      this.handleClose()

      let message = option == 'reactivate' ? 'Its good to have you back' : 'Let us know if you change your mind' ;
      const { value: res } = await Swal.fire({
        title: "Thank you",
        text: message,
        icon: "success",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      })

      this.$router.push({ name: 'Home', query: { refresh: true } })
    },
  }
}
</script>
<style lang="scss"></style>
