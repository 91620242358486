<template>
  <div>
    <el-card class="mb-3 black-bg white-text" style="font-size: 18px;">
      <h3>Commissions</h3>
      <div>Gained:</div>
      <div style="font-size: 30px;font-weight: bold;"> ${{ total_unclaimed }}</div>
      <div>Total:</div>
      <div  style="font-size: 30px;font-weight: bold;">
         ${{ total_commissions }}
      </div>
      <hr class="white-bg">
      <el-link type="danger" href="/my-commissions">View More</el-link>
    </el-card>
    <el-card v-if="user && user.referral_link" class="mb-3">
      
                    <div style="font-size: 15px;font-weight: bold;">Share/refer the app to the gain commissions</div>
      <hr>
      
      <el-button @click="copy()">Copy Referral Link</el-button> 
      <input v-on:focus="$event.target.select()" ref="clone"  readonly style="opacity: 0;"
          :value="user.referral_link" />
      <br>
      <div class="mt-2" style="    padding-left: 4px;">or share via social media </div>
      <twitter-button
      :url="user.referral_link"
      description="Biz-Depot Sign Up"
    />
    <facebook-button :url="user.referral_link"
        description="Biz-Depot Sign Up" />

        <LinkedInButton :url="user.referral_link"
          description="Biz-Depot Sign Up" />
          <PinterestButton :url="user.referral_link"
            description="Biz-Depot Sign Up" />
    </el-card>
    
  </div>
</template>
<script>

import { mapState } from 'vuex';
import { Message } from 'element-ui';
import TwitterButton from "vue-share-buttons/src/components/TwitterButton";
import FacebookButton from "vue-share-buttons/src/components/FacebookButton";
import LinkedInButton from "vue-share-buttons/src/components/LinkedInButton";
import PinterestButton from "vue-share-buttons/src/components/PinterestButton";
import { UserReferral } from '@/resources/user_referral'

export default {
  computed: mapState(['user']),
  components: { TwitterButton, FacebookButton, LinkedInButton, PinterestButton },
  data() {
    return {
      total_commissions: 0,
      total_claimed: 0,
      total_unclaimed: 0
    }
  },
  created: function () {
    this.getUserByReferralCode()
  },
  methods: {
    copy() {
      this.$refs.clone.focus();
      document.execCommand('copy');
      Message({
        message: 'Referral link copied',
        type: 'success'
      });
    },
    getUserByReferralCode() {
      this.loading = true
      UserReferral.findByCode({ code: this.user.referral_code, total: true, claimed: true, unclaimed: true })
        .then(result => {
          this.total_commissions = result.data.total_commissions
          this.total_claimed = result.data.total_claimed
          this.total_unclaimed = result.data.total_unclaimed
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
  }
}
</script>
<style lang="scss"></style>
