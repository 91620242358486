import api from './api'

const API = '/api/promos'

export const Promo = {
  get: function(params) {
    return api.get(API, params)
  },
  getByCode: function(code) {
    return api.get(API+'/code', {params: {code:code}})
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
