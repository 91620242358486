<template>
    <div v-loading.fullscreen.lock="loading">
      <el-input placeholder="Put your promo code here" v-model="promo_code" class="input-with-select">
      <el-button slot="append" @click="checkPromoCode()">Apply Promo code</el-button>
    </el-input>
  </div>
</template>
<script>

import { Promo } from '@/resources/promo'

export default {
  
  data() {
    return {
      loading: false,
      promo_code: null,
      promo: null
    }
  },
  methods: {
    checkPromoCode() {
      this.loading = true
      Promo.getByCode(this.promo_code)
        .then(result => {
          this.promo = result.data.promo
          this.$emit('update', this.promo)
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style></style>
