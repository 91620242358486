<template>
  <div>
    {{ getMessage() }}
  </div>
</template>

<script>

export default {
  props: ['message'],
  data() {
    return {
      
    };
  },
  methods: {
    getMessage() {
      let text = this.message || ""
      if (text.length > 150) {
        text = text.substring(0, 150) + '...';
      }
      return text
    }
  }
}
</script>
<style></style>
