<template>
    <div class="card card-user" v-if="user">
      <div class="image">
        <img src="@/assets/images/profile_banner.jpg" alt="...">
      </div>
      <div class="card-body">
        <div class="author">
          <a>
            <img class="avatar border-gray" src="@/assets/images/default-avatar.png" alt="...">
            <h5 class="title">{{ user.name }}</h5>
          </a>
          <p class="description">
            {{ user.email }}
          </p>
        </div>
        <p class="description text-center" v-if="!own">
          Roles
          <br>
          {{  user.role_names.join(" , ") }}
        </p>
      </div>
      <div class="card-footer">
        <hr>
        <div class="button-container">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-6 ml-auto">
              <h5>Status
                <br>
                <small>{{ userStatus[user.status]  }}</small>
              </h5>
            </div>
            <!-- <div class="col-lg-4 col-md-6 col-6 ml-auto mr-auto">
              <h5>2GB
                <br>
                <small>Used</small>
              </h5>
            </div> -->
            <!-- <div class="col-lg-3 mr-auto">
              <h5>24,6$
                <br>
                <small>Spent</small>
              </h5>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>

    import { mapState } from 'vuex';
    export default {
        name: 'user-card',
        props: ['user','own'],
        computed: {
          ...mapState(['userStatus'])
        }
    }
  
  </script>
  <style>
  
  </style>
  