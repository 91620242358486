<template>
  <div v-if="user" v-loading.fullscreen.lock="loading">
    <div>
      <el-button :disabled="disabled" type="info" @click="createPaymentMethodLink()" class="btn-block mb-3 ">{{ getLabel() }}</el-button>
      <a :href="update_payment_method_link" ref="paymentMethodLink"></a>
    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import { Payment } from '@/resources/payment'
import { CompanyCard } from '@/resources/company_card'

import Swal from 'sweetalert2'

export default {
  props: ['amount', 'label', 'type', 'description', 'code', 'disabled', 'other'],
  data() {
    return {
      loading_via_card: false,
      loading: false,
      update_payment_method_link: null,
      allowed_payment_balance_types: ['renew', 'meeting_room_booking']
    }
  },
  created: function () {
  },
  methods: {
    getLabel() {
      if (this.loading) return "Checking"
      if (this.other) return "Update payment card"
      return this.user.default_payment ? 'Update your payment card' : 'Set your payment card'
    },
    createPaymentMethodLink() {
      this.loading = true
      CompanyCard.createPaymentMethodLink({ return_url: this.$route.fullPath })
        .then(result => {
          this.update_payment_method_link = result.data.url
          const elem = this.$refs.paymentMethodLink
          setTimeout(() => {
            if (elem) elem.click()
          }, 1000);
        })
        .catch(() => {
        }).finally(() => {
          // this.loading = false
        })
    }
  },
  computed: mapState(['user']),
}
</script>
<style lang="scss"></style>
