<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <el-card>
        <ValidationObserver ref="form">
          <form @submit.prevent="validate">
            <h5 class="info-text"></h5>
            <div class="row justify-content-center">
              <div class="col-sm-6">
                <ValidationProvider name="start_date" rules="required" v-slot="{ passed, failed }">
                  <label>From</label>
                  <fg-input type="date" name="start_date" :error="failed ? 'The start date field is required' : null"
                    :hasSuccess="passed" placeholder="Start Date (required)" v-model="form.start_date"
                    addon-left-icon="el-icon-date">
                  </fg-input>
                </ValidationProvider>
              </div>

              <div class="col-sm-6">
                <ValidationProvider name="end_date" rules="required" v-slot="{ passed, failed }">
                  <label>To</label>
                  <fg-input type="date" :min-date="form.start_date" name="end_date"
                    :error="failed ? 'The end date field is required' : null" :hasSuccess="passed"
                    placeholder="End Date (required)" v-model="form.end_date" addon-left-icon="el-icon-date">
                  </fg-input>
                </ValidationProvider>
              </div>

              <div class="col-md-12 mb-3">
                <el-button round type="info" @click="validate()"><i class="nc-icon nc-money-coins"></i> Generate Commissions Report</el-button>
              </div>
              <div class="col-md-12">
                <el-button round type="info" @click="validate('claimed_commissions')"><i class="nc-icon nc-money-coins"></i> Generate Claimed Commissions Report</el-button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </el-card>
      </div>
    </div>
    
    
  </div>
</template>
<script>

import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", required);

import { UserReferral } from '@/resources/user_referral'

export default {
  data() {
    return {
      form: { start_date: null, end_date: null },
      loading: false,
    }
  },
  watch: {
    'form.start_date': function () {
      if (this.form.start_date) this.form.end_date = this.form.start_date
    }
  },
  created: function () {

  },
  methods: {
    validate(option) {
      return this.$refs.form.validate().then(res => {
        if (res) {
          if (option == 'claimed_commissions') this.getClaimedCommissionsReport()
          else this.submit()
        }
        return res
      })
    },
    submit() {
      this.loading = true
      UserReferral.downloadCommissions(this.form)
        .then(result => {
          const link = document.createElement('a')
          link.href = result.data.link
          link.download = result.data.filename
          link.click()
        })
        .catch(() => {
        }).finally(() => {
          this.loading = false
        })
    },
    getClaimedCommissionsReport() {
      this.loading = true
      UserReferral.downloadClaimedCommissions(this.form)
        .then(result => {
          const link = document.createElement('a')
          link.href = result.data.link
          link.download = result.data.filename
          link.click()
        })
        .catch(() => {
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
