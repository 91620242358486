import api from './api'

const API = '/api/payments'

export const Payment = {
  cashInWithCard: function(params) {
    return api.create(API+'/cash-in-with-card', params)
  },
  payWithBalance: function(params) {
    return api.create(API+'/pay-with-balance', params)
  },
  payWithCard: function(params) {
    return api.create(API+'/pay-with-card', params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id)
  }
}
