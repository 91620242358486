<template>
  <ValidationObserver v-slot="{ handleSubmit }" v-loading="loading">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ getTitle() }}
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>Name</label>
            <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The name field is required' : null" :hasSuccess="passed" name="name"
                v-model="form.name">
              </fg-input>
            </ValidationProvider>
          </div>

          <div class="form-group">
              <label>Rate per Hour</label>
              <ValidationProvider name="price" rules="required" v-slot="{ passed, failed }">
                <fg-input type="number" :error="failed ? 'The rate field is required' : null" :hasSuccess="passed" name="price"
                  v-model="form.price">
                </fg-input>
              </ValidationProvider>
            </div>

          <div class="form-group">
            <label>Office</label>
            <ValidationProvider name="office" rules="required" v-slot="{passed, failed }">
                <el-select v-model="form.office"
                        filterable
                               class="select-primary"
                               :class="passed ? 'is-valid':''"
                               name="country">
                      <el-option v-for="office in offices"
                                 
                                 :label="office.name"
                                 :value="office"
                                 :key="office.id"></el-option>
                    </el-select>
                    <small id="passwordHelp" class="text-danger" v-if="failed">The Office field is required</small>     
              </ValidationProvider>
          </div>

          <div class="form-group">
            <label>Status</label>
            <ValidationProvider name="code" v-slot="{ failed }">
              <el-select v-model="form.status"
                                 class="select-primary"
                                 name="country">
                        <el-option v-for="(status, i) in ['Inactive','Active']"
                                 
                                   :label="status"
                                   :value="i"
                                   :key="i"></el-option>
                      </el-select>
                      <small id="passwordHelp" class="text-danger" v-if="failed">The Office field is required</small>    
            </ValidationProvider>
          </div>

          <div class="form-group">
            <label>Capacity</label>
            <ValidationProvider name="price_per_month" v-slot="{ passed, failed }">
              <fg-input step="1" type="number" :error="failed ? 'The price per month field is required' : null"
                :hasSuccess="passed" name="price_per_month" v-model="form.capacity">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>
        <div class="card-footer text-right">
          <p-button type="primary" native-type="submit">Save</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>

import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { MeetingRoom } from '@/resources/meeting_room'
import { Office } from '@/resources/office'

extend("required", required);

export default {
  props: ['room'],
  data() {
    return {
      form: {
        room_type: 0,
        name: "",
        office: null,
        capacity: 0,
        status: 0,
        price: 1
      },
      offices: [],
      loading: false
    };
  },
  created: function () {
    this.form = Object.assign({}, this.room)
    this.form.room_type = this.form.type
    this.getOffices()
  },
  watch: {
    room() {
      this.form = Object.assign({}, this.room)
      this.form.room_type = this.form.type
    },
  },
  methods: {
    getTitle() {
      let name = this.form && this.form.id ? 'Update' : 'Create New'
      name = name + (this.form && this.form.room_type == 1 ? ' Desk' : ' Room')
      return name
    },
    getOffices() {
      this.loading = true
      Office.get({ params: { page: 1, per_page: 999 } })
        .then(result => {
          if (result.data.offices)
            this.offices = result.data.offices
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
    submit() {
      this.loading = true

      if (this.form.office) this.form.office_id = this.form.office.id
      let request = this.form.id ? MeetingRoom.update(this.form) : MeetingRoom.create(this.form)

      request
        .then(result => {
          let name = this.type ? 'Desk':'Room'
          this.$notify({
            type: 'success',
            title: "Success",
            text: name+' has been saved',
          });

          this.$emit('update', true)
        })
        .catch(() => {
         
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style></style>
