<template>
  <div v-if="user" class="row">
    <div class="col-md-12">
      <stats-card small-title="Due Amount" :title="'$'+ user.unpaid_charges" type="danger" icon="fa fa-dollar" :buttonLink="'/pay-due-amount'" :buttonLabel="'Pay'"></stats-card>
    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import StatsCard from '../../UIComponents/Cards/StatsCard.vue'

export default {
  components: { StatsCard },
  data() {
    return {
      loading: false,
    }
  },
  created: function () {
    // this.getUser(this.$route.params.id)
  },
  methods: {
    handleEdit(index, data) {
      console.log(data)
    },
  },
  computed: mapState(['user']),
}
</script>
<style lang="scss"></style>
