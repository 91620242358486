<template>
  <div>
    <div class="row" v-loading.fullscreen.lock="loading">
      <div class="col-md-12">
          <el-card v-if="user && user.company.office" class="mb-3">
            <div style="font-size: 20px"><strong>Mail Box Address:</strong> {{ user.company.complete_address }}</div>
          </el-card>
      </div>
      <div class="col-md-3">
        <user-due-amount></user-due-amount>
        <user-balance></user-balance>

        <update-payment-card></update-payment-card>
      </div>
      <div class="col-md-6 mb-3" v-if="user && !user.company.company_plan">
        <el-button type="success" @click="goToSubscribtionPage()">Activate your mailbox service now !</el-button>
      </div>
      <div class="col-md-6 mb-3" v-if="user && user.company.company_plan">
          <div class="row">
            <div class="col-md-6">
              <stats-card small-title="Unread Mails" :title="unread_mails" type="warning" link="/mailbox/inbox"
                icon="nc-icon nc-email-85"></stats-card>
            </div>
            <div class="col-md-6">
                <stats-card small-title="Mail Requests" :title="pending_mail_requests" type="danger"
                  icon="nc-icon nc-single-copy-04"></stats-card>
              </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mails-received-in-a-year></mails-received-in-a-year>
            </div>
          </div>
      </div>


      <div class="col-md-3">
                <user-referral-card></user-referral-card>

        <user-meeting-room-bookings-today></user-meeting-room-bookings-today>
      </div>
    </div>
    
  </div>
</template>
<script>

import { Analytic } from '@/resources/analytics'
import StatsCard from '../../UIComponents/Cards/StatsCard.vue'
import MailsReceivedInAYear from './MailsReceivedInAYear.vue'
import UserBalance from '../Profile/UserBalance.vue'
import { mapState } from 'vuex';
import UpdatePaymentCard from '../../UIComponents/UpdatePaymentCard.vue'
import UserMeetingRoomBookingsToday from './UserMeetingRoomBookingsToday.vue'
import UserDueAmount from '../Profile/UserDueAmount.vue'
import { Message } from 'element-ui';
import UserReferralCard from './UserReferralCard.vue'


export default {
  computed: mapState(['user']),
  components: {
    StatsCard,
    MailsReceivedInAYear,
    UserBalance,
    UpdatePaymentCard,
    UserMeetingRoomBookingsToday,
     UserDueAmount,
    UserReferralCard
  },
  data() {
    return {
      unread_mails: 0,
      pending_mail_requests: 0,
      loading: false,
    }
  },
  created: function () {
    this.getAnalytics()
    this.getPendingMailRequests()
  },
  methods: {
    copy() {  
      this.$refs.clone.focus();
      document.execCommand('copy');
      Message({
        message: 'Referral link copied',
        type: 'success'
      });
    },
    getAnalytics() {
      this.loading = true
      Analytic.unreadMails()
        .then(result => {
          this.unread_mails = result.data
        })
        .catch(() => {
        }).finally(() => {
          this.loading = false
        })
    },
    getPendingMailRequests() {
      this.loading = true
      Analytic.pendingMailRequests()
        .then(result => {
          this.pending_mail_requests = result.data
        })
        .catch(() => {
        }).finally(() => {
          this.loading = false
        })
    },
    goToSubscribtionPage() {
      this.$router.push({ name: 'NewSubscription' })
    }
  }
}
</script>
<style lang="scss"></style>
