var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.form.id ? 'Update Plan' : 'Create new Plan')+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Level")]),_c('ValidationProvider',{attrs:{"name":"level","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The level field is required' : null,"hasSuccess":passed,"name":"level"},model:{value:(_vm.form.level),callback:function ($$v) {_vm.$set(_vm.form, "level", $$v)},expression:"form.level"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Name")]),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The name field is required' : null,"hasSuccess":passed,"name":"name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Code")]),_c('ValidationProvider',{attrs:{"name":"code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The code field is required' : null,"hasSuccess":passed,"name":"code"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Remarks")]),_c('ValidationProvider',{attrs:{"name":"code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The remarks field is required' : null,"hasSuccess":passed,"name":"remarks"},model:{value:(_vm.form.remarks),callback:function ($$v) {_vm.$set(_vm.form, "remarks", $$v)},expression:"form.remarks"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Setup Fee")]),_c('ValidationProvider',{attrs:{"name":"setup_fee"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"step":".01","type":"number","error":failed ? 'The setup fee field is required' : null,"hasSuccess":passed,"name":"setup_fee"},model:{value:(_vm.form.setup_fee),callback:function ($$v) {_vm.$set(_vm.form, "setup_fee", $$v)},expression:"form.setup_fee"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Price per month")]),_c('ValidationProvider',{attrs:{"name":"price_per_month"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"step":".01","type":"number","error":failed ? 'The price per month field is required' : null,"hasSuccess":passed,"name":"price_per_month"},model:{value:(_vm.form.price_per_month),callback:function ($$v) {_vm.$set(_vm.form, "price_per_month", $$v)},expression:"form.price_per_month"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Price per year")]),_c('ValidationProvider',{attrs:{"name":"price_per_year"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"step":".01","type":"number","error":failed ? 'The price per year field is required' : null,"hasSuccess":passed,"name":"price_per_year"},model:{value:(_vm.form.price_per_year),callback:function ($$v) {_vm.$set(_vm.form, "price_per_year", $$v)},expression:"form.price_per_year"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Mails per month")]),_c('ValidationProvider',{attrs:{"name":"mails_per_month"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"number","error":failed ? 'The price per month field is required' : null,"hasSuccess":passed,"name":"mails_per_month"},model:{value:(_vm.form.mails_per_month),callback:function ($$v) {_vm.$set(_vm.form, "mails_per_month", $$v)},expression:"form.mails_per_month"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Forwarded mails per month")]),_c('ValidationProvider',{attrs:{"name":"forwarded_mails_per_month"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"number","error":failed ? 'The price per month field is required' : null,"hasSuccess":passed,"name":"forwarded_mails_per_month"},model:{value:(_vm.form.forwarded_mails_per_month),callback:function ($$v) {_vm.$set(_vm.form, "forwarded_mails_per_month", $$v)},expression:"form.forwarded_mails_per_month"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Scanned mails per month")]),_c('ValidationProvider',{attrs:{"name":"scanned_mails_per_month"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"number","error":failed ? 'The price per month field is required' : null,"hasSuccess":passed,"name":"scanned_mails_per_month"},model:{value:(_vm.form.scanned_mails_per_month),callback:function ($$v) {_vm.$set(_vm.form, "scanned_mails_per_month", $$v)},expression:"form.scanned_mails_per_month"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Shredded mails per month")]),_c('ValidationProvider',{attrs:{"name":"shredded_mails_per_month"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"number","error":failed ? 'The price per month field is required' : null,"hasSuccess":passed,"name":"shredded_mails_per_month"},model:{value:(_vm.form.shredded_mails_per_month),callback:function ($$v) {_vm.$set(_vm.form, "shredded_mails_per_month", $$v)},expression:"form.shredded_mails_per_month"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Details")]),_vm._l((_vm.form.plan_details),function(detail,i){return _c('div',{key:i},[_c('fg-input',{attrs:{"type":"text"},model:{value:(detail.description),callback:function ($$v) {_vm.$set(detail, "description", $$v)},expression:"detail.description"}})],1)}),_c('div',{staticStyle:{"margin-top":"15px"}},[_c('el-input',{staticClass:"input-with-select",attrs:{"placeholder":"Please input"},model:{value:(_vm.new_detail.description),callback:function ($$v) {_vm.$set(_vm.new_detail, "description", $$v)},expression:"new_detail.description"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-plus"},on:{"click":function($event){return _vm.addDetail()}},slot:"append"},[_vm._v("Add")])],1)],1)],2)]),_c('div',{staticClass:"card-footer text-right"},[_c('p-button',{attrs:{"type":"info","native-type":"submit"}},[_vm._v("Save")])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }