<template>
		<div class="row">
			<div class="col-md-6">
				<h4>Assigned companies</h4>
					<el-tag
					:key="tag"
					v-for="tag in tags.dynamicTags"
					type="primary"
					:closable="true"
					:close-transition="false"
					@close="handleClose(tag)"
				>
					{{tag}}
				</el-tag>
			</div>
			<div class="col-md-6">
				<SelectFromAllCompanies :selected_ids="selected_ids" @selected="select($event)"></SelectFromAllCompanies>
				
			</div>

		</div>
	</template>
	<script>

	import {Tag} from 'element-ui'
  import SelectFromAllCompanies from 'src/components/System/Companies/SelectFromAllCompanies'
  import { UserCompany } from '@/resources/user_company'

		export default{
      props: ['user'],
			components: {
				[Tag.name]: Tag,
        SelectFromAllCompanies
			},
			data () {
				return {
          loading: false,
					tags: {
						dynamicTags: [],
						inputVisible: false,
						inputValue: ''
					},
          selected_ids: [],
          selected_names: []
				}
			},
			created: function() {
        
        this.getUserCompanies()
			},
			methods: {
				handleClose (tag) {
					this.tags.dynamicTags.splice(this.tags.dynamicTags.indexOf(tag), 1)
				},
        select(company) {
          let found = false
          let i = null
          this.selected_ids.forEach(function(selectedId, index){
            if (company.id == selectedId) {
              found = true
              i = index
            }
          })

          this.tags.dynamicTags = this.selected_names
          this.saveUserCompany(company, found, i)
        },
        gatherCompanyNames(companies) {
          this.selected_ids = []
          this.selected_names = []
          let self = this
          companies.forEach(function(company){
            self.selected_ids.push(company.id)
            self.selected_names.push(company.name)
          })
          this.tags.dynamicTags = this.selected_names
        },
        getUserCompanies() {
          this.loading = true
          UserCompany.get({params: {user_id: this.user.id, per_page: 9999} })
          .then(result => {
             this.gatherCompanyNames(result.data.companies)
          })
          .catch( error => {
            // this.$notify({
            //   message: 'Unable to update this time',
            //   type: 'danger'
            // })
          }).finally( () => {
            this.loading = false
          })
        },
        saveUserCompany(company, remove, index) {
          this.loading = true
          UserCompany.create({user_id: this.user.id, company_id: company.id, remove: remove})
          .then(result => {
             if (remove) {
                this.selected_ids.splice(index, 1);
                this.selected_names.splice(index, 1);
              } else {
                this.selected_ids.push(company.id)
                this.selected_names.push(company.name)
              }
          })
          .catch( error => {
            let message = error.response.data.message || 'Unable to save this time'
            this.$notify({
	            type: 'danger',
	            title: "Error",
	            text: message,
	          });
          }).finally( () => {
            this.loading = false
          })
        }
			}
		}
	</script>
	<style lang="scss">
	
	</style>
	