

<template>
  <div>
    <p>    I, {{ user.name }}, authorize Biz-Depot to {{ action }} my letter(s) on my behalf, 
      and I agree to pay the corresponding service charge. I hold Biz-Depot free from any legal action arising from this process.</p>
  
    <div>Client's Full Name: {{ user.name }}</div>
    <div> Date: {{ $moment().format('MM/DD/YYYY') }}</div>
  
    <br>
    <el-checkbox v-model="accept" border>Click to confirm</el-checkbox>

  </div>
</template>

<script>

import { mapState } from 'vuex';

export default {
  computed: mapState(['user']),
  name: 'mail-action-authorization',
  props: ['action'],
  data() {
    return {
      accept: false
    }
  },
  watch: {
    accept: function () {
      this.$emit('accept', this.accept)
    }
  }
}
</script>

<style scoped>
.card-content {
  min-height: 85px
}
</style>
