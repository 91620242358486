<template>
  <div v-loading.fullscreen.lock="loading">
    <stats-card small-title="Sales this month" :title="total_sales" type="success"
            icon="nc-icon nc-money-coins"></stats-card>
  </div>
</template>
<script>

import { Analytic } from '@/resources/analytics'
import StatsCard from '../../UIComponents/Cards/StatsCard.vue'

export default {
  components: { StatsCard  },
  data() {
    return {
      loading: false,
      total_sales: 0
    }
  },
  created: function () {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      Analytic.salesThisMonth()
        .then(result => {
          this.total_sales = '$ '+result.data.total_sales
        })
        .catch(() => {
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
