<template>
    <div>
      <!-- <h5 class="card-title">Select Company</h5> -->
      <div  class="ml-auto mr-auto" >      
  
         <div class="input-group mb-3">
            <input v-model="term" type="text" class="form-control" placeholder="Company name" aria-label="Company name" aria-describedby="basic-addon2">
          </div>
  
      </div>
  
      <div v-if="!companies.length" class=" ml-auto mr-auto" >
           <div style="text-align:center;width: 100%">No data found</div>
      </div>
  
      <div class="" v-for="company in companies" :key="company.id">
        <div class="card ml-auto mr-auto" :class="{'selected': isSelected(company.id) }" >
          <div class="card-body">
            {{  company.name }} <p-button :type="isSelected(company.id) ? 'danger':'primary'" size="sm" style="float:right" @click="select(company)">{{ isSelected(company.id) ? 'Unselect' : 'Select' }}</p-button>
          </div>
        </div>
      </div>
    
  
      <p-pagination v-if="companies.length" class="pull-right"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="pagination.total">
      </p-pagination>
    </div>
  </template>
  <script>
  
    import PPagination from 'src/components/UIComponents/Pagination.vue'
    import { Company } from '@/resources/company'
  
    export default{
      props: ['selected_ids'],
      components: {
        PPagination
      },
      data () {
        return {
          companies: [],
          pagination: {
            perPage: 5,
            currentPage: 1,
            perPageOptions: [5, 10, 25, 50],
            total: 0
          },
          term: null,
          status: 1,
          loading: true
        }
      },
      watch: {
        term: function() {
          if (!this.term || this.term.length > 2) {
            this.getCompanies()
          }
        },
        'pagination.perPage': function() {
          if ( this.pagination.currentPage == 1) {
            this.getCompanies()
          } else {
            this.pagination.currentPage = 1
          }
        },
        'pagination.currentPage': function() {
          this.getCompanies()
        }
      },
      created: function() {
        this.getCompanies()
      },
      methods: {
        getCompanies() {
          this.loading = true
          Company.get({params: {term: this.term, status: this.status,page: this.pagination.currentPage}})
          .then(result => {
              if(result.data.companies)
              this.companies = result.data.companies
              this.pagination = result.data.meta
          })
          .catch( () => {
          this.$notify({
              type: 'danger',
              title: "Error",
              text: 'Unable to fetch companies',
            });
          }).finally( () => {
            this.loading = false
          })
        },
        isSelected(id) {
          if (!this.selected_ids) return false
          let found = false
          this.selected_ids.forEach(function(selectedId){
            if (selectedId == id) found = true
          })

          return found
        },
        select(company) {
          this.$emit('selected',company)
        }
      }
    }
  </script>
  <style lang="scss">
    .selected {
      background: #e4e4e4;
    }
  </style>
  