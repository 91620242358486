import api from './api'

const API = '/api/company_cards'

export const CompanyCard = {
  get: function(params) {
    return api.get(API, params)
  },
  getDefaultCard: function () {
     return api.get(API+'/default-card')
  },
  create: function(params) {
    return api.create(API, params)
  },
  createPaymentMethodLink: function(params) {
    return api.create(API+'/create-payment-method-link', params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id)
  },
  makeDefault: function (params) {
    return api.update(API+'/'+params.id+'/make-default', params)
  }
}
