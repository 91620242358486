<template>
  <div>
    <h5 v-if="title">{{ title }}</h5>
    <p></p>
    <el-checkbox v-model="accept" border> I Authorized Biz-Depot to take action in my behalf to open and scan the content my mail. </el-checkbox>
</div></template>
<script>

export default {
  props: ['title'],
  data() {
    return {
      accept: null
    }
  },
  watch: {
    accept: function () {
      this.$emit('accept', this.accept)
    }
  },
  created: function () {

  }
}

</script>
<style></style>
