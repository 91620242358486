<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="info-text">{{ label || 'Address' }}</h5>
      <div class="row justify-content-center">
        <div class="col-md-12">
          <ValidationProvider name="address1" rules="required" v-slot="{ passed, failed }">
            <fg-input name="address1" :error="failed ? 'The Street line 1 field is required' : null" :hasSuccess="passed"
              placeholder="Street line 1 (required)" v-model="form.address1" addon-left-icon="nc-icon nc-single-02">
            </fg-input>
          </ValidationProvider>
        </div>

        <div class="col-md-12">
          <ValidationProvider name="address2" v-slot="{ passed, failed }">
            <fg-input name="fein" :error="failed ? 'The FEIN field is required' : null" :hasSuccess="passed"
              placeholder="Street line 2" v-model="form.address2" addon-left-icon="nc-icon nc-circle-10">
            </fg-input>
          </ValidationProvider>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-12">
          <ValidationProvider name="city" rules="required" v-slot="{ passed, failed }">
            <fg-input name="city" :error="failed ? 'The City field is required' : null" :hasSuccess="passed"
              placeholder="City (required)" v-model="form.city" addon-left-icon="nc-icon nc-single-02">
            </fg-input>
          </ValidationProvider>
        </div>
        <div class="col-md-7">
          <ValidationProvider name="State" rules="required" v-slot="{ passed, failed }">
            <fg-input name="state" :error="failed ? 'The State field is required' : null" :hasSuccess="passed"
              placeholder="State (required)" v-model="form.state" addon-left-icon="nc-icon nc-single-02">
            </fg-input>
          </ValidationProvider>
        </div>
        <div class="col-md-5">
          <ValidationProvider name="zip" rules="required" v-slot="{ passed, failed }">
            <fg-input name="zip" :error="failed ? 'The Zip field is required' : null" :hasSuccess="passed"
              placeholder="Zip Code (required)" v-model="form.zip" addon-left-icon="nc-icon nc-single-02">
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);

export default {
  props: ['address','label'],
  data() {
    return {
      form: {
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip:null
      }
    };
  },
  watch: {
    address: function () {
      if (this.address) {
        this.form = Object.assign({}, this.address)
      }
    },
    form: {
      handler: function (newVal) {
        // this.validate()
        // this.$emit('on-validated', true, this.form, 'address')
      },
      deep: true
    },
  },
  created: function () {
    if (this.address) this.form = Object.assign({}, this.address)
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then(res => {
        this.$emit('on-validated', res, this.form, 'address')
        return res
      })
    }
  }
}
</script>
<style lang="scss">
.card-wizard .picture-container .picture {
  input[type="file"] {
    display: none;
  }
}
</style>
