<template>
<div style="padding: 20px">
  <div>
    <h4 style="padding-top: 4pt;padding-left: 6pt;text-indent: 0pt;line-height: 113%;text-align: left;font-size: 14px;">
      These General Terms and Conditions apply to Office/Co-Working, Virtual Office, and Membership agreements for services We supply to You.</h4>

    <ol id="l1">
      <li data-list-text="1.">
        <p style="padding-top: 5pt;padding-left: 24pt;text-indent: -18pt;text-align: left;">General Agreement</p>
        <ol id="l2">
          <li data-list-text="1.1.">
            <p style="padding-top: 8pt;padding-left: 51pt;text-indent: -27pt;line-height: 121%;text-align: justify;">Nature of an agreement: At all times, each Center remains in Our possession and control. YOU ACCEPT THAT AN AGREEMENT CREATES NO TENANCY INTEREST, LEASEHOLD ESTATE, OR OTHER REAL PROPERTY INTEREST IN YOUR FAVOR WITH RESPECT TO THE ACCOMMODATION.
            </p>
          </li>
          <li data-list-text="1.2.">
            <p style="padding-top: 6pt;padding-left: 51pt;text-indent: -27pt;line-height: 121%;text-align: justify;">House Rules: The House Rules, which are incorporated into these terms and conditions, are primarily in place and enforced to ensure that all clients have a professional environment to work in.
            </p>
          </li>
          <li data-list-text="1.3.">
            <p style="padding-top: 6pt;padding-left: 51pt;text-indent: -27pt;line-height: 121%;text-align: justify;">Company and Contact Information: It is Your responsibility to keep the information and key contact information We use to communicate with You up to date through the App or Online Account (or other customer portal as advised to you from time to time). This includes but is not limited to email addresses, phone numbers, and company address. Your contact address details must be a legitimate business address or residential address of the primary contact; it must not be an IWG Center address (or other business center address).
            </p>
          </li>
          <li data-list-text="1.4.">
            <p style="padding-top: 6pt;padding-left: 51pt;text-indent: -27pt;line-height: 121%;text-align: justify;">Availability at the start of an agreement: If for any unfortunate reason We cannot provide the Virtual Office services or Office/Co-Working accommodation in the Center stated in an agreement by the start date, We will have no liability to You for any loss or damage, but You may either move to one of Our other Centers (subject to availability), delay the start of the agreement, or cancel it.</p>
          </li>
          <li data-list-text="1.5.">
            <p style="padding-top: 5pt;padding-left: 51pt;text-indent: -27pt;line-height: 121%;text-align: justify;">
              <span>AUTOMATIC RENEWAL: </span>SO THAT WE CAN MANAGE YOUR SERVICES EFFECTIVELY AND TO ENSURE SEAMLESS CONTINUITY OF THOSE SERVICES, ALL AGREEMENTS WILL RENEW AUTOMATICALLY FOR SUCCESSIVE PERIODS EQUAL TO THE CURRENT TERM UNTIL BROUGHT TO AN END BY YOU OR US. ALL PERIODS SHALL RUN TO THE LAST DAY OF THE MONTH IN WHICH THEY WOULD OTHERWISE EXPIRE. THE FEES ON ANY RENEWAL WILL BE AT THE THEN PREVAILING MARKET RATE.<u> IF YOU DO NOT WISH FOR AN AGREEMENT TO RENEW, THEN YOU CAN CANCEL IT</u> <u>EASILY WITH EFFECT FROM THE END DATE STATED IN THE AGREEMENT OR AT THE END OF ANY EXTENSION OR</u> <u>RENEWAL PERIOD BY GIVING US PRIOR NOTICE. NOTICE MUST BE GIVEN THROUGH YOUR ONLINE ACCOUNT OR</u> <u>THROUGH THE APP. THE NOTICE PERIODS REQUIRED ARE AS FOLLOWS:</u>
            </p>
            <p class="s1" style="padding-top: 6pt;padding-left: 51pt;text-indent: 0pt;text-align: left;"> Term &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Notice Period</p>
            <p style="padding-top: 8pt;padding-left: 51pt;text-indent: 0pt;line-height: 176%;text-align: left;">Month-to-Month no less than 1 month&#39;s notice from the 1<span class="s2">st </span>day of any calendar month 3 months no less than 2 months’ notice prior to the end of the term</p>
            <p style="padding-left: 51pt;text-indent: 0pt;line-height: 11pt;text-align: left;">More than 3 months no less than 3 months’ notice prior to the end of the term</p>
          </li>
          <li data-list-text="1.6.">
            <p style="padding-top: 8pt;padding-left: 45pt;text-indent: -21pt;line-height: 121%;text-align: justify;">We may elect not to renew an agreement. If so, We will inform You by email, through the App or Your online account, according to the same notice periods specified above.</p>
          </li>
          <li data-list-text="1.7.">
            <p style="padding-top: 6pt;padding-left: 45pt;text-indent: -21pt;line-height: 121%;text-align: justify;">If the Center is no longer available: In the event that We are permanently unable to provide the services and accommodation at the Center stated in an agreement, We will offer You accommodation in one of Our other centers. In the unlikely event We are unable to find a nearby alternative accommodation, Your agreement will end, and You will only have to pay monthly fees up to that date and for any additional services You have used.</p>
          </li>
          <li data-list-text="1.8.">
            <p style="padding-top: 6pt;padding-left: 45pt;text-indent: -21pt;line-height: 121%;text-align: justify;">Ending an agreement immediately: We may terminate an agreement immediately by giving You notice if (a) You become insolvent or bankrupt; or (b) You breach one of your obligations which cannot be put right, or which We have given You notice to put right and which You have failed to put right within 14 days of that notice; or (c) Your conduct, or that of someone at the Center with Your permission or invitation, is incompatible with ordinary office use and, (i) that conduct continues despite You having been given notice, or (ii) that conduct is material enough (in Our reasonable opinion) to warrant immediate termination; or (d) You are in breach of the “Compliance With Law” clause below. If We terminate an agreement for any of the reasons referred to in this clause You must, within 30 days of the date of Our notice of termination, pay Us as a lump sum payment all sums that would otherwise have fallen due and payable by you during the remainder of the period for which Your agreement would have lasted if We had not terminated it. You agree that this payment reflects a reasonable estimate of the actual damages that We will sustain in the event of an early termination.</p>
          </li>
          <li data-list-text="1.9.">
            <p style="padding-top: 6pt;padding-left: 46pt;text-indent: -22pt;line-height: 121%;text-align: justify;">When an Office agreement ends: When an agreement ends You must vacate Your accommodation immediately, leaving it in the same state and condition as it was when You took it. If You leave any property in the Center, We may dispose of it at Your cost in any way We choose without owing You any responsibility for it or any proceeds of sale. If You continue to use the accommodation when an agreement has ended, You are responsible for any loss, claim or liability We may incur as a result of Your failure to vacate on time.</p>
          </li>
          <li data-list-text="1.10.">
            <p style="padding-top: 6pt;padding-left: 46pt;text-indent: -22pt;line-height: 121%;text-align: justify;">Transferability: Subject to availability (which shall be determined in Our sole discretion) You may transfer Your agreement to alternative accommodation in the IWG network of Centers provided that Your financial</p>
            <p style="padding-top: 4pt;padding-left: 46pt;text-indent: 0pt;line-height: 121%;text-align: left;">commitment remains the same (or increases) and such transfer is not used to extend or renew an existing agreement. Such a transfer may require entry into a new agreement.</p>
          </li>
        </ol>
      </li>
      <li data-list-text="2.">
        <p style="padding-top: 6pt;padding-left: 24pt;text-indent: -18pt;text-align: left;">Use of the Centers:</p>
        <ol id="l3">
          <li data-list-text="2.1.">
            <p style="padding-top: 8pt;padding-left: 45pt;text-indent: -21pt;line-height: 121%;text-align: justify;">Business Operations: You may not carry on a business that competes with Our business of providing serviced offices and flexible working. You may not use Our name (or that of Our affiliates) in any way in connection with Your business. You are only permitted to use the address of a Center as Your registered office address if it is permitted by both law and if We have given You prior written consent (given the administration there is an additional fee chargeable for this service). You must only use the accommodation for office business purposes. If We decide that a request for any particular service is excessive, We reserve the right to charge an additional fee. In order to ensure that the Center provides a great working environment for all, We kindly ask you to limit any excessive visits by members of the public.</p>
          </li>
          <li data-list-text="2.2.">
            <p style="padding-top: 6pt;padding-left: 45pt;text-indent: -21pt;text-align: justify;">Accommodation</p>
            <ol id="l4">
              <li data-list-text="2.2.1.">
                <p style="padding-top: 8pt;padding-left: 67pt;text-indent: -25pt;line-height: 121%;text-align: justify;">Alterations or Damage: You are liable for any damage caused by You or those in the Center with Your permission, whether express or implied, including but not limited to all employees, contractors and/or agents.</p>
              </li>
              <li data-list-text="2.2.2.">
                <p style="padding-top: 6pt;padding-left: 67pt;text-indent: -25pt;line-height: 121%;text-align: justify;">IT Installations: We take great pride in Our IT infrastructure and its upkeep and therefore You must not install any cabling, IT, or telecom connections without Our consent, which We may refuse in our absolute discretion. As a condition to Our consent, You must permit Us to oversee any installations (for example, IT or electrical systems) and to verify that such installations do not interfere with the use of the accommodation by other clients or Us or any landlord of the building. Fees for installation and de-installation will be at Your cost.</p>
              </li>
              <li data-list-text="2.2.3.">
                <p style="padding-top: 6pt;padding-left: 67pt;text-indent: -25pt;line-height: 121%;text-align: justify;">Use of the Accommodation: An agreement will list the accommodation We initially allocate for Your use. You will have a non-exclusive right to the rooms allocated to You. Where the accommodation is a Coworking desk, this can only be used by one individual. It cannot be shared among multiple individuals. Occasionally to ensure the efficient running of the Centre, We may need to allocate different accommodation to You, but it will be of reasonably equivalent size, and We will notify You with respect to such different accommodation in advance.</p>
              </li>
              <li data-list-text="2.2.4.">
                <p style="padding-top: 6pt;padding-left: 67pt;text-indent: -25pt;line-height: 121%;text-align: justify;">Access to the Accommodation: To maintain a high level of service, We may need to enter Your accommodation and may do so at any time, including and without limitation, in an emergency, for cleaning and inspection or in order to resell the space if You have given notice to terminate. We will always endeavor to respect any of Your reasonable security procedures to protect the confidentiality of Your business.</p>
              </li>
              <li data-list-text="2.2.5.">
                <p style="padding-top: 6pt;padding-left: 67pt;text-indent: -25pt;line-height: 121%;text-align: justify;">Hybrid Working: You may use Your designated office for hybrid working (excluding Coworking desks). Hybrid working is defined as having more individuals registered with access to Your office than the specified maximum allowable occupants for that office at any one time. The management of individuals accessing your office is Your responsibility and should be managed through Your online account. At no time may the number of individuals working in Your accommodation exceed the maximum number of occupants allowed. A hybrid supplemental monthly fee will be payable by You for each individual registered above the maximum occupants allowed. This fee can be found in the House Rules.</p>
              </li>
            </ol>
          </li>
          <li data-list-text="2.3.">
            <p style="padding-top: 6pt;padding-left: 45pt;text-indent: -21pt;text-align: justify;">Membership:</p>
            <ol id="l5">
              <li data-list-text="2.3.1.">
                <p style="padding-top: 8pt;padding-left: 67pt;text-indent: -25pt;line-height: 121%;text-align: justify;">If You have subscribed to a Membership Agreement, You will have access to all participating centers worldwide during standard business working hours and subject to availability.</p>
              </li>
              <li data-list-text="2.3.2.">
                <p style="padding-top: 6pt;padding-left: 67pt;text-indent: -25pt;line-height: 121%;text-align: justify;">Membership Usage: Usage is measured in whole days and unused days cannot be carried over to the following month. A membership is not intended to be a replacement for a full-time workspace and all workspaces must be cleared at the end of each day. You are solely responsible for Your belongings at the center at all times. We are not responsible for any property that is left unattended. Should You use more than Your membership entitlement, We will charge You an additional usage fee. You may bring in 1 guest free of charge (subject to fair usage). Any additional guests will be required to purchase a day pass.</p>
              </li>
              <li data-list-text="2.3.3.">
                <p style="padding-top: 6pt;padding-left: 67pt;text-indent: -25pt;line-height: 121%;text-align: justify;">As a Member, You may not use any Center as Your business address without an accompanying office or virtual office agreement in place. Any use of the Center address in such a way will result in an automatic enrollment in the Virtual Office product for the same term as Your membership and You will be invoiced accordingly.</p>
              </li>
            </ol>
          </li>
          <li data-list-text="2.4.">
            <p style="padding-top: 6pt;padding-left: 50pt;text-indent: -27pt;line-height: 121%;text-align: justify;">Compliance with Law: You must comply with all relevant laws and regulations in the conduct of Your business. You must not do anything that may interfere with the use of the Center by Us or by others (including but not</p>
            <p style="padding-top: 4pt;padding-left: 51pt;text-indent: 0pt;line-height: 121%;text-align: justify;">limited to political campaigning or immoral activity), cause any nuisance or annoyance, or cause loss or damage to Us (including damage to reputation) or to the owner of any interest in the building. If We have been advised by any government authority or other legislative body that it has reasonable suspicion that You are conducting criminal activities from the Center, or You are or will become subject to any government sanctions, then We shall be entitled to terminate any and all of Your agreements with immediate effect. You acknowledge that any breach by You of this clause shall constitute a material default, entitling Us to terminate Your agreement without further notice.</p>
          </li>
          <li data-list-text="2.5.">
            <p style="padding-top: 6pt;padding-left: 45pt;text-indent: -21pt;line-height: 121%;text-align: justify;">Ethical Trading: Both We and You shall comply at all times with all relevant anti-slavery, anti-bribery, and anti-corruption laws.</p>
          </li>
          <li data-list-text="2.6.">
            <p style="padding-top: 6pt;padding-left: 45pt;text-indent: -21pt;text-align: justify;">Data Protection:</p>
            <ol id="l6">
              <li data-list-text="2.6.1.">
                <p style="padding-top: 8pt;padding-left: 67pt;text-indent: -25pt;line-height: 121%;text-align: justify;"><a href="http://www.iwgplc.com/clientprivacypolicy.)" class="a" target="_blank">Each party shall comply with all applicable data protection legislation. The basis on which we will process Your personal data is set out in our privacy policies (available on our website at </a><a href="http://www.iwgplc.com/clientprivacypolicy.)" target="_blank">www.iwgplc.com/clientprivacypolicy.)</a></p>
              </li>
              <li data-list-text="2.6.2.">
                <p style="padding-top: 6pt;padding-left: 67pt;text-indent: -25pt;line-height: 121%;text-align: justify;">You acknowledge and accept that we may collect and process personal data concerning You and/or your personnel in the course of our agreement for services with you. Such personal data will be processed in accordance with our privacy policy. Where you provide this data to us, you will ensure that you have the necessary consents and notices in place to allow for this.</p>
              </li>
            </ol>
          </li>
          <li data-list-text="2.7.">
            <p style="padding-top: 5pt;padding-left: 45pt;text-indent: -21pt;line-height: 121%;text-align: justify;">Employees: We will both have invested a great deal in training Our staff. Therefore, neither of us may knowingly solicit or offer employment to the other’s staff employed in any Center (or for 3 months after they have left their employment). To recompense the other for staff training and investment costs, if either of us breaches this clause the breaching party will pay upon demand the other the equivalent of 6 months’ salary of any employee concerned.</p>
          </li>
          <li data-list-text="2.8.">
            <p style="padding-top: 6pt;padding-left: 45pt;text-indent: -21pt;line-height: 121%;text-align: justify;">Confidentiality: The terms of an agreement are confidential. Neither of us may disclose them without the other’s consent unless required to do so by law or an official authority. This obligation continues for a period of 3 years after an agreement ends.</p>
          </li>
          <li data-list-text="2.9.">
            <p style="padding-top: 6pt;padding-left: 45pt;text-indent: -21pt;line-height: 121%;text-align: justify;">Assignment: An agreement is personal to You and cannot be transferred to anyone else without prior consent from Us unless such transfer is required by law. However, We will not unreasonably withhold our consent to assignment to an affiliate provided that You execute our standard form of assignment. We may transfer any agreement and any and all amounts payable by You under an agreement to any other member of Our group.</p>
          </li>
          <li data-list-text="2.10.">
            <p style="padding-top: 6pt;padding-left: 45pt;text-indent: -21pt;line-height: 121%;text-align: justify;">Applicable law: An agreement is interpreted and enforced in accordance with the law of the place where the Center is located other than in a few specific jurisdictions which are detailed in the House Rules. We and You both accept the exclusive jurisdiction of the courts of that jurisdiction. If any provision of these terms and conditions is held void or unenforceable under the applicable law, the other provisions shall remain in force.</p>
          </li>
        </ol>
      </li>
      <li data-list-text="3.">
        <p style="padding-top: 6pt;padding-left: 24pt;text-indent: -18pt;text-align: justify;">Our liability to You and Insurance</p>
        <ol id="l7">
          <li data-list-text="3.1.">
            <p style="padding-top: 8pt;padding-left: 51pt;text-indent: -27pt;line-height: 121%;text-align: justify;">The extent of Our liability: To the maximum extent permitted by applicable law, We are not liable to You in respect of any loss or damage You suffer in connection with an agreement, including without limitation any loss or damage arising as a result of our failure to provide a service as a result of mechanical breakdown, strike, or other event outside of Our reasonable control otherwise, unless We have acted deliberately or have been negligent. In no event shall We be liable for any loss or damage until You provide written notice and give Us a reasonable time to put it right. If We are liable for failing to provide You with any service under an agreement, then, subject to the exclusions and limits set out immediately below, We will pay any actual and reasonable additional expense You have incurred in obtaining the same or similar service from elsewhere.</p>
          </li>
          <li data-list-text="3.2.">
            <p style="padding-top: 6pt;padding-left: 51pt;text-indent: -27pt;line-height: 121%;text-align: justify;">Your Insurance: It is Your responsibility to arrange insurance for property which You bring in to the Center, for any mail You send or receive and for Your own liability to your employees and to third parties. We strongly recommend that You put such insurance in place.</p>
          </li>
          <li data-list-text="3.3.">
            <p style="padding-top: 6pt;padding-left: 51pt;text-indent: -27pt;line-height: 121%;text-align: justify;">IT Services and Obligations: Whilst We have security internet protocols in place and strive to provide seamless internet connectivity, WE DO NOT MAKE ANY REPRESENTATION AND CANNOT GUARANTEE ANY MAINTAINED LEVEL OF CONNECTIVITY TO OUR NETWORK OR TO THE INTERNET, NOR THE LEVEL OF SECURITY OF IT INFORMATION</p>
            <p style="padding-left: 51pt;text-indent: 0pt;line-height: 121%;text-align: justify;">AND DATA THAT YOU PLACE ON IT. You should adopt whatever security measures (such as encryption) You believe are appropriate to Your business. Your sole and exclusive remedy in relation to issues of reduced connectivity which are within Our reasonable control shall be for Us to rectify the issue within a reasonable time following notice from You to Us.</p>
          </li>
          <li data-list-text="3.4.">
            <p style="padding-top: 6pt;padding-left: 51pt;text-indent: -27pt;line-height: 121%;text-align: justify;">EXCLUSION OF CONSEQUENTIAL LOSSES: WE WILL NOT IN ANY CIRCUMSTANCES HAVE ANY LIABILITY TO YOU FOR LOSS OF BUSINESS, LOSS OF PROFITS, LOSS OF ANTICIPATED SAVINGS, LOSS OF OR DAMAGE TO DATA, THIRD</p>
            <p style="padding-top: 4pt;padding-left: 51pt;text-indent: 0pt;line-height: 121%;text-align: left;">PARTY CLAIMS OR ANY CONSEQUENTIAL LOSS. WE STRONGLY RECOMMEND THAT YOU INSURE AGAINST ALL SUCH POTENTIAL LOSS, DAMAGE, EXPENSE OR LIABILITY.</p>
          </li>
          <li data-list-text="3.5.">
            <p style="padding-top: 6pt;padding-left: 51pt;text-indent: -27pt;text-align: left;">Financial limits to our liability: In all cases, our liability to You is subject to the following limits:</p>
            <ol id="l8">
              <li data-list-text="3.5.1.">
                <p style="padding-top: 8pt;padding-left: 67pt;text-indent: -25pt;text-align: left;">without limit for personal injury or death;</p>
              </li>
              <li data-list-text="3.5.2.">
                <p style="padding-top: 8pt;padding-left: 67pt;text-indent: -25pt;line-height: 121%;text-align: justify;">up to a maximum of GBP 1 million (or USD 1.5 million or EUR 1 million or other local equivalent) for any one event or series of connected events for damage to Your personal property; and</p>
              </li>
              <li data-list-text="3.5.3.">
                <p style="padding-top: 6pt;padding-left: 67pt;text-indent: -25pt;line-height: 121%;text-align: justify;">in respect of any other loss or damage, up to a maximum equal to 125% of the total fees paid between the date services under an agreement commenced and the date on which the claim in question arises; or if higher, for office agreements only, GBP 50,000 / USD 100,000 / EUR 66,000 (or local equivalent).</p>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li data-list-text="4.">
        <p style="padding-top: 6pt;padding-left: 23pt;text-indent: -18pt;text-align: left;">Fees</p>
        <ol id="l9">
          <li data-list-text="4.1.">
            <p style="padding-top: 8pt;padding-left: 50pt;text-indent: -27pt;line-height: 121%;text-align: justify;">Service Retainer/Deposit: Your service retainer / deposit will be held by Us without generating interest as security for performance of all Your obligations under an agreement. All requests for the return must be made through Your online account or App after which the service retainer/deposit or any balance will be returned within 30 days to You once your agreement has ended and when You have settled Your account. We will deduct any outstanding fees and other costs due to Us before returning the balance to You. We will require You to pay an increased retainer if the monthly office or virtual office fee increases upon renewal, outstanding fees exceed the service retainer/deposit held, and/or You frequently fail to pay invoices when due.</p>
          </li>
          <li data-list-text="4.2.">
            <p style="padding-top: 6pt;padding-left: 50pt;text-indent: -27pt;line-height: 121%;text-align: justify;">Taxes and duty charges: You agree to pay promptly (i) all sales, use, excise, consumption and any other taxes and license fees which You are required to pay to any governmental authority (and, at Our request, You will provide to Us evidence of such payment) and (ii) any taxes paid by Us to any governmental authority that are attributable to Your accommodation, where applicable, including, without limitation, any gross receipts, rent and occupancy taxes, tangible personal property taxes, duties or other documentary taxes and fees.</p>
          </li>
          <li data-list-text="4.3.">
            <p style="padding-top: 6pt;padding-left: 50pt;text-indent: -27pt;line-height: 121%;text-align: justify;">Payment: We are continually striving to reduce our environmental impact and support You in doing the same. Therefore, We will send all invoices electronically and You will make payments via an automated method such as Direct Debit or Credit Card (wherever local banking systems permit). If You do not set up an automatic form of payment, You will be charged a refundable payment retainer equal to one time your monthly product fee. Invoices are due and payable on the due date stated in them.</p>
          </li>
          <li data-list-text="4.4.">
            <p style="padding-top: 6pt;padding-left: 50pt;text-indent: -27pt;line-height: 121%;text-align: justify;">Late payment: If You do not pay fees when due, a fee will be charged on all overdue balances. This fee will differ by country and is listed in the House Rules. If any part of an invoice is legitimately disputed, You shall give immediate written notice to Us, follow the requirements of the Disputes clause in the House Rules, and pay the amount not in dispute by the due date or be subject to late fees. We also reserve the right to withhold services (including for the avoidance of doubt, denying You access to the Center where applicable) while there are any outstanding fees and/or interest or You are in breach of an agreement.</p>
          </li>
          <li data-list-text="4.5.">
            <p style="padding-top: 6pt;padding-left: 51pt;text-indent: -27pt;line-height: 121%;text-align: justify;">Insufficient Funds: Due to the additional administration We incur, You will pay a fee for any returned or declined payments due to insufficient funds. This fee will differ by country and is listed in the House Rules.</p>
          </li>
          <li data-list-text="4.6.">
            <p style="padding-top: 5pt;padding-left: 51pt;text-indent: -27pt;line-height: 121%;text-align: justify;">Activation: An activation fee is payable in respect of each agreement You have with Us (including any new agreements entered into under clause 1.10 above). This fee covers the administrative cost of the client onboarding process and account setup. This fee is set out in each Local Services Agreement and is charged on a per occupant basis for Serviced Office and Coworking (dedicated desk), on a per location basis for Virtual Office, and on a per person basis for Membership. Further information is set out in the House Rules.</p>
          </li>
          <li data-list-text="4.7.">
            <p style="padding-top: 6pt;padding-left: 45pt;text-indent: -21pt;line-height: 115%;text-align: left;">Indexation: If an agreement, including month to month agreements, continues for more than 12 months, We will increase the monthly fee on each anniversary of the start date in line with the relevant inflation index detailed in the current House Rules. If a country experiences high levels of inflation, indexation could be applied more frequently and is detailed in the current House Rules.</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
          </li>
          <li data-list-text="4.8.">
            <p style="padding-left: 51pt;text-indent: -27pt;line-height: 121%;text-align: justify;">Office Restoration: Upon Your departure or if You choose to relocate to a different room within a Center, We will charge a fixed office restoration service fee to cover normal cleaning and any costs incurred to return the accommodation to its original condition and state. This fee will differ by country and is listed in the House Rules. We reserve the right to charge additional reasonable fees for any repairs needed above and beyond normal wear and tear.</p>
          </li>
          <li data-list-text="4.9.">
            <p style="padding-top: 6pt;padding-left: 51pt;text-indent: -27pt;line-height: 121%;text-align: justify;">Standard services: Monthly fees, plus applicable taxes, and any recurring services requested by You are payable monthly in advance. Where a daily rate applies, the charge for any such month will be 30 times the daily fee. For a period of less than one month, the fee will be applied on a daily basis.</p>
          </li>
          <li data-list-text="4.10.">
            <p style="padding-top: 4pt;padding-left: 50pt;text-indent: -27pt;line-height: 121%;text-align: justify;">Pay-as-you-use and Additional Variable Services: Fees for pay-as-you-use services, plus applicable taxes, are payable monthly in arrears at our standard rates which may change from time to time and are available on request.</p>
          </li>
          <li data-list-text="4.11.">
            <p style="padding-top: 6pt;padding-left: 50pt;text-indent: -27pt;line-height: 121%;text-align: justify;">Additional Fees: If Your use of the accommodation or treatment of the accommodation requires Us to incur additional costs for the provision of nonstandard service(s), including but not limited to deep cleaning, unusual trash removal, pest remediation, or additional security, We reserve the right to charge You for the cost of these services plus an additional 20% administration fee.</p>
          </li>
          <li data-list-text="4.12.">
            <p style="padding-top: 6pt;padding-left: 50pt;text-indent: -27pt;text-align: justify;">Discounts, Promotions and Offers: If You benefited from a special discount, promotion or offer, We will discontinue that discount, promotion or offer without notice if You materially breach Your agreement.</p>
          </li>
        </ol>
      </li>

      <li data-list-text="5.">
        <p style="padding-top: 6pt;padding-left: 23pt;text-indent: -18pt;text-align: left;">Stored Mails</p>
        <ol id="l9">
          <li data-list-text="5.1.">
            <p style="padding-top: 8pt;padding-left: 50pt;text-indent: -27pt;line-height: 121%;text-align: justify;">For mail items stored beyond the initial 3-month period, the User agrees to pay an extended storage fee, which will be communicated to the User in advance. The extended storage fee will be charged at regular intervals (e.g., monthly or quarterly) until the mail items are retrieved or the storage services are terminated.</p>
          </li>
          <li data-list-text="5.2.">
            <p style="padding-top: 8pt;padding-left: 50pt;text-indent: -27pt;line-height: 121%;text-align: justify;">The Company will notify the User in writing (via email or any other preferred method of communication) when a mail item's storage period approaches the 3-month limit. The User must respond to the notification and provide instructions regarding the disposition of the mail item, including retrieval, further storage, or disposal.</p>
          </li>
          <li data-list-text="5.3.">
            <p style="padding-top: 8pt;padding-left: 50pt;text-indent: -27pt;line-height: 121%;text-align: justify;">If the User fails to respond to the notification or fails to pay the extended storage fee within a reasonable timeframe, the Company reserves the right to consider the mail items abandoned. In such cases, the Company may, at its sole discretion, dispose of the mail items or take any appropriate action in compliance with applicable laws.</p>
          </li>
        </ol>
      </li>
    </ol>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>

    <p class="s3" style="text-indent: 0pt;text-align: right;">Global Terms Feb 2023</p>
  </div>

  <div style="text-align:center">
    <el-checkbox v-model="agree" @change="update()">I agree</el-checkbox>
  </div>

</div>
</template>

<script>
export default {
  data() {
    return {
      agree: false
    };
  },
  methods: {
    update() {
      this.$emit('change', this.agree)
    }
  }
}
</script>

<style lang="scss">
.selected {
  background: #bbffd2;
}

h4 {
  text-align: justify;
}

ol {
  padding-left: 5px;
}

ol li p {
  padding-left: 25px !important;
  text-align: justify;
  text-indent: 0px !important;
}
</style>
