var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" Create Mail ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Recipient (Client)")]),_c('ValidationProvider',{attrs:{"name":"recipient","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{staticStyle:{"display":"none"},attrs:{"type":"text","error":failed ? 'The recipient field is required' : null,"hasSuccess":passed,"name":"recipient","readonly":true},model:{value:(_vm.form.user_id),callback:function ($$v) {_vm.$set(_vm.form, "user_id", $$v)},expression:"form.user_id"}}),_c('search-users',{attrs:{"passed":passed,"has_plan":true,"error":failed ? 'The recipient field is required' : null},on:{"update":function($event){return _vm.setRecipient($event)}},model:{value:(_vm.form.user_id),callback:function ($$v) {_vm.$set(_vm.form, "user_id", $$v)},expression:"form.user_id"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Subject")]),_c('div',{staticClass:"mb-2"},[_c('small',{staticClass:"orange-text"},[_vm._v("Click to use")]),_c('br'),_vm._l((_vm.subject_helpers),function(subject){return _c('el-button',{key:subject,attrs:{"size":"small"},on:{"click":function($event){return _vm.useSubject(subject)}}},[_vm._v(_vm._s(subject))])})],2),_c('ValidationProvider',{attrs:{"name":"subject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The subject field is required' : null,"hasSuccess":passed,"name":"subject"},model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", $$v)},expression:"form.subject"}})]}}],null,true)})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Message")]),_c('div',{staticClass:"mb-2"},[_c('small',{staticClass:"orange-text"},[_vm._v("Click to use")]),_c('br'),_vm._l((_vm.message_helpers),function(message){return _c('el-button',{key:message,attrs:{"size":"small"},on:{"click":function($event){return _vm.useMessage(message)}}},[_vm._v(_vm._s(message))])})],2),_c('ValidationProvider',{attrs:{"name":"message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"textarea":true,"type":"text","error":failed ? 'The message field is required' : null,"hasSuccess":passed,"name":"message"},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})]}}],null,true)})],1)]),_c('div',{staticClass:"col-md-4"})]),_c('div',{staticClass:"form-group"},[_c('file-upload',{attrs:{"multiple":false},on:{"change":function($event){return _vm.setFiles($event)}}})],1)]),_c('div',{staticClass:"card-footer text-right"},[_c('p-button',{attrs:{"type":"info","native-type":"submit"}},[_vm._v("Create")])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }