import api from './api'

const API = '/api/user/mails'

export const UserMail = {
  takeAction: function(id, params) {
    return api.create(API + '/'+id+'/take-action', params)
  },
  all: function(params) {
    return api.get(API+'/all', params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, {params: params})
  },
  create: function (formData) {
    console.log(formData)
    return api.createFormData(API, formData)
  },
  updateCompany: function(params) {
    return api.update(API+'/'+params.id+'/update-company', params)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
