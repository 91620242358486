<template>
  <div style="">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <h2 style="text-align:center">Terms and Conditions</h2>
  <terms-and-condition style="height: 500px;overflow:scroll" @change="getAgree($event)"></terms-and-condition>  
      </div>
      <div class="col-md-2"></div>
    </div>
    
  </div>
</template>
<script>
import TermsAndCondition from './TermsAndCondition.vue';

export default {
  components: { TermsAndCondition },
  props: ['company','plan'],
  data() {
    return {
      agree: false,
      plans: []
    };
  },
  created: function () {
  
  },
  methods: {
    getAgree(agree) {
      this.agree = agree
    },
    validate() {
      if (this.agree) {
        this.$emit('on-validated', true, null, 'verify')
        return true
      } else {
        this.$notify({
          type: 'danger',
          title: "Error",
          text: 'You have to agree to the terms and conditions',
        });
        
        return false
      }
    }
  }
}
</script>
<style lang="scss">
.selected {
  background: #bbffd2;
}
</style>
