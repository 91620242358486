<template>
  <div v-loading.fullscreen.lock="page_loading">
    <div v-if="meeting_room">
       <h4>Manage {{ meeting_room.name }}</h4>
       <p></p>
       <hr>
       <el-tabs v-model="activeTab" @tab-click="handleClick">
        <el-tab-pane label="Daily Schedules" name="days">
          <meeting-room-day-schedules :meeting_room="meeting_room"></meeting-room-day-schedules>
        </el-tab-pane>
        <el-tab-pane label="Pictures" name="pictures">
            <meeting-room-pictures-list :meeting_room="meeting_room"></meeting-room-pictures-list>
          </el-tab-pane>
      </el-tabs>
       <div class="row">
        <div class="col-md-6">
          
        </div>
       </div>
    </div>
  </div>
</template>
<script>

import { MeetingRoom } from '@/resources/meeting_room'
import MeetingRoomDaySchedules from './MeetingRoomDaySchedules.vue'
import MeetingRoomPicturesList from './MeetingRoomPicturesList.vue'

export default {
  components: { MeetingRoomDaySchedules, MeetingRoomPicturesList },
  data() {
    return {
      activeTab: "days",
      meeting_room: null,
      page_loading: false
    }
  },
  created: function () {
    if (this.$route.query.tab) this.activeTab = this.$route.query.tab
    this.getRoom(this.$route.params.id)
  },
  methods: {
    handleClick(tab, event) {
      this.$router.replace({
        path: this.$route.path,
        query: { tab: this.activeTab }
      })
    },
    getRoom(id) {
      this.page_loading = true
      MeetingRoom.show(id)
        .then(result => {
          if (result.data.meeting_room)
            this.meeting_room = result.data.meeting_room
        })
        .catch(() => {
        }).finally(() => {
          this.page_loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
