<template>
  <div v-loading.fullscreen.lock="page_loading">
    <div v-if="meeting_room">
      <h5>Maximum of {{max}} pictures only</h5>
      <el-upload
      :on-change="onChange"
        action="#"
        list-type="picture-card"
         :file-list="fileList"
        :auto-upload="false">
          <i slot="default" class="el-icon-plus"></i>
          <div slot="file" slot-scope="{file}">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url" alt=""
            >
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                class="el-upload-list__item-delete"
                @click="onRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
      </el-upload>

      <el-button @click="save()">Save</el-button>

      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
    </div>
  </div>
</template>
<script>

import { MeetingRoom } from '@/resources/meeting_room'

export default {
  props: ['meeting_room'],
  data() {
    return {
      page_loading: false,
      fileList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      max: 5,
      remove_pictures: []
    }
  },
  created: function () {
    let list = []

    if (this.meeting_room.pictures) {
      this.meeting_room.pictures.items.forEach(function (item) {
        list.push({
          name: item.file_name,
          url: item.link,
          uid: item.file_name,
          link: item.link,
          path: item.path
        })
      })
    }
    
    this.fileList = list
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    onRemove(file) {
      let newList = []
      this.fileList.forEach(function (f) {
        if (file.name != f.name) {
          newList.push(f)
        }
      })

      this.fileList = newList
      if (file.path) this.remove_pictures.push(file.path)
    },
    onChange(file, fileList) {

      if (fileList.length > this.max) {
        this.$notify({
          type: 'error',
          title: "Error",
          text: '5 pictures only',
        });
        error = true
        this.fileList.splice(fileList.length - 1, 1)
        return false
      }
      const isJPG =  ['image/jpeg', 'image/png'].includes(file.raw.type)
      const isLt2M = file.size / 1024 / 1024 < 10;
      let error = false
      if (!isJPG) {
        this.$notify({
                type: 'error',
                title: "Error",
                text: 'Picture must be JPG or PNG format!',
        });
              error = true
      }
      if (!isLt2M) {
        this.$notify({
          type: 'error',
          title: "Error",
          text: 'Picture size can not exceed 10MB!',
        });
        error = true
      }

      if (error) this.onRemove(file)
      else this.fileList.push(file)
    },
    save() {
      this.page_loading = true

      MeetingRoom.savePictures(this.meeting_room.id, this.getFormData())
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Pictures have been saved',
          });
          this.remove_pictures = []
        })
        .catch(() => {

        }).finally(() => {
          this.page_loading = false
        })
    },
    getFormData() {
      var formData = new FormData()
      formData.append('id', this.meeting_room.id)
      this.fileList.forEach(file => {
        if (file.raw) formData.append('files[]', file.raw)
      });

      this.remove_pictures.forEach(function (picture) {
        formData.append('remove_pictures[]', picture)
      })

      return formData
    }
  }
}
</script>
<style lang="scss"></style>
