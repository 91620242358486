import api from './api'

const API = '/api/meeting-rooms'

export const MeetingRoom = {
  get: function(params) {
    return api.get(API, params)
  },
  availableRooms: function(params) {
    return api.get(API + '/available-rooms', params)
  },
  getBookings: function(params) {
    return api.get(API + '/bookings', params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  savePictures: function(id, form) {
    return api.createFormData(API + '/' + id + '/save-pictures', form)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, {params: params})
  },
  daySchedules: function(id, params) {
    return api.get(API + '/' + id + '/day-schedules', {params: params})
  },
  bookedHours: function(id, params) {
    return api.get(API + '/' + id + '/booked-hours', {params: params})
  },
  setDaySchedule: function(id, params) {
    return api.create(API + '/' + id + '/set-day-schedule', params)
  },
  createBooking: function(id, params) {
    return api.create(API + '/' + id + '/create-booking', params)
  },
  getBookingByCode: function(code, params) {
    return api.get(API + '/' + code + '/get-booking-by-code', params)
  },
  update: function (params) {
    return api.update(API+'/'+params.id, params)
  }
}