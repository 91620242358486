<template>
  <ValidationObserver v-slot="{ handleSubmit }"  v-loading="loading">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Create Mail
          </h4>
        </div>
        <div class="card-body">
          <!-- <div class="form-group">
            <label>Sender</label>
            <ValidationProvider name="sender" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The sender field is required' : null" :hasSuccess="passed"
                name="sender" v-model="form.sender">
              </fg-input>
            </ValidationProvider>
          </div> -->
          <div class="form-group">
              <label>Recipient (Client)</label>
              <ValidationProvider name="recipient" rules="required" v-slot="{ passed, failed }">
                <fg-input type="text" :error="failed ? 'The recipient field is required' : null" :hasSuccess="passed"
                  name="recipient" v-model="form.user_id" :readonly="true" style="display:none">
                </fg-input>
      
                <search-users  v-model="form.user_id" @update="setRecipient($event)" :passed="passed" :has_plan="true"
                    :error="failed ? 'The recipient field is required' : null"></search-users>
              </ValidationProvider>
            </div>
          <div class="form-group">
              <label>Subject</label>
              <div class="mb-2"> 
                <small class="orange-text">Click to use</small><br>
                <el-button size="small" v-for="subject in subject_helpers" :key="subject" @click="useSubject(subject)">{{ subject }}</el-button>
              </div>
              <ValidationProvider name="subject" rules="required" v-slot="{ passed, failed }">
                <fg-input type="text" :error="failed ? 'The subject field is required' : null" :hasSuccess="passed"
                  name="subject" v-model="form.subject">
                </fg-input>
              </ValidationProvider>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <label>Message</label>
                  <div class="mb-2"> 
                    <small class="orange-text">Click to use</small><br>
                    <el-button size="small" v-for="message in message_helpers" :key="message" @click="useMessage(message)">{{ message }}</el-button>
                  </div>
                  <ValidationProvider name="message" rules="required" v-slot="{ passed, failed }">
                      <fg-input :textarea="true" type="text" :error="failed ? 'The message field is required' : null" :hasSuccess="passed"
                        name="message" v-model="form.message">
                      </fg-input>
                    
                    </ValidationProvider>
                  
                </div>
              </div>
              <div class="col-md-4">
               
              </div>
            </div>
          
          <div class="form-group">
            <file-upload @change="setFiles($event)" :multiple="false"></file-upload>
          </div>
          
        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Create</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import FileUpload from '../../Dashboard/Views/Components/FileUpload.vue';
import { Mail } from '@/resources/mail';
import RemoteSearch from '../../Dashboard/Views/Components/RemoteSearch.vue';
import SearchUsers from '../../Dashboard/Views/Components/SearchUsers.vue';

extend("email", email);
extend("required", required);

export default {
  components: { FileUpload, RemoteSearch, SearchUsers },
  data() {
    return {
      form: {
        sender: "",
        subject: "",
        message: "",
        user_id: null
      },
      loading: false,
      files: [],
      message_helpers: [
        "Hi, attached is a new mail for you.",
        "Hello, there's a new mail waiting for you."
      ],
      subject_helpers: [
        "New Mail",
        "You've Got Mail",
        "Notification",
        "New Message"
      ]
    };
  },
  methods: {
    useMessage(message) {
      this.form.message = message
    },
    useSubject(subject) {
      this.form.subject = subject
    },
    setRecipient(user) {
      this.form.user_id = user ? user.id : null
    },
    setFiles(files) {
      this.files = files
    },
    submit() {
      this.loading = true

      Mail.create(this.getFormData())
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: "Mail has been sent",
          });
          this.$router.push({ name: 'AllMails' })
        })
        .catch(error => {
      
        }).finally(() => {
          this.loading = false
        })
    },
    getFormData() {
      var formData = new FormData()
      formData.append('sender', this.form.sender)
      formData.append('message', this.form.message)
      formData.append('subject', this.form.subject)
      formData.append('user_id', this.form.user_id)

      this.files.forEach(file => {
        if (file.raw) formData.append('files[]', file.raw)
      });

      return formData
    }
  }
}
</script>
<style></style>
