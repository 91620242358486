<template>
  <div v-if="user" v-loading.fullscreen.lock="fullscreen_loading" >
    <h4 v-if="pay_plan">You have to pay the Plan amount to start using the service</h4>
    <h4 v-else>Cash In</h4>
    <hr>
    <div v-if="step == 0">
      
      <ValidationObserver ref="form">
        <form @submit.prevent="validate">
          <div class="row">
            <div class="col-sm-3">
              <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
                <label>Type the amount you want to load (Min ${{ min }})</label>
                <fg-input type='number' name="name" :min="min" :error="failed ? 'Amount is required' : null" :hasSuccess="passed"
                  placeholder="Amount (required)" v-model="form.amount" addon-left-icon="nc-icon nc-single-02">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <div class="row">
            <div class="col-md-3">
              <el-button :disabled="!form.amount" type="success" @click="next()" v-loading="loading">{{ loading ? 'loading' : 'Next' }}</el-button>
            </div>
      </div>
    </div>
     
    <div v-if="step == 1">
      
      <h4>{{ pay_plan ? 'Pay ': 'Load' }} amount ${{ form.amount }} <el-link type="warning" class="danger-link" @click="step = 0">(Change)</el-link></h4>
      <a :href="payment_link" ref="paymentLink" ></a>
       <hr>
       <div class="row">
        <div class="col-md-3">
          <payment-button :amount="form.amount" :label="form.amount" :description="'Cash In'" @success="success()"></payment-button>
        </div>
       </div>

       <div class="row">
          <div class="col-md-3">
           <p>{{ user && user.default_payment ? 'OR' : '' }} Tap/Scan QR to Pay</p>
            <a  @click="showPay()" style="cursor: pointer;display: block;" >
              <qr-code 
                v-if="payment_link"
                  :text="payment_link"
                  error-level="L">Test
              </qr-code>
            </a>

          </div>
        </div>
    </div>

  </div>
</template>
<script>

import { mapState } from 'vuex';
import Vue from 'vue'
import VueQRCodeComponent from 'vue-qrcode-component'
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { User } from '@/resources/user'
import { CompanyCard } from '@/resources/company_card'


import Swal from 'sweetalert2'
import PaymentButton from '../../UIComponents/PaymentButton.vue';

extend("required", required);

Vue.component('qr-code', VueQRCodeComponent)

export default {
  components: { PaymentButton },
  data() {
    return {
      loading: false,
      form: {
        amount: 10
      },
      step: 0,
      payment_link: null,
      payment_link_id: null,
      status: 0,
      fullscreen_loading: false,
      pay_plan: false,
      min: 10,
      update_payment_method_link: null
    }
  },
  created: function () {
    let id = this.$route.query.payment_link_id

    if (id) {
      this.getUserCashInRequest(id)
    }

    let paymentToken = this.$route.query.payment_token

    if (paymentToken) {
      if (this.$route.query.pay_plan) {
        this.pay_plan = true
      }
      this.approvePayment(paymentToken)
    } else {
      if (this.$route.query.pay_plan) {
        this.pay_plan = true
        this.status = 1
        this.form.amount = this.user.company.plan_amount
        this.min = this.user.company.plan_amount
        this.next()
      }
    }
  },
  methods: {
    approvePayment(payment_token) {
      this.fullscreen_loading = true
      let params = { payment_token: payment_token }
      if (this.pay_plan) params.pay_plan = true
      User.approvePayment(params)
        .then(result => {
           this.success()
        })
        .catch(() => {
        }).finally(() => {
          this.fullscreen_loading = false
        })
    },
    getUserCashInRequest(payment_link_id) {
      this.loading = true

      User.getUserCashRequest({ params: { payment_link_id: payment_link_id  } })
        .then(result => {
          this.payment_link = result.data.user_cash_request.payment_link
          this.payment_link_id = result.data.user_cash_request.payment_link_id
          this.form.amount = result.data.user_cash_request.amount
          this.status = result.data.user_cash_request.status
          this.step = 1
        })
        .catch(() => {
        }).finally(() => {
          this.loading = false
        })
    },
    async success() {
      const { value: res } = await Swal.fire({
        title: "Success",
        text: "Thank you for loading",
        icon: "success",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      })

      this.$router.push({ name: 'Home', query: { refresh: true } })
    },
    checkIfPaid() {
      this.loading = true

      User.getUserCashRequest({ params: { payment_link_id: this.payment_link_id } })
        .then(result => {
          console.log(result.data.payment_link)
          this.status = result.data.payment_link.status

          if (this.status == 1) {
            this.success()
          } else {
             this.$notify({
              type: 'danger',
              title: "Error",
              text: 'Payment not yet processed',
            });
          }

          this.step = 1
        })
        .catch(() => {
        }).finally(() => {
          this.loading = false
        })
    },
     validate() {
      return this.$refs.form.validate().then(res => {
        this.$emit('on-validated', res, this.form, 'company')
        return res
      })
    },
    showPay() {
      const elem = this.$refs.paymentLink
      if (elem) elem.click()
    },
    next() {
      this.loading = true

      if (!this.form.amount || this.form.amount < this.min) {
        this.$notify({
          type: 'danger',
          title: "Error",
          text: 'Amount must be at least $'+this.min,
        });
        this.loading = false
        return false
      }
      
      User.createPaymentLink({ amount: this.form.amount, pay_plan: this.pay_plan })
        .then(result => {
          this.step = 1
          this.payment_link = result.data.payment_link.url
          this.payment_link_id = result.data.payment_link.id
          let query = { payment_link_id: this.payment_link_id, }
          if (this.pay_plan) query.pay_plan = true
          this.$router.replace({
            path: this.$route.path,
            query: query,
            replace: true
          })
        })
        .catch(() => {
        }).finally(() => {
          this.loading = false
        })
    }
  },
  computed: mapState(['user']),
}
</script>
<style lang="scss"></style>
