<template>
  <div class="">
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item label="Referral Code">
        <el-input v-model="form.code" placeholder="Put the referral code here" :disabled="loading"></el-input>
      </el-form-item>
      <div v-if="userReferrer">
      <div style="margin-left: 12px;">Found User: {{ userReferrer.name }}</div>
      </div>
      <hr>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" size="small" :disabled="!userReferrer">Save</el-button>
        <el-button size="small" @click="close">Close</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import { UserReferral } from '@/resources/user_referral'

export default {
  props: ['user'],
  data() {
    return {
      loading: false,
      userReferrer: null,
      form: {
        code: null
      }
    }
  },
  watch: {
    'form.code': function () {
      this.userReferrer = null
      if (this.form.code && this.form.code.length == 8) {
        this.checkRefCode()
      }
    }
  },
  created: function () {
  },
  methods: {
    close() {
      this.$emit('update', false)
    },
    checkRefCode() {
      this.loading = true
      this.userReferrer = null
      UserReferral.findByCode({ code: this.form.code})
        .then(result => {
          this.userReferrer = result.data.user
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
    onSubmit() {
      this.loading = true
      UserReferral.setUserReferrer({ code: this.form.code, user_id: this.user.id })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'User Referral has been saved',
          });
          this.$emit('update', true)
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
