<template>
  <div v-if="user" v-loading.fullscreen.lock="loading || loading_via_card">
    <el-button type="info" @click="createPaymentMethodLink()" class="btn-block mb-3 ">{{ loading ? 'Checking' :
      (user.default_payment ? 'Update ' : 'Set ') + ' your payment card' }}</el-button>
       <a :href="update_payment_method_link" ref="paymentMethodLink" ></a>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import { Payment } from '@/resources/payment'
import { CompanyCard } from '@/resources/company_card'

import Swal from 'sweetalert2'

export default {
  props: ['amount', 'label', 'type', 'description', 'code'],
  data() {
    return {
      loading_via_card: false,
      loading: false,
      update_payment_method_link: null,
      allowed_payment_balance_types: ['renew', 'meeting_room_booking']
    }
  },
  created: function () {
  },
  methods: {
    createPaymentMethodLink() {
      this.loading = true
      CompanyCard.createPaymentMethodLink({ return_url: this.$route.fullPath })
        .then(result => {
          this.update_payment_method_link = result.data.url
          const elem = this.$refs.paymentMethodLink
          setTimeout(() => {
            if (elem) elem.click()
          }, 1000);
        })
        .catch(() => {
        }).finally(() => {
          this.loading = false
        })
    }
  },
  computed: mapState(['user']),
}
</script>
<style lang="scss"></style>
