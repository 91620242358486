var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title",staticStyle:{"text-align":"center"}},[_vm._v(" Please complete your company details ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Company Name")]),_c('ValidationProvider',{attrs:{"name":"name","rules":{
                  required: true,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
return [_c('div',{class:!passed ? 'has-danger' : ''},[_c('el-input',{attrs:{"type":"text"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(!passed)?_c('div',{staticClass:"invalid-feedback error-text"},[_vm._v(" Company name is required ")]):_vm._e()],1)]}}],null,true)})],1)])]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Business Type")]),_c('ValidationProvider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
return [_c('div',{class:!passed ? 'has-danger' : ''},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.type),expression:"form.type"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.business_types),function(t){return _c('option',{key:t,domProps:{"value":t}},[_vm._v(_vm._s(t))])}),0),(!passed)?_c('div',{staticClass:"invalid-feedback error-text"},[_vm._v(" Select business type ")]):_vm._e()])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Mobile Number")]),_c('ValidationProvider',{attrs:{"name":"mobile_number","rules":{
              required: true,
              regex: /^[0-9]{10}$/
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var passed = ref.passed;
return [_c('div',{class:!passed ? 'has-danger' : ''},[_c('el-input',{attrs:{"type":"number"},model:{value:(_vm.form.mobile_number),callback:function ($$v) {_vm.$set(_vm.form, "mobile_number", $$v)},expression:"form.mobile_number"}}),_c('div',[_c('small',{staticClass:"gray-text"},[_vm._v("Your number will be used in sending verification "),_c('code')])]),(!passed)?_c('div',{staticClass:"invalid-feedback error-text"},[_vm._v(" Please provide a valid number (Ex 1234567890) ")]):_vm._e()],1)]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Website")]),_c('ValidationProvider',{attrs:{"name":"website","rules":{
                regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var passed = ref.passed;
return [_c('div',{class:!passed ? 'has-danger' : ''},[_c('el-input',{attrs:{"type":"text"},model:{value:(_vm.form.website),callback:function ($$v) {_vm.$set(_vm.form, "website", $$v)},expression:"form.website"}}),(!passed)?_c('div',{staticClass:"invalid-feedback error-text"},[_vm._v(" Website not valid ")]):_vm._e()],1)]}}],null,true)})],1),_c('hr')]),_c('div',{staticClass:"card-footer text-right"},[_c('p-button',{attrs:{"type":"info","native-type":"submit"}},[_vm._v("Save")])],1)])])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }