<template>
  <div>
    <loading :loading="loading"></loading>
    <div class="row" v-if="user">
      <div class="col-md-3">
        <UserProfileCard :user="user"></UserProfileCard>
        <payment-method-link-button :other="true"></payment-method-link-button>
      </div>
      <div class="col-md-9">
        <div class="card">
          <div class="card-body">
            <tabs value="Description" tab-content-classes="text-center">
              <tab-pane title="Mail Box" key="mailbox" v-if="user.company && user.company.company_plan">
                    <user-mail-box :user="user" @update="mailBoxUpdated(user.id)"></user-mail-box>
                  </tab-pane>
              <tab-pane title="Transactions" key="transactions">
                  <user-transactions-list :user="user"></user-transactions-list>
                </tab-pane>
              <tab-pane title="Account" key="home">
                <user-edit-form :user="user"></user-edit-form>
              </tab-pane> 
              <tab-pane title="Roles" key="roles">
                <user-roles :user="user"></user-roles>
                </tab-pane>      
            </tabs>
          </div>
        </div>

        
      </div>
    </div>
  </div>
</template>
<script>

  import { User } from '@/resources/user'
  import UserProfileCard from '@/components/UIComponents/Cards/UserProfileCard'
  import {TabPane, Tabs} from '@/components/UIComponents'
  import UserCompanies from './UserCompanies'
import UserEditForm from './UserEditForm.vue'
import PaymentMethodLinkButton from '../../../UIComponents/PaymentMethodLinkButton.vue'
import UserTransactionsList from './UserTransactionsList.vue'
import UserRoles from './UserRoles.vue'
import UserMailBox from './UserMailBox.vue'

  export default{
  components: { UserProfileCard, TabPane, Tabs, UserCompanies, UserEditForm, PaymentMethodLinkButton, UserTransactionsList, UserRoles, UserMailBox },
    props: ['selected'],
    data () {
      return {
        loading: true,
        user: null
      }
  },
  watch: {
    selected: function () {
      if (this.selected) {
        this.getUser(this.selected.id)
      }
    }
  },
  created: function () {
      let id = this.$route.params.id
      if (this.selected) {
        id = this.selected.id
      }

      this.getUser(id)
    },
  methods: {
      mailBoxUpdated(id) {
      this.$emit('update', true)
        this.getUser(id)
      },
      handleEdit(index, data) {
          console.log(data)
      },
      getUser(id) {
        this.loading = true
        this.user = null
        User.show(id)
        .then(result => {
          if (result.data.user) this.user = result.data.user
        })
        .catch( () => {
        

        }).finally( () => {
          this.loading = false
        })
      }
    }
  }
</script>
<style lang="scss">

</style>
