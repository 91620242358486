<template>
  <el-upload
    class="upload-demo"
    ref="upload"
    :drag="drag"
    action="https://jsonplaceholder.typicode.com/posts/"
    :on-preview="handlePreview"
    :on-remove="handleRemove"
    :on-change="handleOnChange"
    :file-list="fileList"
    :auto-upload="false"
    :multiple="multiple">
    <i class="el-icon-upload"></i>
    <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
    <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div>
  </el-upload>
</template>

<script>

export default {
  data() {
    return {
      fileList: [],
      file: null,
      newFiles: []
    };
  },
  watch: {
    fileList: {
      handler: function (newVal) {
        console.log(newVal)
        this.update()
      },
      deep: true
    },
  },
  props: {
    multiple: {
      type: Boolean,
      description: 'Whether accepts multiple files',
      default: true
    },
    drag: {
      type: Boolean,
      description: 'Whether draggable',
      default: true
    },
  },
  methods: {
    handlePreview(ob) {
      
    },
    handleRemove(file, fileList) {
      // this.update(fileList)
    },
    handleOnChange(file, fileList) {
      // file.status = 'success'
      this.fileList = !this.multiple ? [file] : fileList
    },
    update() {
      let files = []

      this.fileList.forEach(file => {
        files.push(file)
      });
      this.$emit('change', files)
    }
  }
}
</script>
<style></style>
