<template>
  <ValidationObserver v-slot="{ handleSubmit }" v-loading="loading">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ form.id ? 'Update Promo' : 'Create new Promo' }}
          </h4>
        </div>
        <div class="card-body">
      

          <div class="form-group">
            <label>Code</label>
            <ValidationProvider name="code" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The code field is required' : null" :hasSuccess="passed" name="code"
                v-model="form.code">
              </fg-input>
            </ValidationProvider>
          </div>

          <div class="form-group">
            <label>Description</label>
            <ValidationProvider name="description" v-slot="{ passed, failed }">
              <fg-input type="textarea" :error="failed ? 'The description field is required' : null" :hasSuccess="passed"
                name="description" v-model="form.description">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label>Promo Type</label>
            <ValidationProvider name="promo_type" rules="required" v-slot="{ passed, failed }">
              <el-select v-model="form.promo_type"
                      filterable
                              class=""
                              :class="passed ? 'is-valid' : ''"
                              name="promo_type">
                    <el-option v-for="promo_type in promo_types"
                                :label="promo_type"
                                :value="promo_type"
                                :key="promo_type"></el-option>
                  </el-select>
                  <small id="passwordHelp" class="text-danger" v-if="failed">The promo type field is required</small>     
            </ValidationProvider>
          </div>
          
          <div class="form-group">
                <label>Total</label>
                <ValidationProvider name="total" v-slot="{ failed }" >
                  <el-input name="total" type="number" v-model="form.total" :class="failed ? 'input-error' : ''"></el-input>
                  <small id="help" class="text-danger" v-if="failed">The end date type field is required</small>     
                </ValidationProvider>
              </div>

          <div class="form-group">
              <label>Discount Type</label>
              <ValidationProvider name="discount_type" rules="required" v-slot="{ passed, failed }">
                <el-select v-model="form.discount_type"
                        filterable
                                class="select-primary"
                                :class="passed ? 'is-valid' : ''"
                                name="promo_type">
                      <el-option v-for="discount_type in discount_types"
                                  :label="discount_type.label"
                                  :value="discount_type.value"
                                  :key="discount_type.vaue"></el-option>
                    </el-select>
                    <small id="passwordHelp" class="text-danger" v-if="failed">The promo type field is required</small>     
              </ValidationProvider>
            </div>

            <div class="form-group">
                  <label>Discount Amount</label>
                  <ValidationProvider name="discount_amount" v-slot="{ failed }" >
                    <el-input name="total" type="number" step=".01" v-model="form.discount_amount" :class="failed ? 'input-error' : ''"></el-input>
                    <small id="help" class="text-danger" v-if="failed">The discount amount field is required</small>     
                  </ValidationProvider>
                </div>

          <div class="form-group">
            <label>Start Date</label>
            <ValidationProvider name="start_date" v-slot="{ failed }" rules="required" >
              <el-input name="start_date" type="date" v-model="form.start_date" :class="failed ? 'input-error':''"></el-input>
              <small id="help" class="text-danger" v-if="failed">The start date type field is required</small>     
            </ValidationProvider>
          </div>

          <div class="form-group">
              <label>End Date</label>
              <ValidationProvider name="end_date" v-slot="{ failed }" rules="required" >
                <el-input name="end_date" type="date" v-model="form.end_date" :class="failed ? 'input-error' : ''"></el-input>
                <small id="help" class="text-danger" v-if="failed">The end date type field is required</small>     
              </ValidationProvider>
            </div>


        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Save</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>

import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Promo } from '@/resources/promo'
import { mapState } from 'vuex';

extend("required", required);

export default {
   computed: {
    ...mapState(['promo_types','discount_types']),
  },
  props: ['promo'],
  data() {
    return {
      form: {
        code: null,
        description: null,
        promo_type: null,
        start_date: null,
        end_date: null,
        total: 0,
        discount_type: 0,
        discount_amount:0
      },
      loading: false
    };
  },
  created: function () {
    this.form = Object.assign({}, this.promo)
  },
  watch: {
    plan() {
      this.form = Object.assign({}, this.promo)
    }
  },
  methods: {
    submit() {
      this.loading = true

      let request = this.form.id ? Promo.update(this.form) : Promo.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Promo has been saved',
          });

          this.$emit('update', true)
        })
        .catch(error => {
     
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style></style>
