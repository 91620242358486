<template>
  <div class="row" v-loading.fullscreen.lock="page_loading">

    <el-drawer title="Attention" :visible.sync="showDelete" direction="rtl" :before-close="handleClose">
      <div style="padding: 10px;">
        <p>Remove this promo? <el-link type="danger" @click="deleteItem()" class="red-label">Yes, remove it!</el-link></p>

      </div>
    </el-drawer>

    <el-drawer :title="selected && selected.id ? 'Edit' : 'New'" :visible.sync="showForm" direction="rtl" :size="600"
      :before-close="handleClose">
      <div style="padding: 10px;">
        <promo-form :promo="selected" @update="getPromos()"></promo-form>
      </div>
    </el-drawer>


    <div class="col-md-12 card" v-loading="loading">
      <div class="card-header">
        <div class="category">Promos</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="col-md-6">
          <p-button type="primary" size="sm" style="float:right" @click="handleNew()">
            <i class="fa fa-plus"></i> NEW
          </p-button>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="promos" style="width: 100%">
            <el-table-column :min-width="120" class-name="td-actions" label="Code">
              <template slot-scope="props">
                {{ props.row.code }}
              </template>
            </el-table-column>
            <el-table-column :min-width="120" class-name="td-actions" label="Description">
              <template slot-scope="props">
                {{ props.row.description }}
              </template>
            </el-table-column>
            <el-table-column :min-width="120" class-name="td-actions" label="Type">
              <template slot-scope="props">
                {{ props.row.promo_type }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="Start Date">
              <template slot-scope="props">
                {{ props.row.start_date }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="End Date">
              <template slot-scope="props">
                {{ props.row.end_date }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="Total">
                <template slot-scope="props">
                  {{ props.row.total }}
                </template>
              </el-table-column>

              <el-table-column :min-width="120" class-name="td-actions" label="Used">
                <template slot-scope="props">
                  {{ props.row.used }}
                </template>
              </el-table-column>

              <el-table-column :min-width="120" class-name="td-actions" label="Available">
                <template slot-scope="props">
                  {{ props.row.available }}
                </template>
              </el-table-column>

              <el-table-column :min-width="120" class-name="td-actions" label="Disc Type">
                <template slot-scope="props">
                  {{ props.row.discount_type == 0 ? 'Fixed': 'Percentage'}}
                </template>
              </el-table-column>

              <el-table-column :min-width="120" class-name="td-actions" label="Disc Amount">
                <template slot-scope="props">
                  {{ props.row.discount_amount }} {{ props.row.discount_type == 1 ? '%' : '' }}
                </template>
              </el-table-column>

            <el-table-column :min-width="100" fixed="right" class-name="td-actions" label="Actions">
              <template slot-scope="props">
                <p-button type="primary" size="sm" icon @click="handleEdit(props.row)">
                  <i class="fa fa-pencil"></i>
                </p-button>
                <p-button type="danger" size="sm" icon @click="handleDelete(props.row)">
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        
      </div>
    </div>
  </div>
</template>
<script>

import { Promo } from '@/resources/promo'

import Vue from 'vue'
import { Table, TableColumn, Select, Option, Drawer } from 'element-ui'
import PromoForm from './PromoForm.vue'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(Drawer)

export default {
  components: { PromoForm },
  data() { 
     return {
      showDelete: false,
      showForm: false,
      promos: [],
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0
      },
      selected: null,
      loading: false,
      term: null,
      page_loading: false
    }
  },
  created: function () {
    this.getPromos()
  },
  methods: {
    handleClose() {
      this.page_loading = false
      this.showDelete = false
      this.showForm = false
    },
    handleDelete(object) {
      this.showDelete = true
      this.selected = Object.assign({}, object)
    },
    handleNew() {
      this.showForm = true
      this.selected = {}
    },
    handleEdit(object) {
      this.showForm = true
      this.selected = Object.assign({}, object)
    },
    deleteItem() {
      this.page_loading = true
      Promo.delete({ id: this.selected.id })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Removed successfully',
          });
          this.getPromos()
        })
        .catch(() => {
    
        }).finally(() => {
          this.loading = false
        })

    },
    getPromos() {
      this.handleClose()
      this.loading = true
      Promo.get({ params: { term: this.term, status: this.status, page: this.pagination.currentPage, per_page: this.pagination.perPage } })
        .then(result => {
          if (result.data.promos)
            this.promos = result.data.promos
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
