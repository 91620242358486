<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="info-text">Tell us about your company</h5>
      <div class="row justify-content-center">
        <div class="col-sm-3">
          <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
            <fg-input name="name" :error="failed ? 'The Name field is required' : null" :hasSuccess="passed"
              placeholder="Name (required)" v-model="form.name" addon-left-icon="nc-icon nc-single-02">
            </fg-input>
          </ValidationProvider>
        </div>

         <div class="col-sm-3">
            <ValidationProvider name="fein" v-slot="{ passed, failed }">
                <fg-input name="fein" :error="failed ? 'The FEIN field is required' : null" :hasSuccess="passed"
                  placeholder="FEIN" v-model="form.fein" addon-left-icon="nc-icon nc-circle-10">
                </fg-input>
              </ValidationProvider>
         </div>
      </div>

      <div class="row justify-content-center">
         <div class="col-sm-6">
              <ValidationProvider name="dba" v-slot="{ passed, failed }">
                <fg-input name="dba" :error="failed ? 'The DBA field is required' : null" :hasSuccess="passed"
                  placeholder="Doing business as" v-model="form.dba" addon-left-icon="nc-icon nc-circle-10">
                </fg-input>
              </ValidationProvider>
          </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { mapState } from 'vuex';

extend("required", required);
extend("email", email);

export default {
  data() {
    return {
      form: {
        name: '',
        dba: null,
        fein: null
      }
    };
  },
  created: function () {
    this.form.name = this.user.name
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then(res => {
        this.$emit('on-validated',res, this.form, 'company')
        return res
      })
    }
  },
  computed: {
    ...mapState(['user'])
  }
}
</script>
<style lang="scss">
.card-wizard .picture-container .picture {
  input[type="file"] {
    display: none;
  }
}
</style>
