<template>
  <div v-loading.fullscreen.lock="loading">
    <el-card class="box-card">
      <h5>Mails Received in A Year</h5>
      <bar-chart :labels="chartData.labels" :height="250" :datasets="chartData.datasets" v-if="dataReady">
      </bar-chart>
    </el-card>

  </div>
</template>
<script>

import { Analytic } from '@/resources/analytics'
import BarChart from 'src/components/UIComponents/Charts/BarChart'

export default {
  components: {
    BarChart
  },
  data() {
    return {
      dataReady: false,
      loading: false,
      months: [],
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Data",
            borderColor: '#fcc468',
            fill: true,
            backgroundColor: '#fcc468',
            hoverBorderColor: '#fcc468',
            borderWidth: 5,
            data: [100, 120, 80, 100, 90, 130, 110, 100, 80, 110, 130, 140, 130, 120, 130, 80, 100, 90, 120, 130],
          }
        ]
      }
    }
  },
  created: function () {
    let latestMonth = parseInt(this.$moment().format('M'))
    let earliestMonth = latestMonth == 12 ? 1 : latestMonth + 1

    let monthNames = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

    let year = parseInt(this.$moment().format('Y'))
    if (latestMonth < 12) year -= 1
    let months = []
    for (let i = 1; i <= 12; i++) {

      months.push({
        count: 0, month: earliestMonth, year: year, month_name: monthNames[earliestMonth]
      })
      earliestMonth++
      if (earliestMonth > 12) {
        earliestMonth = 1
        year += 1
      }
    }

    this.months = months;

    this.getReceivedMailsOnAYear()
  },
  methods: {
    getReceivedMailsOnAYear() {
      this.loading = true
      Analytic.allMailsReceivedInAYear()
        .then(result => {
          this.fillUpData(result.data)
        })
        .catch(() => {
        }).finally(() => {
          this.loading = false
        })
    },
    fillUpData(list) {
      let self = this
      this.months.forEach(function (month, i) {
        list.forEach(function (item) {
          if (item.month == month.month && item.year == month.year) {
            self.months[i].count = item.count
          }
        })
      })

      this.chartData.labels = []
      this.chartData.datasets[0].data = []
      this.months.forEach(function (monthData) {
        self.chartData.labels.push(monthData.month_name + ' ' + monthData.year)
        self.chartData.datasets[0].data.push(monthData.count)
      })

      this.dataReady = true
    }
  }
}
</script>
<style lang="scss"></style>
