<template>
  <div v-if="user" class="row">
      <div class="col-md-12">
        <stats-card small-title="Cash" :title="user.balance" type="success"
                      icon="nc-icon nc-money-coins"></stats-card>
                      <hr>
                      <el-button type="success" class="mb-3 btn-block"><a href="/cash-in" style="color:#fff !important">Cash In <i class="nc-icon nc-simple-add"></i></a></el-button>
      </div>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import StatsCard from '../../UIComponents/Cards/StatsCard.vue'

export default {
  components: { StatsCard },
  data() {
    return {
      loading: false,
    }
  },
  created: function () {
    // this.getUser(this.$route.params.id)
  },
  methods: {
    handleEdit(index, data) {
      console.log(data)
    },
  },
  computed: mapState(['user']),
}
</script>
<style lang="scss"></style>
