<template>
  <ValidationObserver v-slot="{ handleSubmit }" v-loading="loading">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="row">
        <div class="col-md-5">
          <div class="card">
          <div class="card-header">
            <h4 class="card-title" style="text-align: center;">
              Please complete your company details
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                  <div class="form-group">
                  <label>Company Name</label>
                  <ValidationProvider name="name" :rules="{
                    required: true,
                  }" v-slot="{ passed }">
                    <div :class="!passed ? 'has-danger' : ''">
                      <el-input v-model="form.name" type="text"></el-input>
                      <div class="invalid-feedback error-text" v-if="!passed">
                        Company name is required
                      </div>
                    </div>
                  </ValidationProvider>
                </div>

              </div>
            </div>
          
            <div class="form-group">
              <label>Business Type</label>
              <ValidationProvider name="type" rules="required" v-slot="{ passed }" >
                <div :class="!passed ? 'has-danger' : ''">
                    <select class="form-control" v-model="form.type">
                    <option :value="t" v-for="t in business_types" :key="t">{{ t }}</option>
                  </select>
                   <div class="invalid-feedback error-text" v-if="!passed">
                    Select business type
                  </div>
                </div>
              </ValidationProvider>
            </div>

            <div class="form-group">
              <label>Mobile Number</label>
              <ValidationProvider name="mobile_number" :rules="{
                required: true,
                regex: /^[0-9]{10}$/
              }" v-slot="{ passed }">
                <div :class="!passed ? 'has-danger' : ''">
                  <el-input v-model="form.mobile_number" type="number"></el-input>
                  <div><small class="gray-text">Your number will be used in sending verification <code></code></small></div>
                  <div class="invalid-feedback error-text" v-if="!passed">
                    Please provide a valid number (Ex 1234567890)
                  </div>
                </div>
              </ValidationProvider>
            </div>

            <div class="form-group">
                <label>Website</label>
                <ValidationProvider name="website" :rules="{
                  regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
                }" v-slot="{ passed }">
                  <div :class="!passed ? 'has-danger' : ''">
                    <el-input v-model="form.website" type="text"></el-input>
                    <div class="invalid-feedback error-text" v-if="!passed">
                      Website not valid
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            <hr>

          </div>
          <div class="card-footer text-right">
            <p-button type="info" native-type="submit">Save</p-button>
          </div>
        </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>

import { extend } from "vee-validate";
import { required, regex } from "vee-validate/dist/rules";
import { UserProfile } from '@/resources/user_profile'
import { mapState } from 'vuex';

import {
  BUSINESS_TYPES
} from '@/Constants'


extend("required", required);
extend("regex", regex);

export default {
  computed: {
    ...mapState(['user'])
  },
  data() {
    return {
      form: {
        type: "",
        mobile_number: null,
        website: null,
        name: null
      },
      business_types: BUSINESS_TYPES,
      loading: false
    };
  },
  created: function () {
    if ( this.user.company && this.user.company.company_profile) {
      this.form = Object.assign({}, this.user.company.company_profile)
    }
  },  
  methods: {
    isValidUrl(str) {
       const pattern = new RegExp(
        '^([a-zA-Z]+:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', // fragment locator
        'i'
      );
      return pattern.test(str);
    },
    reset() {
      this.form = {
        type: "",
        mobile_number: null,
        website: null,
      }
    },
    submit() {
      this.loading = true

      UserProfile.saveCompanyProfile(this.form)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'You data has been saved',
          });
          this.user.company.company_profile = result.data.company_profile
           this.$store.commit('saveUser', this.user);
          this.$router.push({ name: 'Home' })
        })
        .catch(error => {
          
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style></style>
