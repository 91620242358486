import api from './api'

const API = '/api/mails'

export const Mail = {
  get: function(params) {
    return api.get(API, params)
  },
  all: function(params) {
    return api.get(API+'/all', params)
  },
  mailActionOptions: function(params) {
    return api.get(API+'/mail-action-options', params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, {params: params})
  },
  checkMailActionsCount: function(id, params) {
    return api.get(API + '/' + id + '/check-mail-actions-count', {params: params})
  },
  create: function (formData) {
    console.log(formData)
    return api.createFormData(API, formData)
  },
  updateCompany: function(params) {
    return api.update(API+'/'+params.id+'/update-company', params)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
