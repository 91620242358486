<template>
  <div v-loading.fullscreen.lock="loading">
    <el-card class="box-card">
      <h5>Top Clients</h5>
      <el-table class="table-striped" header-row-class-name="text-primary" :data="top_users" style="width: 100%">
          <el-table-column :min-width="120" class-name="td-actions" label="Name">
            <template slot-scope="props">
              {{ props.row.name }}
            </template>
          </el-table-column>
          <el-table-column :min-width="120" class-name="td-actions" label="Payment">
              <template slot-scope="props">
                $ {{ props.row.total_amount.toFixed(2) }}
              </template>
            </el-table-column>    
      </el-table>
    </el-card>
  </div>
</template>
<script>

import { Analytic } from '@/resources/analytics'

export default {

  data() {
    return {
      loading: false,
      top_users: []
    }
  },
  created: function () {
    this.getTopUsers()
  },
  methods: {
    getTopUsers() {
      this.loading = true
      Analytic.topUsers()
        .then(result => {
          this.top_users = result.data.users
        })
        .catch(() => {
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
