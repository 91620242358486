<template>
  <div v-if="user" class="" v-loading.fullscreen.lock="loading">
    <el-drawer 
    :close-on-press-escape="false"
    title="Select Timeslot" :visible.sync="showTimeslot" direction="rtl" :size="500"
        :before-close="handleClose">
        <div style="padding: 10px;">
          <select-room-time-slot :room="selected" v-if="selected" @create="setBooking($event)" :booked_hours="booked_hours"></select-room-time-slot>
        </div>
      </el-drawer>

    <div class="block">
      <!-- <div class="demonstration">Search Available Rooms</div> -->
      <datepicker v-model="date" input-class="form-control" bootstrap-styling	placeholder="Search Date"></datepicker>
    </div>
    <div>
      <el-card class="list-card mt-3" v-for="room,i in rooms" :key="i">
        
        <meeting-room-picture-preview :meeting_room="room.meeting_room"></meeting-room-picture-preview>
        <hr>
        <h5>{{ room.meeting_room.name }} - {{ room.meeting_room.office.name }} <small class="text-orange text-white-hover room-price">(${{ room.meeting_room.price }} /hr)</small></h5>
        <div>Capacity: {{ room.meeting_room.capacity }} people</div>
        <div>Address: {{ room.meeting_room.office.address.full_address }}</div>
        <div>Timeslots:</div>
        <div>
           <el-tag
            v-for="timeslot,i in getTimeSlotsText(room.hours)"
            :key="i"
            type="success"
            effect="dark">
            {{ timeslot }}
          </el-tag>
        </div>
        <hr>
        <el-button type="info" @click="selectRoom(room)">Select time to book</el-button>
      </el-card>
    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import SelectRoomTimeSlot from './SelectRoomTimeSlot.vue';
import { MeetingRoom } from '@/resources/meeting_room'
import Datepicker from 'vuejs-datepicker';
import MeetingRoomPicturePreview from './MeetingRoomPicturePreview.vue';

export default {
  props: ['type'],
  components: { SelectRoomTimeSlot, Datepicker, MeetingRoomPicturePreview },
  data() {
    return {
      booking: null,
      loading: false,
      date: new Date(),
      showTimeslot: null,
      selected: null,
      booked_hours: [],
      rooms: []
    }
  },
  created: function () {
    // this.getUser(this.$route.params.id)
    this.getRooms()
  },
  watch: {
    date: function () {
      this.getRooms()
    }
  },
  methods: {
    getRooms() {
      this.loading = true
      
      let d = this.$moment(this.date);
      if (!d.isValid()) {
        this.$notify({
          type: 'error',
          title: "Error",
          text: 'Please select a date',
        });
        return false
      }
      MeetingRoom.availableRooms({ params: { page: 1, per_page: 999, date: d.format('YYYY/MM/DD'), type: this.type } })
        .then(result => {
          this.rooms = result.data.rooms
        })
        .catch((error) => {
          console.log(error)
        }).finally(() => {
          this.loading = false
        })
    },
    getTimeSlotsText(hours) {
      return this.getTimeSlotsFromHours(hours)
    },
    handleClose() {
      this.showTimeslot = false
    },
    selectRoom(room) {
      this.loading = true
      this.booked_hours = []
      MeetingRoom.bookedHours(room.meeting_room.id, { date: this.$moment(this.date).format('YYYY-MM-DD'), type: this.type })
        .then(result => {
          this.booked_hours = result.data.hours
          this.showTimeslot = true
          this.selected = Object.assign({}, room)
          this.selected.date = this.date
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })

    },
    setBooking(booking) {
      this.booking = Object.assign({}, booking)
      this.handleClose()
      this.$emit('select', this.booking)
    },
    setSelectedHours(hours) {
      
      this.selected.selected_hours = hours
      this.handleClose()
      this.$emit('select', this.selected)
    }
  },
  computed: mapState(['user']),
}
</script>
<style lang="scss"></style>
