<template>
  <div v-loading.fullscreen.lock="loading">
      <div v-if="action">
        <div style="padding: 10px">
            <span style="font-size: 18px;padding-top: 5px" class="mt-3">Request to <strong>{{ action.label }}</strong> the mail.</span>
            <!-- <span v-if="actions_count >= plan_count && action.value != 'open'">You will be charge ${{ action.amount }}</span> -->
          </div>
         <el-alert
         v-if="plan_count < 500"
              :type="(plan_count - actions_count) <= 0 ? 'error':'success'">
              <template slot="title">
                  <div >
                      {{ (plan_count - actions_count) < 0 ? 0 : (plan_count - actions_count) }} out of {{ plan_count }} remaining free ({{ action.label }}) request for this month.
                  </div>
                  <div v-if="(plan_count - actions_count) <= 0" class="mt-3">
                  
                    <span v-if="action.value == 'forward'">
                      The delivery fee for mail forwarding is subject to change and may vary over time.
                       Your charges will be adjusted accordingly to reflect any changes in this fee.
                      </span>
                    <span v-if="action.value == 'shred'">
                      (Note: The cost for shredding services will be determined based on the total number of pages. 
                      Your charges will reflect this quantity accordingly.)
                    </span>
                    <span v-if="action.value == 'open'">
                      (Note: The cost for opening/scanning services will be determined based on the total number of pages. 
                        Your charges will reflect this quantity accordingly.)
                    </span>
                  </div>
              </template>
            </el-alert>
    
            
        <forward-mail-form v-if="action.value == 'forward'" ref="forward_mail"
          @on-validated="onStepValidated" :user_location="user_location"></forward-mail-form>
      </div>
      <hr>
      <span slot="footer" class="dialog-footer">
        <mail-action-authorization :action="action.label" @accept="authorized = $event"></mail-action-authorization>

        <div  v-if="action.value != 'open'">
           <!-- <all-policy @accept="accept = $event" :hide_room="true"></all-policy>
          <br>
          <el-alert type="error" class="mb-3">Please read and accept the terms and conditions Card payment agreements</el-alert> -->

          <!-- <payment-button
          :disabled="!accept"
            v-if="action"
                  :amount="action.amount" 
                  :label="'$' + action.amount" 
                  @success="takeAction()" :type="'mail'" :description="action.label"></payment-button> -->

          <!-- <el-button @click="close()">Cancel</el-button> -->
        </div>
        <div>
          <!-- <authorized-to-open-mail :title="'Authorization'" @accept="authorized_to_open = $event"></authorized-to-open-mail> -->
          <hr>
          <el-button type="success" :disabled="!authorized" @click="takeAction()">Send request</el-button>
          <el-button @click="close()">Cancel</el-button>
        </div>
      </span>
  </div>
</template>
<script>

import { Mail } from '@/resources/mail';
import { UserMail } from '@/resources/user_mail';
import ForwardMailForm from './ForwardMailForm.vue';
import PaymentButton from '../../UIComponents/PaymentButton.vue';
import AllPolicy from '../Policies/AllPolicy.vue';
import AuthorizedToOpenMail from './AuthorizedToOpenMail.vue';
import MailActionAuthorization from '../Authorization/MailActionAuthorization.vue';

export default {
  components: { ForwardMailForm, PaymentButton, AllPolicy, AuthorizedToOpenMail, MailActionAuthorization },
  props: ['mail', 'action'],
  data() {
    return {
      authorized: false,
      authorized_to_open: false,
      loading: true,
      showConfirm: false,
      actions: [],
      form: {},
      open_action: null,
      forwardMail: null,
      plan_count: 0,
      actions_count: 0,
      accept: false,
      user_location: null
    };
  },
  watch: {
    action: function () {
      this.checkMailAction()
    }
  },
  created: function () {
   this.checkMailAction()
  },
  methods: {
    requestToOpen() {
      this.takeAction()
    },
    isInvalid() {
      if (!this.action) return true
      if (this.action.value == 'forward') {
        return this.forwardMail ? false : true
      }
      return false
    },
    onStepValidated(validated, model, type) {
      if (type == 'forward_mail') {
        this.forwardMail = validated ? Object.assign({}, model) : null
      }
    },
    getOpenAction() {
      let self = this
      this.mail.mail_actions.forEach(function (mail_action) {
        if (mail_action.action == 'open') self.open_action = mail_action
      })
    },
    takeAction() {
      this.loading = true
      this.form.action = this.action.value
      if (this.forwardMail && this.action.value == 'forward') {
        this.form.forward_to = this.forwardMail.forward_to
        this.form.foward_message = this.forwardMail.forward_message
        this.form.note = this.forwardMail.note
        this.form.address_id = this.forwardMail.address_id
      }
      UserMail.takeAction(this.mail.id, this.form)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: "Updated successfully",
          });

          this.close()
        })
        .catch(error => {
         
        }).finally(() => {
          this.loading = false
        })
    },
    close() {
      this.$emit('update', true)
    },
     checkMailAction() {
      this.loading = true
      Mail.checkMailActionsCount(this.mail.id, { action: this.action.value })
        .then(result => {
          this.plan_count = result.data.plan_count
          this.actions_count = result.data.actions_count
          this.user_location = result.data.user_location
        })
        .catch(error => {
          
        }).finally(() => {
          this.loading = false
        })
    },
  },
};
</script>
<style lang="scss">
.action {
  background: #fff;
}

.unread-row td {
  background: #F5F7FA;
}

.bold {
  font-weight: bold;
}
</style>

