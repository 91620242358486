<template>
  <ValidationObserver v-slot="{ handleSubmit }" v-loading="loading">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ form.id ? 'Update user':'Create new user'}}
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>Name</label>
            <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The name field is required' : null" :hasSuccess="passed"
                name="name" v-model="form.name">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="form-group">
              <label>Email</label>
              <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
                <fg-input type="text" :error="failed ? 'The email field format is invalid' : null" :hasSuccess="passed"
                  name="email" v-model="form.email">
                </fg-input>
              </ValidationProvider>
            </div>

             <div class="form-group">
                  <label>Password</label>
                  <ValidationProvider vid="confirmation"
                    name="password"
                      :rules="{
                        required: true,
                        regex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
                      }"
                  v-slot="{ passed, failed }">
                    <fg-input type="password" :error="failed ? 'The Password must be at least 8 characters and has a number' : null" :hasSuccess="passed"
                      name="password" v-model="form.password">
                    </fg-input>
                  </ValidationProvider>
                </div>


            <div class="form-group">
               <label>Roles</label>
   <el-tag
                :key="role"
                v-for="role in roles"
                closable
                :disable-transitions="false"
                @close="handleClose(role)">
                {{ role }}
              </el-tag>
              <el-input
                class="input-new-tag"
                v-model="inputValue"
                ref="saveTagInput"
                size="mini"
                @keyup.enter.native="handleInputConfirm"
                @blur="handleInputConfirm"
              >
              </el-input>
            </div>
           
          <!-- <div class="form-group">
            <label>Company</label>
            <ValidationProvider name="company_id" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The recipient field is required' : null" :hasSuccess="passed"
                name="company_id" v-model="form.company_id" :readonly="true" style="display:none">
              </fg-input>
              <remote-search :company="form.company" @update="setCompany($event)" :passed="passed"
                :error="failed ? 'The company field is required' : null"></remote-search>
            </ValidationProvider>
          </div> -->
          
        
        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Save</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required, email, regex } from "vee-validate/dist/rules";
import { User } from '@/resources/user'
import RemoteSearch from '@/components/Dashboard/Views/Components/RemoteSearch.vue';

extend("email", email);
extend("required", required);
extend("regex", regex);

export default {
  props: ['user'],
  components: { RemoteSearch },
  data() {
    return {
      roles: [],
      form: {
        password: "",
        email: "",
        name: "",
        company_id: null
      },
      loading: false,
       inputValue: ''
    };
  },
  created: function () {
    this.form = Object.assign({}, this.user)
    if (this.user) this.roles = this.user.role_names
  },
  methods: {
     handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.roles.push(inputValue);
      }
      this.inputValue = '';
    },
    handleClose(tag) {
      this.roles.splice(this.roles.indexOf(tag), 1);
    },
    setCompany(company) {
      this.form.company_id = company ? company.id : null
    },
    submit() {
      this.loading = true
      this.form.roles = this.roles
      let request = this.form.id ? User.update(this.form) : User.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'User has been saved',
          });
        })
        .catch(error => {
          let message = error.response.data.message || 'Unable to save this time'
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message,
          });
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style></style>
