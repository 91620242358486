import api from './api'

const API = '/api/profile'

export const UserProfile = {
  get: function(params) {
    return api.get(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  saveCompanyProfile: function(params) {
    return api.create(API+'/save-company-profile', params)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
