<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="info-text">Payment Method</h5>
      <div class="row" :class="fullScreen ? '' : 'justify-content-center'">
         <div class="col-sm-5">
           
            <ValidationProvider name="number" rules="required" v-slot="{ passed, failed }">
              <fg-input 
              mask="#### #### #### ####"
               name="number" :error="failed || !isValidCard() ? 'Card number is invalid' : null" :hasSuccess="passed || isValidCard()"
                placeholder="Card Number (required)" v-model="form.number" addon-left-icon="nc-icon nc-credit-card">
              </fg-input>
            </ValidationProvider>
          </div>
            <div class="col-sm-1 credit-card" style="    margin-bottom: 10px;">
                <i class="fa fa-cc-visa fa-2" aria-hidden="true" v-if="cardType() == 'VISA'"></i>
                <i class="fa fa-cc-mastercard fa-2" aria-hidden="true" v-if="cardType() == 'MASTERCARD'"></i>
                <i class="fa fa-cc-amex fa-2" aria-hidden="true" v-if="cardType() == 'AMEX'"></i>
                <i class="fa fa-cc-diners-club fa-2" aria-hidden="true" v-if="cardType() == 'DINERS'"></i>
                <i class="fa fa-cc-jcb fa-2" aria-hidden="true" v-if="cardType() == 'JCB'"></i>
                <i class="fa fa-cc-discover fa-2" aria-hidden="true" v-if="cardType() == 'DISCOVER'"></i>
              </div>
      </div>
      <div class="row" :class="fullScreen ? '' : 'justify-content-center'">
          <div class="col-sm-3">
              <ValidationProvider name="expiration" rules="required" v-slot="{ passed, failed }">
                <fg-input mask="##/##" name="month" :error="failed  || !isValidExpiration() ? 'Expiration date is invalid' : null" :hasSuccess="passed || isValidExpiration()"
                  placeholder="Expiration mm/yy (required)" v-model="form.expiration" addon-left-icon="nc-icon nc-time-alarm">
                </fg-input>
              </ValidationProvider>
            </div>
  <div class="col-sm-3">
                  <ValidationProvider name="cvc" rules="required" v-slot="{ passed, failed }">
                    <fg-input mask="###" name="cvc" :error="failed ? 'CVC is required' : null" :hasSuccess="passed"
                      placeholder="CVC (required)" v-model="form.cvc" addon-left-icon="nc-icon nc-lock-circle-open">
                    </fg-input>
                  </ValidationProvider>
                </div>
      </div>
      <div class="row" :class="fullScreen ? '' : 'justify-content-center'">
              <div class="col-sm-6">
            <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
              <fg-input name="name" :error="failed ? 'Name is required' : null" :hasSuccess="passed"
                placeholder="Name (required)" v-model="form.name" addon-left-icon="nc-icon nc-badge">
              </fg-input>
            </ValidationProvider>
          </div>
      </div>

      
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required, email,regex } from "vee-validate/dist/rules";
import { CreditCardTypes } from '@/services/creditCardTypes'

extend("required", required);
extend("email", email);
extend("regex", regex)

export default {
  props: {
    fullScreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        name: null,
        number: null,
        cvc: null,
        expiration: null
      },
    };
  },
  watch: {
    form: {
      handler: function () {
        this.validate()     
      },
      deep: true
    },
    'form.expiration': function () {
      // if (this.form.expiration) {
      //   if (this.form.expiration.length == 2) this.form.expiration += '/'
      //   if (this.form.expiration.length >= 5) this.form.expiration = this.form.expiration.slice(0, 5);
      //   if (this.form.expiration.length == 5) {
      //     let string = this.form.expiration.split('/')
      //     this.form.exp_month = string[0]
      //     this.form.exp_year = string[1]
      //   }
      // }
    },
    'form.cvc': function () {
      // if (this.form.cvc) {
      //   if (this.form.cvc.length >= 3) this.form.cvc = this.form.cvc.slice(0, 3);
      // }
    },
    'form.number': function () {
      // if (this.form.number) {
      //   if (this.form.number.length >= 16) this.form.number = this.form.number.slice(0, 16);
      // }
    }
  },
  methods: {
    formattedCardNumber() {
      // let string = this.form.number ? this.form.number.split('') : []
      // let format = ""

      // let ctr = 0
      // string.forEach(num => {
      //   ctr++
      //   format += num
      //   if (ctr == 4) {
      //     format += " "
      //     ctr = 0
      //   }
      // });

      return ""
    },
    cardType() {
      return CreditCardTypes.getType(this.form.number)
    },
    isValidCard() {
      return this.cardType() ? true : false
    },
    isValidExpiration() {
      
      if (!this.form.expiration) return false

      if (this.form.expiration.length == 5) {
        let string = this.form.expiration.split('/')

        this.form.exp_month = parseInt(string[0])
        this.form.exp_year = parseInt(string[1])

        let currentMonth = parseInt(this.$moment().format('MM'))
        if (this.form.exp_month < currentMonth || this.form.exp_month > 12) return false
        if (this.form.exp_year < 23) return false
        
        return true
      }

      return false
    },
    validate() {
      return this.$refs.form.validate().then(res => {
        this.$emit('on-validated', res, this.form, 'payment_method')
        return res
      })
    }
  }
}
</script>
<style lang="scss">
.card-wizard .picture-container .picture {
  input[type="file"] {
    display: none;
  }
}

.credit-card .fa {
  font-size: 39px;
}
</style>
