<template>
  <div v-if="user" v-loading.fullscreen.lock="fullscreen_loading">
    <h4>You have to pay the Plan first before using the service</h4>
    <hr>
    <div class="row">
      <div class="col-md-3">
        <payment-button :amount="form.amount" :label="form.amount" :description="'Pay Plan'" @success="success()"></payment-button>
      </div>
    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import { User } from '@/resources/user'
import Swal from 'sweetalert2'
import PaymentButton from '../../UIComponents/PaymentButton.vue';

export default {
  components: { PaymentButton },
  data() {
    return {
      loading: false,
      form: {
        amount: 10
      },
      fullscreen_loading: false,
      pay_plan: false
    }
  },
  created: function () {
    // This component will be shown one time. When the new client login for the first time
    this.pay_plan = true
    this.form.amount = this.user.company.plan_amount

    if (this.user && this.user.company && this.user.company.has_paid_the_plan) {
      this.$router.push({ name: 'Home' })
    }
  },
  methods: {
    async success() {
      let text = "Thank you for paying the plan"
      const { value: res } = await Swal.fire({
        title: "Success",
        text: text,
        icon: "success",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      })

      this.$router.push({ name: 'Home', query: { refresh: true } })
    },
  },
  computed: mapState(['user']),
}
</script>
<style lang="scss"></style>
