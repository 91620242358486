<template>
  <div>
    <div v-if="!hide_room">
      <h5>{{ roomPolicyTitle }}</h5>
      <div style="height: 400px;overflow: scroll;" class="mb-3" >
      
        <room-policy @accept="updateTerms('room', $event)"></room-policy>
      </div>
    </div>

    <h5>{{ cardPolicyTitle }}</h5>
    <div style="height: 400px;overflow: scroll;">

      <credit-card-terms @accept="updateTerms('card', $event)"></credit-card-terms>
    </div>
  </div>
</template>
<script>
import CreditCardTerms from '../MeetingRoom/CreditCardTerms.vue'
import RoomPolicy from '../MeetingRoom/RoomPolicy.vue'

export default {
  components: { RoomPolicy, CreditCardTerms },
  props: ['hide_room'],
  data() {
    return {
      roomPolicyTitle: 'House Rules and Terms and Conditions for Meeting Room and Desk Rental Reservations',
      cardPolicyTitle: 'Terms and Conditions and Agreement for Credit Card Payment',
      acceptRoomPolicy: false,
      acceptCardTerm: false,
    }
  },
  methods: {
    updateTerms(type, accept) {
      if (type == 'room') this.acceptRoomPolicy = accept
      if (type == 'card') this.acceptCardTerm = accept

      if (this.hide_room) this.acceptRoomPolicy = true
      this.$emit('accept', this.acceptRoomPolicy && this.acceptCardTerm)
    }
  }
}

</script>
<style></style>
