<template>
  <div v-if="user" class="row">
    <div class="col-md-12">
      <h5>{{ type == 1 ? 'Co-Working Space':'Meeting Room' }}</h5>
    </div>
    <div class="col-md-3">
      <meeting-room-summary :selected="selected" class="mb-3"></meeting-room-summary>
    </div>
    <div class="col-md-6">
      <search-meeting-rooms @select="setBooking($event)" :type="type"></search-meeting-rooms>
    </div>

  </div>
</template>
<script>

import { mapState } from 'vuex';
import MeetingRoomSummary from './Client/MeetingRoomSummary.vue';
import SearchMeetingRooms from './Client/SearchMeetingRooms.vue';

export default {
  components: { SearchMeetingRooms, MeetingRoomSummary },
  props: ['type'],
  data() {
    return {
      loading: false,
      selected: null
    }
  },
  created: function () {
    // this.getUser(this.$route.params.id)
  },
  methods: {
    setBooking(booking) {
      this.selected = booking
    }
  },
  computed: mapState(['user']),
}
</script>
<style lang="scss"></style>
