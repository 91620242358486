<template>
  <div>
    <h5 class="info-text">Select your office</h5>
    <div class="row pricing">
      <div class="col-md-4" v-for="office in offices" :key="office.id" style="margin-bottom: 10px">
        <el-card class="box-card" :class="{ 'selected': selected && selected.id == office.id }" >
          <h2>{{ office.name }}</h2>
          <hr>
          <div class="address">
              {{ office.address.full_address }}
          </div>
          <div style="text-align:center" class="mt-5"> 
              <el-button type="success" @click="select(office)" class="btn-block" :disabled="selected && selected.id == office.id">Select</el-button>
            </div>
      
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>

import { Office } from '@/resources/office'

export default {
  data() {
    return {
      selected: null,
      offices: []
    };
  },
  created: function () {
    this.getOffices()
  },
  methods: {
    select(office) {
      this.selected = Object.assign({}, office)
    },
    validate() {
      if (this.selected) {
        this.$emit('on-validated', true, this.selected, 'office')
         return true
      } else {
         this.$notify({
          type: 'danger',
          title: "Error",
          text: 'You have to select an office',
        });
        return false
      } 
    },
    getOffices() {
      Office.get({ params: { status: 1, page: 1, per_page: 999 } })
        .then(result => {
          if (result.data.offices)
            this.offices = result.data.offices
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch offices',
          });

        }).finally(() => {
        
        })
    }
  }
}
</script>
<style lang="scss">
  .selected {
    background: #ffcdbe;
    border: 1px solid #f0b4a2;
  }
</style>
