<template>
  <div v-loading.fullscreen.lock="page_loading">
    <div v-if="meeting_room">
      <el-button type="primary" @click="clear()">Clear</el-button>
      <el-button type="primary" @click="autoSet()">Auto Set Mon to Fri | 8 am to 5 pm</el-button>
      <table class="table table-responsive">
        <tr>
          <td v-for="schedule in schedules" :key="schedule.value">
            <el-button class="btn-block" type="info">{{  schedule.label }}</el-button>
            <div v-for="hour in hours" :key="hour.value">
              <el-button class="btn-block" @click="select(schedule.value, hour.value)" :type="isSelected(schedule.value, hour.value)">{{ hour.label }}</el-button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>

import { MeetingRoom } from '@/resources/meeting_room'

export default {
  props: ['meeting_room'],
  data() {
    return {
      page_loading: false,
      day_schedules: [],
      schedules: [
        { value: 'monday', label: 'Monday' },
        { value: 'tuesday', label: 'Tuesday' },
        { value: 'wednesday', label: 'Wednesday' },
        { value: 'thursday', label: 'Thursday' },
        { value: 'friday', label: 'Friday' },
        { value: 'saturday', label: 'Saturday' },
        { value: 'sunday', label: 'Sunday' }
      ],
      hours: [
        { value: 0, label: '12:00 am - 01:00 am' },
        { value: 1, label: '01:00 am - 02:00 am' },
        { value: 2, label: '02:00 am - 03:00 am' },
        { value: 3, label: '03:00 am - 04:00 am' },
        { value: 4, label: '04:00 am - 05:00 am' },
        { value: 5, label: '05:00 am - 06:00 am' },
        { value: 6, label: '06:00 am - 07:00 am' },
        { value: 7, label: '07:00 am - 08:00 am' },
        { value: 8, label: '08:00 am - 09:00 am' },
        { value: 9, label: '09:00 am - 10:00 am' },
        { value: 10, label: '10:00 am - 11:00 am' },
        { value: 11, label: '11:00 am - 12:00 pm' },
        { value: 12, label: '12:00 pm - 01:00 pm' },
        { value: 13, label: '01:00 pm - 02:00 pm' },
        { value: 14, label: '02:00 pm - 03:00 pm' },
        { value: 15, label: '03:00 pm - 04:00 pm' },
        { value: 16, label: '04:00 pm - 05:00 pm' },
        { value: 17, label: '05:00 pm - 06:00 pm' },
        { value: 18, label: '06:00 pm - 07:00 pm' },
        { value: 19, label: '07:00 pm - 08:00 pm' },
        { value: 20, label: '08:00 pm - 09:00 pm' },
        { value: 21, label: '09:00 pm - 10:00 pm' },
        { value: 22, label: '10:00 pm - 11:00 pm' },
        { value: 23, label: '11:00 pm - 12:00 am' },
      ]
    }
  },
  created: function () {
    this.getDaySchedules()
  },
  methods: {
    autoSet() {
      this.page_loading = true
      MeetingRoom.setDaySchedule(this.meeting_room.id, { auto_set: true })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'The schedule has been updated',
          });
          this.getDaySchedules()
        })
        .catch(() => {
        }).finally(() => {
          this.page_loading = false
        })
    },
    clear() {
      this.page_loading = true
      MeetingRoom.setDaySchedule(this.meeting_room.id, { clear: true })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'The schedule has been updated',
          });
          this.getDaySchedules()
        })
        .catch(() => {
        }).finally(() => {
          this.page_loading = false
        })
    },
     isSelected(day, hour) {
      let data = null
      this.day_schedules.forEach(function (day_schedule) {
        if (day_schedule.available_day == day && day_schedule.available_hour == hour) data = day_schedule
      })
      return data ? 'success' : ''
    },
    getDaySchedules() {
      this.page_loading = true
      MeetingRoom.daySchedules(this.meeting_room.id)
        .then(result => {
          if (result.data.day_schedules)
            this.day_schedules = result.data.day_schedules
        })
        .catch(() => {
        }).finally(() => {
          this.page_loading = false
        })
    },
    select(day, hour) {
      this.page_loading = true
      MeetingRoom.setDaySchedule(this.meeting_room.id, {day: day, hour: hour})
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'The schedule has been updated',
          });
          this.getDaySchedules()
        })
        .catch(() => {
        }).finally(() => {
          this.page_loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
