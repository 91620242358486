<template>
  <div>
    <loading :loading="loading"></loading>
    <div class="row" v-if="user">
      <div class="col-md-3">
        <UserProfileCard :user="user" :own="true"></UserProfileCard>
      </div>
      <div class="col-md-9">
        <div class="card">
          <div class="card-body">
            <tabs value="Description" tab-content-classes="text-center">
             
              <tab-pane title="Credential" key="home">
               <div class="row">
                  <div class="col-md-4 offset-4">
                    <user-credential-form :user="user"></user-credential-form>
                  </div>
               </div>
              </tab-pane>
            </tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import UserProfileCard from '@/components/UIComponents/Cards/UserProfileCard'
import { TabPane, Tabs } from '@/components/UIComponents'
import UserCredentialForm from '../Settings/Users/UserCredentialForm.vue'
import { mapState } from 'vuex';

export default {
  components: { UserProfileCard, TabPane, Tabs, UserCredentialForm },
  data() {
    return {
      loading: false,
    }
  },
  created: function () {
    // this.getUser(this.$route.params.id)
  },
  methods: {
    handleEdit(index, data) {
      console.log(data)
    },
  },
  computed: mapState(['user']),
}
</script>
<style lang="scss"></style>
