<template>
  <div>
    <h4 v-if="title">{{ title }}</h4>

    <div>
      <strong> Reservation and Cancellation Policy:</strong>
    </div>
    <div>
        1.1. All meeting room and desk rental reservations must be made in advance through our designated reservation system or by contacting our office.
    </div>
    <div>
        1.2. Cancellations must be made at least 3 days prior to the reserved date. Failure to cancel within this timeframe may result in a cancellation fee.
    </div>
    <div>
        1.3. We reserve the right to modify or cancel reservations in the event of unforeseen circumstances or maintenance requirements. In such cases, we will make every effort to provide alternative arrangements or a refund.
    </div>
    <br>
    <div><strong>Code of Conduct:</strong></div>
    <div>  2.1. Clients and their guests are expected to conduct themselves in a professional manner at all times within the premises.</div>
    <div>  2.2. Noise levels should be kept to a minimum to ensure a conducive working environment for all occupants.</div>
    <div>  2.3. Clients and their guests should adhere to all local laws and regulations, including those pertaining to business operations, noise control, and health and safety.</div>

     <br>
      <div><strong> Security and Safety:</strong></div>
    <div>  3.1. Clients are responsible for the security and safety of their personal belongings and equipment. We recommend that clients do not leave valuable items unattended.</div>
    <div>  3.2. Any damage or loss of property belonging to the Biz-Depot, including meeting room furniture, equipment, or fixtures, caused by the client or their guests, will be charged to the client accordingly.</div>
    <div>  3.3. For the safety and security of all occupants, the premises are monitored by surveillance cameras. Clients and their guests are expected to respect the privacy and security of others.</div>
    
    <br>
    <div><strong>  Cleanliness and Hygiene:</strong></div>
    <div>  4.1. Clients and their guests are expected to maintain a clean and tidy workspace during their reservation period.</div>
    <div>  4.2. A "clean as you go" policy should be observed, where clients are responsible for removing any personal belongings, trash, or other materials from the rented space after use.</div>

    <br>
    <div><strong>  Prohibited Activities and Items:</strong></div>
    <div>  5.1. The following activities and items are strictly prohibited within the premises:</div>

    <div>  Consumption or possession of illegal substances or drugs.</div>

  <div>Accessing or promoting explicit or pornographic content.</div>
  <div>Consumption of alcohol, unless prior approval has been obtained for specific events.</div>
  <div>Operation of any equipment or machinery without prior consent from the Biz-Depot.</div>
  <div>Cooking or preparation of food that creates strong odors or may be a fire hazard.</div>
  <div>Bringing animals or pets onto the premises, with the exception of service animals as required by law.</div>
  <div>Smoking or vaping in any designated non-smoking areas.</div>
  <br>
  <div><strong>Indemnification:</strong></div>
  <div>6.1. Clients and their guests agree to indemnify and hold the Biz-Depot harmless against any claims, damages, losses, or expenses arising from their use of the meeting room or desk rental, including any violations of the house rules or terms and conditions outlined herein.
    </div>
    <br>
    <div><strong>Right to Terminate:</strong></div>
  
  <div>  7.1. The Biz-Depot reserves the right to terminate any reservation or rental agreement if a client or their guests fail to comply with the house rules or terms and conditions, or engage in disruptive, inappropriate, or unlawful behavior.
  </div>
  <div>  Please note that these house rules and terms and conditions are subject to change without prior notice. It is the client's responsibility to review and adhere to the most up-to-date version provided by the Biz-Depot.
  </div>
  <div>  By reserving a meeting room or desk rental, the client acknowledges and agrees to abide by these house rules and terms and conditions
  </div>

  <br><br>

  <el-checkbox v-model="accept" border>I accept the terms and conditions</el-checkbox>
  </div>
</template>
<script>

export default {
  props: ['title'],
  data() {
    return {
      accept: null
    }
  },
  watch: {
    accept: function () {
      this.$emit('accept', this.accept)
    }
  },
  created: function () {
 
  }
}

</script>
<style></style>
