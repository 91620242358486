import api from './api'

const API = '/api/subscription'

export const Subscription = {
  payments: function(params) {
    return api.get(API + '/payments', params)
  },
  analytics: function(params) {
    return api.get(API + '/analytics', params)
  },
  renew: function(params) {
    return api.create(API + '/renew', params)
  },
  upgrade: function(params) {
    return api.create(API + '/upgrade', params)
  },
  cancel: function(params) {
    return api.create(API + '/cancel', params)
  },
  reactivate: function(params) {
    return api.create(API + '/reactivate', params)
  }
}
