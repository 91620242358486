<template>
  <div v-loading.fullscreen.lock="loading">
      <div v-if="subscription">
        <table class="mb-3 table">
          <tr>
              <td class="font-15" style="width: 250px">{{ promo ? 'Original':''}} Amount</td><td class="font-15" style="text-align:right">${{ formatNumber(subscription.original_amount) }}</td>
            </tr>
         
          <tr>
              <td class="font-15">Setup Fee</td><td style="text-align:right" class="font-15">${{ formatNumber(subscription.setup_fee || 0) }}</td>
            </tr>
             <tr v-if="promo">
              <td class="font-15" style="width: 150px" >Discounted Amount</td><td class="font-15" style="text-align:right">${{ formatNumber(subscription.amount) }}</td>
            </tr>
            <tr>
              <td class="font-15" style="font-weight:bold;">Total</td><td class="font-15" style="font-weight:bold;text-align: right;">${{ formatNumber(getTotal()) }}</td>
            </tr>

            <tr v-if="promo">
                <td class="font-15">Promo Code</td><td style="text-align:right" class="font-15 orange-text">{{ promo.code}}</td>
              </tr>
        </table>

        <apply-promo @update="checkDiscount($event)" class="mb-3" v-if="!promo"></apply-promo>
       
        <payment-button :label="'$'+getTotal()" :amount="getTotal()" 
        :type="subscription.type" :description="subscription.description" @success="onPaid()"
        :promo_code="promo ? promo.code : null"
        ></payment-button>
      </div>
    </div>
</template>
<script>

import { CompanyCard } from '@/resources/company_card'
import { Subscription } from '@/resources/subscription'
import PaymentButton from '../../UIComponents/PaymentButton.vue'
import ApplyPromo from '../../Dashboard/Views/Components/ApplyPromo.vue'


export default {
  components: { PaymentButton, ApplyPromo },
  props: ['subscription'],
  data() {
    return {
      card: null,
      loading: false,
      company_card_id: null,
      promo: null
    }
  },
  created: function () {
    this.subscription.original_amount = this.subscription.amount
    if (this.subscription.promo) {
      this.promo = this.subscription.promo
      this.checkDiscount(this.promo)
    }
  },
  methods: {
    checkDiscount(promo) {
      if (promo.promo_type == 'setup_fee') {
        let amount = promo.discount_type == 1 ? this.subscription.setup_fee * (promo.discount_amount / 100) : promo.discount_amount
        this.subscription.setup_fee = Math.max(0, this.subscription.setup_fee - amount)
      }

      if (promo.promo_type == 'plan') {
        let amount = promo.discount_type == 1 ? this.subscription.amount * (promo.discount_amount / 100) : promo.discount_amount
        this.subscription.amount = Math.max(0, this.subscription.amount - amount)
      }
      this.promo = promo
    },
    formatNumber(num) {
      return parseFloat(num).toFixed(2)
    },
    getTotal() {
      return parseFloat(this.subscription.amount)+parseFloat(this.subscription.setup_fee || 0)
    },
    onPaid() {
      let form = {
        company_card_id: this.company_card_id,
        plan_id: this.subscription.id,
        option: this.subscription.option,
        type: this.subscription.type,
        description: this.subscription.description,
        promo_code: this.promo ? this.promo.code : null,
        office_id: this.subscription.office_id
      }

      this.loading = true

      if (this.subscription.type == 'upgrade') {
        this.upgradePlan(form)
      } else {
        Subscription.renew(form)
          .then(result => {
            this.$emit('paid', true)
          })
          .catch(() => {

          }).finally(() => {
            this.loading = false
          })
      }
    },
    upgradePlan(form) {
      Subscription.upgrade(form)
        .then(result => {
          this.$emit('paid', true)
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
