<template>
  <div v-if="user" v-loading.fullscreen.lock="loading">
    <div class="row">
      <div class="col-md-4">
        <el-card>
          <div slot="header" class="clearfix">
            <span >Summary</span>
            <h5 class="mt-2" v-if="booking && booking.meeting_room.type == 1">Co-Working Space</h5>
            <h5 class="mt-2" v-else>Meeting Room</h5>
          </div>
          <div v-if="booking">
            
            <div>{{ booking.meeting_room.name }} - {{ booking.meeting_room.office.name }}</div>
            <div>Capacity: {{ booking.meeting_room.capacity }} people</div>
            <div>Address: {{ booking.meeting_room.office.address.full_address }}</div>
            <div>Total Hours: {{ booking.hours.length }} hrs</div>
            <div>
              <el-tag class="ml-0">{{ booking.date }} | {{ booking.time }}</el-tag>
            </div>
            <hr>
            <div>
                <el-tag type="warning" v-if="booking.status == 0" style="margin-left: 0">Unpaid</el-tag>
                <el-tag type="danger" v-if="booking.status == 2" style="margin-left: 0">Booked</el-tag>
              </div>
          </div>
          <div v-if="!booking">No booking selected yet.</div>
        </el-card>
      </div>
      <div class="col-md-8" v-if="booking && booking.meeting_room.pictures">
        <el-card>
              <meeting-room-picture-preview :meeting_room="booking.meeting_room"></meeting-room-picture-preview>

        </el-card>
      </div>
    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import { MeetingRoom } from '@/resources/meeting_room'
import MeetingRoomPicturePreview from '../Client/MeetingRoomPicturePreview.vue';

export default {
  components: { MeetingRoomPicturePreview },
  data() {
    return {
      loading: true,
      booking: null
    }
  },
  created: function () {
    if (this.$route.params.code) {
      this.getBooking(this.$route.params.code)
    }
  },
  methods: {
    getBooking(code) {
      this.loading = false
      MeetingRoom.getBookingByCode(code, {})
        .then(result => {
          this.booking = result.data.booking
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    }
  },
  computed: mapState(['user']),
}
</script>
<style lang="scss"></style>
