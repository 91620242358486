<template>
  <div class="" v-loading.fullscreen.lock="loading">

    <el-dialog title="Attention" :visible.sync="showDelete" direction="rtl" :before-close="handleClose">
      <div style="padding: 10px;">
        <!-- <p>Remove this plan? <el-link type="danger" @click="deleteItem()" class="red-label">Yes, remove it!</el-link></p> -->

      </div>
    </el-dialog>

    <el-dialog :title="selected && selected.id ? 'Edit' : 'New'" :visible.sync="showForm" direction="rtl" :size="400"
      :before-close="handleClose">
      <div style="padding: 10px;">
          <add-payment-method ref="step4" @on-validated="validateForm" :full-screen="true"></add-payment-method>
          <hr>
          <el-button :disabled="!formValid" @click="saveCard()">Save</el-button>
      </div>
    </el-dialog>

    <div>
       <h4>
        Payment Methods
       </h4>
        <el-alert
        effect="dark"
      title="You can create up to 4 cards only"
      type="warning">
    </el-alert>
       <hr>
      
    </div>

    <div class="row">
      <div class="col-md-12">
         <p-button type="primary" size="md" @click="handleNew()" v-if="cards.length < 4">
              <i class="fa fa-plus"></i> NEW
            </p-button>
      </div>

      <div class="row col-md-12">
        <div class="col-md-4"></div>
        <div class="col-md-4 row">
            <div class="col-md-12" v-for="card in cards" :key="card.id" style="margin-bottom: 10px">
              <el-card>
                <div>
                  <span> {{ card.card_number }} - {{ card.name }} </span>
                </div>
                <p-button type="danger" size="sm" @click="handleRemove(card)" v-if="!card.is_default">
                      <i class="fa fa-trash"></i> Remove
                    </p-button>
                    <p-button type="success" size="sm" @click="handleDefault(card)" v-if="!card.is_default">
                        <i class="fa fa-eye"></i> Make default
                      </p-button>

                      <p-button type="success" size="sm" @click="handleDefault(card)" v-if="card.is_default" disabled>
                          <i class="fa fa-eye"></i> Default
                        </p-button>

              </el-card>
            </div>
        </div>
        <div class="col-md-4">

        </div>
      </div>
      
    </div>

  </div>
</template>
<script>

import { CompanyCard } from '@/resources/company_card'
import AddPaymentMethod from '../CompanyPlan/AddPaymentMethod.vue'

export default {
  components: { AddPaymentMethod },
  data() {
    return {
      showDelete: false,
      showForm: false,
      cards: [],
      selected: null,
      loading: false,
      term: null,
      formValid: false
    }
  },
  created: function () {
    this.getCards()
  },
  methods: {
    validateForm(validated, model, type) {
      this.formValid = validated
      this.selected = Object.assign({}, model)
    },
    handleClose() {
      this.page_loading = false
      this.showDelete = false
      this.showForm = false
    },
    handleNew() {
      this.showForm = true
      this.selected = {}
    },
    saveCard() {
      this.loading = true
      CompanyCard.create(this.selected)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Payment method saved',
          });
          this.getCards()
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
    handleDefault(card) {
      let param = { default: true, id: card.id }
      this.loading = true

      CompanyCard.makeDefault(param)
        .then(result => {
          this.getCards()
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
    handleRemove(card) {
      this.loading = true
      CompanyCard.delete({id: card.id})
        .then(result => {
          this.getCards()
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
    getCards() {
      this.handleClose()
      this.loading = true
      this.loading = true
      CompanyCard.get({ params: { status: 1, page: 1, per_page: 999 } })
        .then(result => {
          if (result.data.cards)
            this.cards = result.data.cards
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
