<template>
  <div v-loading.fullscreen.lock="loading">
    <el-card class="box-card mb-3">
      <h5>Meeting Rooms Scheduled Today</h5>
      <span class="note" v-if="!bookings.length">No reservations today</span>
      <el-card shadow="always" v-for="booking in bookings" :key="booking.id" style="padding: 10px">
        <div >
          <span>{{ booking.meeting_room.name }}</span>
          <div class="bottom clearfix">
            <time class="time note">{{ booking.time }}</time>
             <el-link type="warning" style="margin-left: 10px" :href="'/meeting-room/booking/'+booking.code+'/view'">Open</el-link>
          </div>
        </div>
      </el-card>
    </el-card>

    <el-card class="box-card mb-3">
        <h5>Co-Working Space Scheduled Today</h5>
        <span class="note" v-if="!co_workings.length">No reservations today</span>
        <el-card shadow="always" v-for="booking in co_workings" :key="booking.id" style="padding: 10px">
          <div >
            <span>{{ booking.meeting_room.name }}</span>
            <div class="bottom clearfix">
              <time class="time note">{{ booking.time }}</time>
               <el-link type="warning" style="margin-left: 10px" :href="'/co-working-space/booking/' + booking.code + '/view'">Open</el-link>
            </div>
          </div>
        </el-card>
      </el-card>

  </div>
</template>
<script>

import { Analytic } from '@/resources/analytics'

export default {

  data() {
    return {
      loading: false,
      bookings: [],
      co_workings: []
    }
  },
  created: function () {
    this.getBookings()
    this.getCoWorkings()
  },
  methods: {
    getBookings() {
      this.loading = true
      Analytic.bookingsToday()
        .then(result => {
          this.bookings = result.data.bookings
        })
        .catch(() => {
        }).finally(() => {
          this.loading = false
        })
    },
    getCoWorkings() {
      this.loading = true
      Analytic.bookingsToday({params: {type: 1}})
        .then(result => {
          this.co_workings = result.data.bookings
        })
        .catch(() => {
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
