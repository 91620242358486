<template>
  <ValidationObserver v-slot="{ handleSubmit }" v-loading="loading">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ form.id ? 'Update Office' : 'Create new Office' }}
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>Name</label>
            <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The name field is required' : null" :hasSuccess="passed" name="name"
                v-model="form.name">
              </fg-input>
            </ValidationProvider>
          </div>

          <div class="form-group">
            <label>Description</label>
            <ValidationProvider name="description" v-slot="{ passed, failed }">
              <fg-input :textarea="true" type="text" :error="failed ? 'The description field is required' : null" :hasSuccess="passed"
                name="remarks" v-model="form.description">
              </fg-input>
            </ValidationProvider>
          </div>

          <div class="form-group">
            <label>Status</label>
            <select class="form-control" v-model="form.status">
              <option :value="1">Active</option>
              <option :value="0">Inactive</option>
            </select>
          </div>

          <hr>
          <address-form :address="form.address" @on-validated="setAddress" ref="addressRef"></address-form>

        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Save</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>

import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Office } from '@/resources/office'
import AddressForm from '../../Address/AddressForm.vue';

extend("required", required);

export default {
  props: ['office'],
  components: { AddressForm },
  data() {
    return {
      form: {
        name: "",
        description: null,
        status: 0,
        address: {}
      },
      new_detail: {
        description: null
      },
      loading: false
    };
  },
  created: function () {
    if (this.office) this.form = Object.assign({}, this.office)
    if (!this.form.id) this.form.address = {}
  },
  watch: {
    office() {
      if (this.office) this.form = Object.assign({}, this.office)
      if (!this.form.id) this.form.address = {}
    }
  },
  methods: {
    setAddress(validated, address) {
      if (validated) {
        this.form.address = Object.assign({}, address)
      }
    },
    reset() {
      this.form = {
        name: "",
        description: null,
        status: 0,
        address: {}
      }
    },
    submit() {
      this.loading = true
      this.setAddress(true, this.$refs['addressRef'].form)
      let request = this.form.id ? Office.update(this.form) : Office.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Office has been saved',
          });
          this.reset()
          this.$emit('update', true)
        })
        .catch(error => {
          let message = error.response.data.message || 'Unable to save this time'
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message,
          });
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style></style>
