<template>
  <div>
    <meeting-room-booking-view></meeting-room-booking-view>
  </div>
</template>
<script>
import MeetingRoomBookingView from '../MeetingRoom/Booking/MeetingRoomBookingView.vue'


export default {
  components: { MeetingRoomBookingView },
  data() {
    return {

    }
  },
  created: function () {
    // This component will be shown one time. When the new client login for the first time
  },
  methods: {

  },
}
</script>
<style lang="scss"></style>
