<template>
  <div>
    <meeting-rooms-list :type="1"></meeting-rooms-list>
  </div>
</template>
<script>
import MeetingRoomsList from '../MeetingRoom/MeetingRoomsList.vue'


export default {
  components: { MeetingRoomsList },
  data() {
    return {

    }
  },
  created: function () {
    // This component will be shown one time. When the new client login for the first time
  },
  methods: {

  },
}
</script>
<style lang="scss"></style>
