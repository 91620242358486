<template>
  <div class="row" v-loading.fullscreen.lock="page_loading">

    <el-drawer title="Attention" :visible.sync="showDelete" direction="rtl" :before-close="handleClose">
      <div style="padding: 10px;">
        <p>Remove this plan? <el-link type="danger" @click="deleteItem()" class="red-label">Yes, remove it!</el-link></p>

      </div>
    </el-drawer>

    <el-drawer :title="selected && selected.id ? 'Edit' : 'New'" :visible.sync="showForm" direction="rtl" :size="600"
      :before-close="handleClose">
      <div style="padding: 10px;">
        <plan-form :plan="selected" @update="getPlans()"></plan-form>
      </div>
    </el-drawer>


    <div class="col-md-12 card" v-loading="loading">
      <div class="card-header">
        <div class="category">Plans</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="col-md-6">
          <p-button type="primary" size="sm" style="float:right" @click="handleNew()">
            <i class="fa fa-plus"></i> NEW
          </p-button>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="plans" style="width: 100%">
            <el-table-column :min-width="120" class-name="td-actions" label="Code">
                <template slot-scope="props">
                  {{ props.row.code }}
                </template>
              </el-table-column>
              <el-table-column :min-width="120" class-name="td-actions" label="Level">
                <template slot-scope="props">
                  {{ props.row.level }}
                </template>
              </el-table-column>
            <el-table-column :min-width="120" class-name="td-actions" label="Name">
              <template slot-scope="props">
                {{ props.row.name }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="Price/Month">
                <template slot-scope="props">
                  ${{ props.row.price_per_month }}
                </template>
              </el-table-column>

              <el-table-column :min-width="120" class-name="td-actions" label="Price/Year">
                  <template slot-scope="props">
                    ${{ props.row.price_per_year }}
                  </template>
                </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="Setup Fee">
                <template slot-scope="props">
                  ${{ props.row.setup_fee }}
                </template>
              </el-table-column>

            <el-table-column :min-width="100" fixed="right" class-name="td-actions" label="Actions">
              <template slot-scope="props">
                <p-button type="primary" size="sm" icon @click="handleEdit(props.row)">
                  <i class="fa fa-pencil"></i>
                </p-button>
                <p-button type="danger" size="sm" icon @click="handleDelete(props.row)">
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { Plan } from '@/resources/plan'

import Vue from 'vue'
import { Table, TableColumn, Select, Option, Drawer } from 'element-ui'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import PlanForm from './PlanForm.vue'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(Drawer)

export default {
  components: {
    PPagination,
    PlanForm
  },
  data() {
    return {
      showDelete: false,
      showForm: false,
      plans: [],
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0
      },
      selected: null,
      loading: false,
      term: null,
      page_loading: false
    }
  },
  created: function () {
    this.getPlans()
  },
  methods: {
    handleClose() {
      this.page_loading = false
      this.showDelete = false
      this.showForm = false
    },
    handleDelete(role) {
      this.showDelete = true
      this.selected = Object.assign({}, role)
    },
    handleNew() {
      this.showForm = true
      this.selected = {}
    },
    handleEdit(plan) {
      this.showForm = true
      this.selected = Object.assign({}, plan)
    },
    deleteItem() {
      this.page_loading = true
      Plan.delete({ id: this.selected.id })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Removed successfully',
          });
          this.getPlans()
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to delete role',
          });

        }).finally(() => {
          this.loading = false
        })

    },
    getPlans() {
      this.handleClose()
      this.loading = true
      Plan.get({ params: { term: this.term, status: this.status, page: this.pagination.currentPage, per_page: this.pagination.perPage } })
        .then(result => {
          if (result.data.plans)
            this.plans = result.data.plans
          // this.pagination = result.data.meta
        })
        .catch(() => {
      
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch plans',
          });

        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
