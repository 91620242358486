<template>
  <div v-loading.fullscreen.lock="loading">
    <stats-card small-title="Pending Requests" :title="total" type="danger"
      icon="nc-icon nc-email-85"></stats-card>
  </div>
</template>
<script>

import { Analytic } from '@/resources/analytics'
import StatsCard from '../../UIComponents/Cards/StatsCard.vue'

export default {
  components: { StatsCard },
  data() {
    return {
      loading: false,
      total: 0
    }
  },
  created: function () {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      Analytic.allPendingRequests()
        .then(result => {
          this.total = result.data.total
        })
        .catch(() => {
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
