<template>
  <div v-loading.fullscreen.lock="loading">
    
    <el-dialog
        title="Pay to view the attachments"
        :visible.sync="showPayToViewAttachment"
        >
        <payment-button :label="'$1.00'" :amount="1.00"  v-if="mail"
          :type="'mail_action_view_attachment_mail_id_'+mail.id" :description="'View attachment'" @success="onPaidToViewAttachment()"
          ></payment-button>
      </el-dialog>

      <el-dialog
          title="Pay to view the content"
          :visible.sync="showPayToShowContent"
          >
          <payment-button :label="'$1.00'" :amount="1.00"  v-if="mail"
            :type="'mail_action_open_mail_id_' + mail.id" :description="'View mail content'" @success="onPaidToViewContent()"
            ></payment-button>
        </el-dialog>


    <el-dialog
      title="Attention"
      :visible.sync="showConfirm"
      
      >
      <mail-action-request :mail="mail" :action="selectedAction" @update="getMail()"></mail-action-request>
    </el-dialog>
    
    <h4 class="card-title"> 
      <el-button type="warning" icon="el-icon-caret-left" circle @click="back()"></el-button>
      View</h4>

     <el-alert
     v-if="mail && mail.has_pending_action"
      :title="getAlertMessage()"
      type="warning"
      show-icon>
    </el-alert>
    <hr>

    <div v-if="notFound">Mail Not found</div>

    <div class="row"  v-if="mail">
      <div class="col-md-9 col-sm-12">
        <card style="">
          <div class="">Record No: {{ mail.id }}</div>
          <div class="">Created on: {{ mail.date_created }}</div>
          <br>
          <p class="bold">Subject:</p>
          <div class=""> {{ mail.subject }}</div>
          <hr>
          <!-- <div class="bold">From: {{ mail.sender }}</div> -->
          <!-- <p class="bold">Body:</p> -->
          <p>{{ mail.message }}</p>
          <div  v-if="mail.files.length">
            <hr>
            <p>Attachment(s)</p>
          </div>
          <div class="attachment row" v-if="mail.is_attachment_viewable || isManager()">
              <div class="col-md-4" v-for="file, i in mail.files" :key="i">
                <el-image 
                  v-if="image_formats.includes(file.file_type)"
                  style="width: 100px; height: 100px"
                  :src="file.link" 
                  :preview-src-list="[file.link]">
                </el-image>
                <div v-else>
                  <a :href="file.storage_path" >{{ file.file_name }}</a>
                </div>
              </div>
          </div>
          <div v-else>
              <el-button type="info" @click="showPayToViewAttachment = true" v-if="mail.files.length">Pay $1 to view the attachments</el-button>
            </div>

          <div v-if="open_action && open_action.status == 1 && open_action.files">
            <div v-if="mail.is_scanned_files_viewable || isManager()">
              <hr>
              <p>Content</p>
              <div class="attachment row">
                  <div class="col-md-4" v-for="file, i in open_action.files" :key="i">
                    <el-image 
                    v-if="image_formats.includes(file.file_type)"
                      style="width: 100px; height: 100px"
                      :src="file.link" 
                      :preview-src-list="[file.link]">
                    </el-image>
                    <div v-else>
                      <a :href="file.storage_path" >{{ file.file_name }}</a>
                    </div>
                  </div>
              </div>
            </div>
            <div v-else>
              <el-button type="info" @click="showPayToShowContent = true">Pay $1 to view the content of your mail</el-button>
            </div>
          </div>

          <div v-if="mail.forwarded">
              <hr>
              <p>Forward Information</p>
              <p>Recipient: {{ mail.forwarded.forward_to }}</p>
              <p>Message: {{ mail.forwarded.forward_message }}</p>
              <p>Note: {{ mail.forwarded.note }}</p>
          </div>
          
          <div v-if="isActionAvailable()">
            <hr>
            <div class="form-group">
              <label>What would you like to do with this letter?</label>
            </div>
              <el-select v-model="action" placeholder="Action" @change="onShowConfirm()" >
          <el-option :label="action.label" :value="action.value" v-for="action in actions " :key="action.value"></el-option>
        </el-select>

          </div>
          
        </card>
      </div>

      <div class="col-md-3 col-sm-12">
        <card style="">
          <h5>History</h5>
          <hr>

          <el-timeline>
            <el-timeline-item
               v-for="(mail_action,i) in mail.mail_actions" :key="i"
              :timestamp="mail_action.date">
              {{ mail_action.history_note }}
            </el-timeline-item>
          </el-timeline>
        </card>
      </div>

    </div>
  </div>
</template>
<script>

import { Mail } from '@/resources/mail';
import { UserMail } from '@/resources/user_mail';
import ForwardMailForm from './ForwardMailForm.vue';
import MailActionRequest from './MailActionRequest.vue';
import { mapState } from 'vuex';
import PaymentButton from '../../UIComponents/PaymentButton.vue';

export default {
  computed: {
    ...mapState(['user'])
  },
  props: ['mail_id'],
  components: { ForwardMailForm, MailActionRequest, PaymentButton },
  data() {
    return {
      showPayToShowContent: false,
      showPayToViewAttachment: false,
      loading: true,
      mail: null,
      action: null,
      showConfirm: false,
      selectedAction: null,
      default_actions: [
        { value: 'open', label: 'Open and Scan ($1.00)', amount: 1 },
        { value: 'trash', label: 'Trash ($1.00)', amount: 1 },
        { value: 'shred', label: 'Shred ($1.00)', amount: 1 },
        { value: 'forward', label: 'Forward ($1.00)', amount: 1 }
      ],
      actions: [],
      form: {},
      open_action: null,
      image_formats: ['png', 'jpg', 'jpeg'],
      forwardMail: null,
      notFound: false
    };
  },
  created: function () {
    this.getMail()
  },
  methods: {
    isManager() {
      return this.user.role_names.includes('admin') ||  this.user.role_names.includes('manager')
    },
    onPaidToViewAttachment() {
      this.showPayToViewAttachment = false
      this.getMail()
    },
    onPaidToViewContent() {
      this.showPayToShowContent = false
      this.getMail()
    },
    isActionAvailable() {
      if (this.mail && this.mail.forwarded) return false
      if (this.mail && this.mail.trashed) return false
      if (this.mail && this.mail.shredded) return false
      if (this.mail && this.actions.length && !this.mail.has_pending_action) return true
    },
    isInvalid() {
      if (!this.selectedAction) return true
      if (this.selectedAction.value == 'forward') {
        return this.forwardMail ? false : true
      }

      return false
    },
    onStepValidated(validated, model, type) {
      if (type == 'forward_mail') {
        this.forwardMail =  validated ? Object.assign({}, model) : null
      }
    },
    getOpenAction() {
      let self = this
      this.mail.mail_actions.forEach(function (mail_action) {
        if (mail_action.action == 'open') self.open_action = mail_action
      })
    },
    getAlertMessage() {
      let message = "Waiting response for"
      return this.mail.latest_action
      switch (this.mail.latest_action) {
        case "Opened":
          message += " open and scan request"
          break;
        case "Shredded":
          message += " shredding request"
          break
        case "Trashed":
          message += " moving to trash request"
          break;
        case "Forwarded":
          message += " forward request"
          break;
        default:
          message += " opened";
      }
      
      return message
    },
    back() {
       this.$router.go(-1)
    },
    onShowConfirm() {
      this.showConfirm = true
      this.setSelectedAction()
    },
    setSelectedAction() {
      let self = this
      this.actions.forEach(function (action) {
        if (self.action == action.value) self.selectedAction = action
      })
    },
    
    takeAction() {
      this.loading = true
      this.form.action = this.action
      if (this.forwardMail && this.action == 'forward') {
        this.form.forward_to = this.forwardMail.forward_to
        this.form.foward_message = this.forwardMail.forward_message
        this.form.note = this.forwardMail.note
      }
      UserMail.takeAction(this.mail.id, this.form)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: "Updated successfully",
          });

          this.getMail()
        })
        .catch(error => {
          let message = error.response.data.message || 'Unable to update this time'
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message
          });
        }).finally(() => {
          this.loading = false
        })
    },
    getAvailableActions() {
      this.actions = []

      let shredded = false
      let opened = false

      let self = this

      if (this.mail.actions_taken.includes('open')) opened = true

      this.default_actions.forEach(function (default_action) {
        if (!self.mail.actions_taken.includes(default_action.value)) {
          if (!opened && default_action.value == 'shred') {
           
          } else {
            self.actions.push(default_action)
          }
        }
      })

      if (this.mail.actions_taken.includes('shred')) shredded = true

      if (shredded) {
        this.actions = []
      }
    },
    getMail() {
      
      this.loading = true
      this.showConfirm = false
      let id = this.mail_id || this.$route.params.id
      Mail.show(id, {open: true})
        .then(result => {
          this.mail = result.data.mail
          this.getMailActionOptions()
          this.getOpenAction()
        })
        .catch(error => {
          let message = error.response.data.message || 'Unable to open mail this time'
          if (error.response.status == 404) {
            this.notFound = true
          }
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message,
          });
        }).finally(() => {
          this.loading = false
        })
    },
    getMailActionOptions() {
      this.loading = true
      Mail.mailActionOptions()
        .then(result => {
          this.default_actions = result.data.actions
          this.getAvailableActions()
        })
        .catch(error => {
          
        }).finally(() => {
          this.loading = false
        })
    }
  },
};
</script>
<style lang="scss">
.action {
  background: #fff;
}

.unread-row td {
  background: #F5F7FA;
}

.bold {
  font-weight: bold;
}
</style>

