<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <hr>
      <div v-if="user_location">
        <p style="font-size: 18px;">Where would you like to forward the mail?</p>
        <ValidationProvider name="address_id" rules="required">
          <div>
            <el-radio v-model="form.address_id" :label="user_location.primary_address.id">(Primary) {{ user_location.primary_address.full_address }}</el-radio>
          </div>
          <div>
            <el-radio v-model="form.address_id" :label="user_location.secondary_address.id">(Secondary) {{ user_location.secondary_address.full_address }}</el-radio>
          </div>
        </ValidationProvider>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);

export default {
  props: ['user_location'],
  data() {
    return {
      form: {
        address_id: null
      }
    };
  },
  watch: {
    form: {
      handler: function (newVal) {
        this.validate()
      },
      deep: true
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then(res => {
        console.log(res)
        this.$emit('on-validated', res, this.form, 'forward_mail')
        return res
      })
    }
  }
}
</script>
<style lang="scss">
.card-wizard .picture-container .picture {
  input[type="file"] {
    display: none;
  }
}
</style>
