<template>
  <div class="form-group"
         :class="[
           { 'has-danger': error },
           { 'has-success': passed }]">
  <slot>
    <el-autocomplete
      v-model="state"
      :fetch-suggestions="querySearchAsync"
      placeholder="Please input"
      :clearable="true"
      @select="handleSelect"
      @change="handleChange"
      style="width: 100%"
    >
    <template slot-scope="{ item }">
      <div class="value">{{ item.value }}</div>
    </template>
  </el-autocomplete>
  </slot>
  
  <slot name="helpBlock">
    <div class="invalid-feedback error-text">
      {{ error }}
    </div>
  </slot>
  </div>
</template>
<script>


import { Company } from '@/resources/company';

export default {
  props: {
    passed: {
      type: Boolean,
      description: 'Whether form is passed',
      default: false
    },
    error: {
      type: String,
      description: 'Error message',
      default: null
    },
    company: {
      type: Object,
      description: 'Company',
      default: null
    }
  },
  data() {
    return {
      links: [],
      state: '',
      timeout: null
    };
  },
  created: function () {
    if (this.company) this.state = this.company.name
  },
  methods: {
    loadAll() {
      return [
        { "value": "vue", "link": "https://github.com/vuejs/vue" },
        { "value": "element", "link": "https://github.com/ElemeFE/element" },
        { "value": "cooking", "link": "https://github.com/ElemeFE/cooking" },
        { "value": "mint-ui", "link": "https://github.com/ElemeFE/mint-ui" },
        { "value": "vuex", "link": "https://github.com/vuejs/vuex" },
        { "value": "vue-router", "link": "https://github.com/vuejs/vue-router" },
        { "value": "babel", "link": "https://github.com/babel/babel" }
      ];
    },
    querySearchAsync(queryString, cb) {
      Company.get({params: { term: queryString } })
        .then(result => {
          let items = []
          result.data.companies.forEach(element => {
            items.push({value: element.name, id: element.id})
          });
          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            cb(items);
          }, 1000 * Math.random());

        })
        .catch(error => {
          let message = error.response.data.message || 'Unable to fetch companies at the moment'
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message,
          });
        })

      // var links = this.links;
      // var results = queryString ? links.filter(this.createFilter(queryString)) : links;

      // clearTimeout(this.timeout);
      // this.timeout = setTimeout(() => {
      //   cb(results);
      // }, 3000 * Math.random());
    },
    createFilter(queryString) {
      return (link) => {
        return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item) {
      console.log(item)
      this.$emit('update', item)
    },
    handleChange(item) {
       this.$emit('update', null)
    }
  },
  mounted() {
    this.links = this.loadAll();
  }
};
</script>

<style scoped>
.error-text {
  display: block;
}
</style>