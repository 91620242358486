<template>
  <div v-if="user" v-loading.fullscreen.lock="loading">
    <h5>{{ room.meeting_room.name }} - {{ room.meeting_room.office.name }}</h5>
    <div>Capacity: {{ room.meeting_room.capacity }} people</div>
    <div>Address: {{ room.meeting_room.office.address.full_address }}</div>
    <hr>
    <div>
      <div v-for="hour in filteredHours()" :key="hour.value">
        <el-button class="btn-block" @click="select(hour)" :type="isSelected(hour)" :disabled="isHourBooked(hour.value)">{{ hour.text }}</el-button>
      </div>
    </div>
    <el-button class="btn-block" @click="close()" type="info">Done</el-button>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import { MeetingRoom } from '@/resources/meeting_room'

export default {
  props: ['room', 'booked_hours'],
  data() {
    return {
      loading: false,
      selected_hours: [],
      clicked: 0,
      start_hour: null,
      last_hour: null
    }
  },
  created: function () {
    // this.getUser(this.$route.params.id)
  },
  methods: {
    isHourBooked(hour) {
      return this.booked_hours.includes(hour)
    },
    filteredHours() {
      let hours = this.hours()
      let filteredHours = []
      let self = this
      hours.forEach(function (hour) {
        if (self.room.hours.includes(hour.value)) filteredHours.push(hour)
      })

      return filteredHours
    },
    select(hour) {
      if (this.clicked >= 2) this.clicked = 0

      if (this.clicked == 0) this.start_hour = hour
      if (this.clicked == 1) this.last_hour = hour

      this.last_hour = !this.last_hour ? this.start_hour : hour

      this.clicked += 1

      let hours = [this.start_hour, this.last_hour]

      hours.sort(function (a, b) {
        return a.value - b.value;
      });

      let start = hours[0]
      let end = hours[1]

      let newHours = [];
      let self = this
      let lastHourPushed = null
      let stop = false
      
      this.filteredHours().forEach(function (filteredHour) {
        if (filteredHour.value >= start.value && filteredHour.value <= end.value) {
          if (!self.isHourBooked(filteredHour.value) && !stop) {
            lastHourPushed = filteredHour
          } else {
            stop = true
          }
        } 
      })

      if (stop && lastHourPushed) {
        if (lastHourPushed.value < this.start_hour.value) this.start_hour = lastHourPushed
        else this.last_hour = lastHourPushed
        
        lastHourPushed = null
        stop = false
      }

      hours = [this.start_hour, this.last_hour]

      hours.sort(function (a, b) {
        return a.value - b.value;
      });

      start = hours[0]
      end = hours[1]
      newHours = [];

      this.filteredHours().forEach(function (filteredHour) {
        if (filteredHour.value >= start.value && filteredHour.value <= end.value) {
          if (!self.isHourBooked(filteredHour.value) && !stop) {
            newHours.push(filteredHour)
          } else {
            stop = true
          }
        }
      })

      hours = [this.start_hour, this.last_hour]

      hours.sort(function (a, b) {
        return a.value - b.value;
      });

      this.start_hour = hours[0]
      this.last_hour = hours[1]


      this.selected_hours = newHours
   },
    isSelected(hour) {
      let data = null
      this.selected_hours.forEach(function (sh) {
        if ( sh.value == hour.value) data = hour
      })
      return data ? 'success' : ''
    },
    close() {
      // save booking as new
      this.loading = true
      let form = Object.assign({}, this.room)
      form.selected_hours = this.selected_hours
      form.from_hour = this.start_hour.value
      form.to_hour = this.last_hour.value
      form.date = this.$moment(form.date).format('YYYY-MM-DD')
      MeetingRoom.createBooking(this.room.meeting_room.id, form)
        .then(result => {
          this.$emit('create', result.data.booking)
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })

      
    }
  },
  computed: mapState(['user']),
}
</script>
<style lang="scss"></style>
