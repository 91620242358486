<template>
  <div v-if="user" v-loading.fullscreen.lock="loading || loading_via_card">
    <el-button :disabled="disabled" type="success" class="btn-block mb-3" @click="payWithBalance()" v-if="allowed_payment_balance_types.includes(type) && user.balance_number">
      <span v-if="!loading">Pay {{ label }} with your balance</span>
      <span v-else>Sending Request...</span>
    </el-button>

    <div v-if="user.default_payment">
      <el-button :disabled="disabled" type="success" class="btn-block" @click="payWithCard()" v-if="user.default_payment.card">
        <span v-if="!loading_via_card">Pay {{ label }} with card *{{ user.default_payment.card.last4 }}</span>
        <span v-else>Sending Request...</span>
      </el-button>

      <el-button :disabled="disabled" type="success" class="btn-block" @click="payWithCard()" v-if="user.default_payment.link">
          <span v-if="!loading_via_card">Pay {{ label }} with card (Link)</span>
          <span v-else>Sending Request...</span>
        </el-button>

      <hr>
    </div>
    <div>
      <div v-if="!user.default_payment" class="mb-2">
        <el-alert type="warning"  effect="dark" ><p style="font-size: 12px">
        In order to process your payment, you have to setup your payment card first
        </p> </el-alert>
      </div>
        <el-button :disabled="disabled" type="info" @click="createPaymentMethodLink()" class="btn-block mb-3 ">{{ loading ? 'Checking' : (user.default_payment ? 'Update ': 'Set ')+' your payment card' }}</el-button>
        <a :href="update_payment_method_link" ref="paymentMethodLink" ></a>
    </div>

  </div>
</template>
<script>

import { mapState } from 'vuex';
import { Payment } from '@/resources/payment'
import { CompanyCard } from '@/resources/company_card'

import Swal from 'sweetalert2'

export default {
  props: ['amount','label', 'type','description','code','disabled', 'promo_code'],
  data() {
    return {
      loading_via_card: false,
      loading: false,
      update_payment_method_link: null,
      allowed_payment_balance_types: ['renew','meeting_room_booking','due_amount_payment']
    }
  },
  created: function () {
  },
  methods: {
    payWithBalance() {
      this.loading = true
      Payment.payWithBalance({
        amount: this.amount, description: this.description,
        type: this.type, code: this.code,
        promo_code: this.promo_code
      })
        .then(result => {
          this.$store.commit('saveUser', result.data.user);
          this.$emit('success', true)
        })
        .catch(() => {
        }).finally(() => {
          this.loading = false
        })
    },
    payWithCard() {
      this.loading_via_card = true
      Payment.payWithCard({ amount: this.amount, description: this.description, type: this.type, code: this.code, promo_code: this.promo_code })
        .then(result => {
          this.$store.commit('saveUser', result.data.user);
          this.$emit('success', true)
        })
        .catch(() => {
          alert("An error occured. Please reload the page")
           this.$router.go(this.$router.currentRoute)
          // this.$emit('error', true)
        }).finally(() => {
          this.loading_via_card = false
        })
    },
    async success() {
      let text = "Thank you for loading"
      if (this.type == 'renew') text = "Thank you for renewing your subscription"
      const { value: res } = await Swal.fire({
        title: "Success",
        text: text,
        icon: "success",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      })

      this.$router.push({ name: 'Home', query: { refresh: true } })
    },
    createPaymentMethodLink() {
      this.loading = true
      CompanyCard.createPaymentMethodLink({return_url: this.$route.fullPath })
        .then(result => {
          this.update_payment_method_link = result.data.url
          const elem = this.$refs.paymentMethodLink
          setTimeout(() => {
            if (elem) elem.click()
          }, 1000);
        })
        .catch(() => {
        }).finally(() => {
          this.loading = false
        })
    }
  },
  computed: mapState(['user']),
}
</script>
<style lang="scss"></style>
