import api from './api'

const API = '/api/analytics'

export const Analytic = {
  bookingsToday: function(params) {
    return api.get(API + '/bookings-today', params)
  },
  unreadMails: function(params) {
    return api.get(API + '/unread-mails', params)
  },
  pendingMailRequests: function(params) {
    return api.get(API + '/pending-mail-requests', params)
  },
  receivedMailsOneYear: function(params) {
    return api.get(API + '/received-mails-one-year', params)
  },
  topUsers: function(params) {
    return api.get(API + '/top-users', params)
  },
  salesThisMonth: function(params) {
    return api.get(API + '/sales-this-month', params)
  },
  allPendingRequests: function(params) {
    return api.get(API + '/all-pending-mail-requests', params)
  },
  allMailsReceivedInAYear: function(params) {
    return api.get(API + '/all-mails-received-in-a-year', params)
  },
}
