<template>
  <div v-loading.fullscreen.lock="loading">
    <div class="row" v-if="user_location">
      <div class="col-md-12">
        <h4>Your Billing Address</h4>
      </div>
      <div class="col-md-3" >
         <el-card class="orange-bg white-text">
          <h5>Primary Address</h5>
          <div v-if="user_location">{{ user_location.primary_address.full_address }}</div>
         </el-card>
      </div>
      <div class="col-md-3">
         <el-card class="black-bg white-text">
            <h5>Secondary Address</h5>
                       <div v-if="user_location && user_location.secondary_address">{{ user_location.secondary_address.full_address }}</div>
           </el-card>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-5">
       <user-location-form :user_location="user_location" @update="getUserLocation()"></user-location-form>
      </div>
      
    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import { UserLocation } from '@/resources/user_location'
import UserLocationForm from './UserLocationForm.vue';

export default {
  components: { UserLocationForm },
  name: 'user-location-list',
  computed: mapState(['user']),
  data() {
    return {
      user_location: null,
      loading: false
    }
  },
  created: function () {
    this.getUserLocation()
  },
  methods: {
    getUserLocation() {
      this.loading = true
       UserLocation.get()
          .then(result => {
            this.user_location = result.data.user_location
          })
          .catch(error => {

          }).finally(() => {
            this.loading = false
          })
    }
  }
}
</script>
<style></style>
