<template>
    <div v-loading.fullscreen.lock="loading">
       <h4 class="card-title">Inbox</h4>

       <div class=row>
        <div class="col-md-3">
          <el-input placeholder="Search mail" v-model="term" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="getMails()"></el-button>
          </el-input>
         </div>
       </div>
       <hr>
      
      <card style="">
        <el-table :data="mails"
          :row-class-name="tableRowClassName"
          class="font-13 table-striped" header-row-class-name="text-primary"
        >
<!-- 
    <el-table-column min-width="150" prop="sender"
                       label="Sender">
      </el-table-column> -->
    <el-table-column min-width="150" prop="subject" 
                     label="Subject">
                     <template slot-scope="scope">
                              {{ scope.row.preview_subject }}
                         </template>
    </el-table-column>
    <el-table-column min-width="200"
                     label="Message">
                     <template slot-scope="scope">
                            {{ scope.row.preview_message }}
                       </template>
    </el-table-column>
     
    <el-table-column min-width="150"
                     label="Date Created">
                     <template slot-scope="scope">
                          {{ createdAt(scope.row.created_at)  }}
                  
                     </template>
     </el-table-column>                
                     <el-table-column min-width="150"
                       label="">
                       <template slot-scope="scope">
                            <span v-if="scope.row.latest_action">
                              <el-tag :type="getAction(scope.row)">{{ "(" + scope.row.latest_action + ")" }}</el-tag>
                            
                            </span>
                       </template>
                    
    </el-table-column>
    <el-table-column
          fixed="right"
          width="120"
                     label="Actions"                  
                     class-name="action"
                     label-class-name="action">
                             <template slot-scope="scope">
                      <el-tooltip content="Open"
                      :open-delay="300"
                      placement="top">
                       
            <p-button  size="sm" icon @click="openMail(scope.row)">
             <i class="fa fa-solid fa-eye"></i>
            </p-button>
          </el-tooltip>
        </template>
    </el-table-column>
  </el-table>

  <p-pagination v-if="mails.length" class="pull-right"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      :total="pagination.total">
        </p-pagination>
        
    </card>

    </div>
  </template>
<script>

import PPagination from 'src/components/UIComponents/Pagination.vue';
import { Mail } from '@/resources/mail';
import TimeAgo from 'javascript-time-ago';

export default {
  components: {
    PPagination,
  },
  data() {
    return {
      mails: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      term: null,
      status: 1,
      loading: true,
      term: "",
      timeAgo: new TimeAgo('en-US')
    };
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getMails()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getMails()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getMails()
    }
  },
  created: function () {
    this.getMails()
  },
  methods: {
    getAction(mail) {
      if (mail.latest_action == 'Shredded') return 'danger'
      if (mail.latest_action == 'Opened') return 'success'
      if (mail.latest_action == 'Trashed') return 'warning'
      if (mail.latest_action == 'Forwarded') return 'info'
       return ""
    },
    openMail(mail) {
      this.$router.push({ name: 'ViewMail', params: { id: mail.id } })
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.status === 0) {
        return 'unread-row';
      } 
      return '';
    },
    createdAt(date) {
      return date ? this.$moment(date).format('YYYY/MM/DD') : ''
      // return date ? this.timeAgo.format(new Date(date)) : ''
    },
    getMails() {
      this.loading = true
      Mail.get(
        { params: { term: this.term, page: this.pagination.currentPage, per_page: this.pagination.perPage } }
      )
        .then(result => {
          this.mails = result.data.mails
          this.pagination = result.data.meta
        })
        .catch(error => {
          let message = error.response.data.message || 'Unable to save this time'
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message,
          });
        }).finally(() => {
          this.loading = false
        })
    }
  },
};
</script>
  <style lang="scss">
  .action {
    background: #fff;
  }
  .unread-row td {
    background: #F5F7FA;
  }
  </style>

