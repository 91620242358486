<template>
  <div v-if="user" class="row">
    <user-referral-commissions :client="user"></user-referral-commissions>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import UserReferralCommissions from '../UserReferrals/UserReferralCommissions.vue';

export default {
  components: { UserReferralCommissions },
  data() {
    return {
      loading: false,
    }
  },
  created: function () {
    // this.getUser(this.$route.params.id)
  },
  methods: {
   
  },
  computed: mapState(['user']),
}
</script>
<style lang="scss"></style>
