<template>
  <div>
    <h5 class="info-text">Select Plan</h5>
    <div class="row pricing">
      <div class="col-md-4" v-for="plan in plans" :key="plan.id" style="margin-bottom: 10px">
        <el-card class="box-card" :class="{ 'selected': selected && selected.id == plan.id }" >
          <h2>{{ plan.name }}</h2>
          <h5>${{ plan.price_per_month }} per month </h5>
          <h5 v-if="plan.price_per_year && plan.price_per_year > 0">${{ plan.price_per_year }} per year </h5>
          <hr>
          <div class="details">
              <div v-for="detail in plan.plan_details" :key="detail.id">
                {{ detail.description }}
              </div>
          </div>
          <div style="text-align:center" class="mt-5"> 
            <el-button type="success" @click="selectPlan(plan)" class="btn-block" :disabled="selected && selected.id == plan.id">Select</el-button>
          </div>
        </el-card>
      </div>
    </div>
      <hr>
      <div style="text-align:center" class=""> 
        <el-radio v-model="option" :label="0" >Monthly</el-radio>
        <el-radio v-model="option" :label="1">Yearly</el-radio>
      </div>
  </div>
</template>
<script>

import { Plan } from '@/resources/plan'

export default {
  data() {
    return {
      option: null,
      selected: null,
      plans: []
    };
  },
  created: function () {
    this.getPlans()
  },
  methods: {
    selectPlan(plan) {
      this.selected = Object.assign({}, plan)
    },
    validate() {
       if (!this.selected) {
        this.$notify({
          type: 'error',
          title: "Error",
          text: 'You have to select a plan',
        });
        return false
       }
      
      if (this.option == null) {
         this.$notify({
            type: 'error',
            title: "Error",
            text: 'Choose between monthly or yearly',
          });
        return false
      }
     
      this.selected.option = this.option
      this.$emit('on-validated', true, this.selected, 'plan')
      return true
    },
    getPlans() {
      Plan.get({ params: { status: 1, page: 1, per_page: 999 } })
        .then(result => {
          if (result.data.plans)
            this.plans = result.data.plans
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: "Error",
            text: 'Unable to fetch plans',
          });

        }).finally(() => {
        
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .selected {
    background: #ffcdbe;
    border: 1px solid #f0b4a2;
  }
</style>
