<template>
  <div>
     <el-drawer title="Attention" :visible.sync="showDelete" direction="rtl" :before-close="handleClose">
      <div style="padding: 10px;" v-if="selected">
        <p>Remove this mail? <el-link type="danger" @click="onDelete()" class="red-label">Yes, remove it!</el-link></p>
      </div>
    </el-drawer>

    <h4 class="card-title">Mails</h4>

    <div class="row" v-loading.fullscreen.lock="loading">
      <div class="col-md-3">
        <search-users  v-model="user" @update="setUser($event)" :passed="true" :has_plan="true"></search-users>
      </div>
    </div>
    <card style="">
      <el-table :data="mails" class="font-13 table-striped" header-row-class-name="text-primary">
        <el-table-column width="50" label="ID">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>
        <!-- <el-table-column min-width="150" prop="sender" label="Sender">
          </el-table-column> -->
          <el-table-column width="200" label="Recipient" prop="recipient">
              <template slot-scope="scope">
                {{ scope.row.recipient ? scope.row.recipient.name : '' }}
                <div style="font-size: 10px;" class="orange-text"> {{ scope.row.client ? '(' + scope.row.client.name + ')' : '' }}</div>
              </template>
            </el-table-column>
        <el-table-column min-width="150" prop="subject" label="Subject">
        </el-table-column>
        <el-table-column min-width="200" prop="message" label="Message">
          <template slot-scope="scope">
            <short-message :message="scope.row.message"></short-message>
          </template>
        </el-table-column>
        <!-- <el-table-column min-width="100" prop="attachments" label="Attachments">
          <template slot-scope="scope">
            <a v-for="(a, i) in scope.row.files" :key="i" @click="downloadAttachment(a, scope.row.id)">
              {{ a.file_name }}
            </a>
          </template>
        </el-table-column> -->
        <el-table-column width="120" label="Created by">
          <template slot-scope="scope">
            {{ scope.row.user ? scope.row.user.name : 'N/A' }}
          </template>
        </el-table-column>

        <el-table-column fixed="right" width="120" label="Actions" 
          class-name="action"
          label-class-name="action"
        >
          <template slot-scope="scope">
            <el-tooltip content="View" :open-delay="300" placement="top">
              <p-button type="info" size="sm" icon @click="openMail(scope.row)">
                <i class="nc-icon nc-alert-circle-i"></i>
              </p-button>
            </el-tooltip>
            <el-tooltip content="Delete" :open-delay="300" placement="top" v-allowed="'mails, delete'">
                <p-button type="danger" size="sm" icon class="ml-2" @click="onShowDelete(scope.row)">
                    <i class="fa fa-trash"></i>
                  </p-button>
              </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column min-width="100"  label="Date Created">
          <template slot-scope="scope">
              {{ scope.row.date_created }}
            </template>
          </el-table-column>

        <el-table-column width="180" label="Status">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status == 0">New</el-tag>
              <el-tag v-if="scope.row.status == 1" type="info">{{ scope.row.latest_action }}</el-tag>
              <el-tag v-if="scope.row.status == 2">{{ scope.row.latest_action }}</el-tag>
              <el-tag v-if="scope.row.status == 3">{{ scope.row.latest_action }}</el-tag>
              <el-tag v-if="scope.row.status == 4">{{ scope.row.latest_action }}</el-tag>
              <el-tag v-if="scope.row.status == 5">{{ scope.row.latest_action }}</el-tag>
            </template>
          </el-table-column>


      </el-table>

      <p-pagination v-if="mails.length" class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
        :total="pagination.total">
      </p-pagination>

    </card>

  </div>
</template>
<script>

import PPagination from 'src/components/UIComponents/Pagination.vue';
import { Mail } from '@/resources/mail';
import ShortMessage from '../../Dashboard/Views/Components/ShortMessage.vue';
import SearchUsers from '../../Dashboard/Views/Components/SearchUsers.vue';

export default {
  components: {
    PPagination,
    ShortMessage,
    SearchUsers,
  },
  data() {
    return {
      mails: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      term: null,
      status: 1,
      loading: true,
      term: "",
      selected: null,
      showDelete: null,
      user: null
    };
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getMails()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getMails()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getMails()
    }
  },
  created: function () {
    this.getMails()
  },
  methods: {
    setUser(user) {
      this.user = user
      this.getMails()
    },
    openMail(mail) {
      this.$router.push({ name: 'ViewMailPreview', params: { id: mail.id } })
    },
     handleClose() {
      this.showDelete = false
    },
    onShowDelete(mail) {
      this.showDelete = true
      this.selected = Object.assign({}, mail)
    },
    downloadAttachment(file, id) {
       const link = document.createElement("a");
      link.href = file.storage_path;
      link.download = file.file_name;
      link.target = '_blank'
      link.click();
    },
    onDelete() {
      this.loading = true
      Mail.delete({ id: this.selected.id})
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Mail has been removed successfully',
          });
          this.getMails()
        })
        .catch(error => {

        }).finally(() => {
          this.loading = false
        })
    },
    getMails() {
      this.handleClose()
      this.loading = true
      let params = { page: this.pagination.currentPage }
      if (this.user) params.user_id = this.user.id
      Mail.all({params: params})
        .then(result => {
          this.mails = result.data.mails
          this.pagination = result.data.meta
        })
        .catch(error => {
        
        }).finally(() => {
          this.loading = false
        })
    }
  },
};
</script>
<style lang="scss">

</style>

