<template>
  <div >
    <div v-if="meeting_room && meeting_room.pictures">
      <el-carousel :interval="3000" arrow="always">
        <el-carousel-item v-for="item, i in meeting_room.pictures.items" :key="i">
          <el-image 
              style="width: 100%; height: 100%"
              :src="item.link" 
              fit="contain"
              :preview-src-list="getPictures()">
            </el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>

export default {
  props: ['meeting_room'],
  methods: {
    getPictures() {
      let list = []

      this.meeting_room.pictures.items.forEach(picture => {
        list.push(picture.link)
      });

      return list
    }
  }
}
</script>
<style lang="scss"></style>
