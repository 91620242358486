<template>
  <div>
    <div class="row d-flex justify-content-center" v-loading="loading">
      <div class="col-md-10 mr-auto ml-auto">
        <wizard @complete="wizardComplete"  title="" subTitle="">
          <!-- <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="nc-icon nc-badge"></i>
            </template>
            <company-info ref="step1" @on-validated="onStepValidated"></company-info>
          </wizard-tab> -->

          <!-- <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="nc-icon nc-tap-01"></i>
            </template>
            <select-plan ref="step2" @on-validated="onStepValidated"></select-plan>
          </wizard-tab> -->

          <!-- <wizard-tab :before-change="() => validateStep('step3')">
              <template slot="label">
                <i class="nc-icon nc-pin-3"></i>
              </template>
              <select-office ref="step3" @on-validated="onStepValidated"></select-office>
            </wizard-tab> -->

            <!-- <wizard-tab :before-change="() => validateStep('step4')">
                <template slot="label">
                  <i class="nc-icon nc-credit-card"></i>
                </template>
                <add-payment-method ref="step4" @on-validated="onStepValidated"></add-payment-method>
              </wizard-tab> -->

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="nc-icon nc-zoom-split"></i>
            </template>
            <review-plan :company="company" :plan="plan" ref="step1" @on-validated="onStepValidated"></review-plan>
          </wizard-tab>
        </wizard>
      </div>
    </div>
  </div>
</template>
<script>

import Swal from 'sweetalert2'
import { Wizard, WizardTab } from 'src/components/UIComponents'
import CompanyInfo from './CompanyInfo.vue'
import SelectPlan from './SelectPlan.vue'
import ReviewPlan from './ReviewPlan.vue'
import { User } from '@/resources/user'
import { Authenticate } from '@/resources/authenticate'
import SelectOffice from './SelectOffice.vue'
import AddPaymentMethod from './AddPaymentMethod.vue'
import { mapState } from 'vuex';

export default {
  data() {
    return {
      wizardModel: {},
      company: null,
      plan: null,
      payment_method: null,
      office: null,
      loading: false
    }
  },
  computed: {
    ...mapState(['user'])
  },
  components: {
    Wizard,
    WizardTab,
    CompanyInfo,
    SelectPlan,
    ReviewPlan,
    SelectOffice,
    AddPaymentMethod
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate()
    },
    onStepValidated(validated, model, type) {
      if (validated) {
        if (type == 'company') this.company = Object.assign({}, model)
        if (type == 'plan') this.plan = Object.assign({}, model)
        if (type == 'office') this.office = Object.assign({}, model)
        if (type == 'payment_method') this.payment_method = Object.assign({}, model)

         this.wizardModel = { ...this.wizardModel, ...model }
      }
     
    },
    async success() {
      const { value: res } = await Swal.fire({
        title: "Congratulations!",
        text: "Click ok to redirect to your dashboard",
        icon: "success",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      })

      this.$router.push({ name: 'Home', query: { refresh: true } })
    },
    getUserFromApi() {
      this.loading = true
      let self = this
      Authenticate.getUser(function (res) {
        self.loading = false
        self.success()
       })
    },
    wizardComplete() {
      if (this.plan) {
        this.company.plan_id = this.plan.id
        this.company.option = this.plan.option
      }
      if (this.office) this.company.office_id = this.office.id
    
      // this.company.payment_method = this.payment_method
      // let price = this.plan.option == 1 ? this.plan.price_per_year+ '(1 year)': this.plan.price_per_month+'(1 month)'

      // Swal.fire({
      //   // text: 'You will be charged $'+ price + '. Proceed?' ,
      //   text: 'All informations you have provided are correct. Proceed?',
      //   showCancelButton: true,
      //   confirmButtonText: 'Submit',
      //   title: "Attention",
      //   icon: 'warning',
      //   showCancelButton: true,
      //   confirmButtonColor: '#ff733d',
      //   cancelButtonColor: '#c3c3c3',
      //   confirmButtonText: 'Yes'
      // }).then((result) => {
      //   /* Read more about isConfirmed, isDenied below */
      //   if (result.isConfirmed) {
      //    this.onSubmit()
      //   }
      // })

      this.onSubmit()
   
    },
    onSubmit() {
      this.loading = true
      if (!this.company)this.company = {name: this.user.name }
      User.setupPlan(this.company)
        .then(result => {
          this.getUserFromApi()
        })
        .catch(() => {
          // this.$notify({
          //   type: 'danger',
          //   title: "Error",
          //   text: 'Unable to set your plan at the moment',
          // });
            this.loading = false
        }).finally(() => {
        
        })
    }
  }
}
</script>


<style lang="scss" scoped>
.swal2-title {
  font-size: 17px !important;
    margin-top: 20px !important;
}
</style>

