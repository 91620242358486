<template>
  <ValidationObserver v-slot="{ handleSubmit }" v-loading.fullscreen.lock="loading">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Mail Response
          </h4>
          <p> {{ getRequest() }}</p>
          <hr>
          <p>Mail Info</p>
          <!-- <p>Sender: {{ mailAction.mail.sender }}</p> -->
          <p>Recipient: {{ mailAction.mail.recipient ? mailAction.mail.recipient.name : '' }}</p>
          <hr>
        </div>
        <div class="card-body">
          <div class="form-group" v-if="action == 'open'">
            <file-upload @change="setFiles($event)" :multiple="false"></file-upload>
            <div class="form-group">
              <label>Pages</label>
              <el-input type="number" v-model="open_form.pages"></el-input>
            </div>
             <div class="form-group">
                <label>Price to pay by the client ($2.25 for first 5 pages and .5 per page excess)</label>
                <el-input type="number" v-model="open_form.price" step=".01"></el-input>
              </div>
          </div>
          <div class="form-group" v-if="action == 'forward'">
              <p>Forward to: {{ mailAction.forward_to }}</p>
              <p>Message: {{ mailAction.forward_message }}</p>
              <p>Notes: {{ mailAction.note }}</p>

              <label>Price to pay by the client (Forwarding fee)</label>
                <el-input type="number" v-model="mailAction.price" step=".01"></el-input>
         
              <hr>
              <el-alert
                  title="Make sure that the mail has been forwarded already"
                  type="warning"
                  show-icon>
                </el-alert>
            </div>
          </div>
          <div class="form-group" v-if="action == 'trash'">
            <el-alert
                    title="Make sure that the mail has been junked already"
                    type="warning"
                    show-icon>
                  </el-alert>
            </div>
            <div class="form-group" v-if="action == 'shred'">
          
              <div class="form-group">
                <label>Pages</label>
                <el-input type="number" v-model="mailAction.pages"></el-input>
              </div>
               <div class="form-group">
                  <label>Price to pay by the client ($2.25 for first 5 pages and .5 per page excess)</label>
                  <el-input type="number" v-model="mailAction.price" step=".01"></el-input>
                </div>
                  <el-alert
                        title="Make sure to shred the mail"
                        type="warning"
                        show-icon>
                      </el-alert>
              </div>

        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Submit</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import FileUpload from '../../Dashboard/Views/Components/FileUpload.vue';
import { MailAction } from '@/resources/mail_action';
import { MessageBox } from 'element-ui';

extend("email", email);
extend("required", required);

export default {
  components: { FileUpload },
  props: {
    mailAction: {
      type: Object,
      default: null
    },
    action: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      form: {
        sender: "",
        subject: "",
        message: "",
        company_id: null
      },
      open_form: {
        price: 1,
        pages: 1
      },
      loading: false,
      files: []
    };
  },
  watch: {
    'open_form.pages': function () {
      let price = 2.25
      let remaining_pages = this.open_form.pages - 5
      price += remaining_pages > 0 ? remaining_pages * .5 : 0
      this.open_form.price = price
    },
    'mailAction.pages': function () {
      let price = 2.25
      let remaining_pages = this.mailAction.pages - 5
      price += remaining_pages > 0 ? remaining_pages * .5 : 0
      this.mailAction.price = price
    }
  },
  methods: {
    getRequest() {
      if (this.action == 'open') return "Request to open and scan the mail"
      if (this.action == 'forward') return "Request to forward the mail"
      if (this.action == 'trash') return "Request to junk the mail"
      if (this.action == 'shred') return "Request to shred the mail"
    },
    setFiles(files) {
      this.files = files
    },
    close(updated) {
      this.$emit('update',updated)
    },
    submit() {
      let charge = this.mailAction.price
      if (this.action == 'open') charge = this.open_form.price
      let message = "Client will be charged $" + charge + ". Proceed?"
      if (charge <= 0) message = "Client will not be charge. Proceed?"

      MessageBox.confirm(message)
        .then(_ => {
          this.onResponse();
        })
        .catch(_ => { });
    },
    onResponse() {
    
      this.loading = true

      MailAction.response(this.mailAction.mail.id, this.getFormData())
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: "Mail has been updated",
          });
          this.close(true)
        })
        .catch(error => {
          let message = error.response.data.message || 'Unable to save this time'
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message,
          });
        }).finally(() => {
          this.loading = false
        })
    },
    getForwardForm() {
      var formData = new FormData()
      formData.append('action', this.mailAction.action)
      formData.append('price', this.mailAction.price)
      return formData
    },
    getFormData() {
      if (this.action == 'forward') return this.getForwardForm()
      var formData = new FormData()
      formData.append('action', this.mailAction.action)
      this.files.forEach(file => {
        if (file.raw) formData.append('files[]', file.raw)
      });

      if (this.action == 'open') {
        formData.append('pages', this.open_form.pages)
        formData.append('price', this.open_form.price)
      }

      if (this.action == 'shred') {
        formData.append('pages', this.mailAction.pages)
        formData.append('price', this.mailAction.price)
      }

      return formData
    }
  }
}
</script>
<style></style>
