<template>
  <div>
    <div class="form-group">
      <label>Roles</label>
      <el-tag :key="role" v-for="role in roles" closable :disable-transitions="false" @close="handleClose(role)">
        {{ role }}
      </el-tag>
      <el-input class="input-new-tag" v-model="inputValue" ref="saveTagInput" size="mini"
        @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
      </el-input>
    </div>
    <el-button @click="submit()" type="success">Save</el-button>
  </div>
</template>
<script>
import { User } from '@/resources/user'

export default {
  props: ['user'],
  data() {
    return {
      roles: [],
      form: {
        password: "",
        email: "",
        name: "",
        company_id: null
      },
      loading: false,
      inputValue: ''
    };
  },
  created: function () {
    this.form = Object.assign({}, this.user)
    if (this.form) this.roles = this.form.role_names
  },
  methods: {
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.roles.push(inputValue);
      }
      this.inputValue = '';
    },
    handleClose(tag) {
      this.roles.splice(this.roles.indexOf(tag), 1);
    },
    submit() {
      this.loading = true
      this.form.roles = this.roles
      let form = {
        id: this.form.id,
        roles:  this.roles
      }
      User.update(form)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Roles has been updated',
          });
        })
        .catch(error => {
          
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style></style>
