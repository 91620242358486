var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.getTitle())+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Name")]),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The name field is required' : null,"hasSuccess":passed,"name":"name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Rate per Hour")]),_c('ValidationProvider',{attrs:{"name":"price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"number","error":failed ? 'The rate field is required' : null,"hasSuccess":passed,"name":"price"},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Office")]),_c('ValidationProvider',{attrs:{"name":"office","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('el-select',{staticClass:"select-primary",class:passed ? 'is-valid':'',attrs:{"filterable":"","name":"country"},model:{value:(_vm.form.office),callback:function ($$v) {_vm.$set(_vm.form, "office", $$v)},expression:"form.office"}},_vm._l((_vm.offices),function(office){return _c('el-option',{key:office.id,attrs:{"label":office.name,"value":office}})}),1),(failed)?_c('small',{staticClass:"text-danger",attrs:{"id":"passwordHelp"}},[_vm._v("The Office field is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Status")]),_c('ValidationProvider',{attrs:{"name":"code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
return [_c('el-select',{staticClass:"select-primary",attrs:{"name":"country"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},_vm._l((['Inactive','Active']),function(status,i){return _c('el-option',{key:i,attrs:{"label":status,"value":i}})}),1),(failed)?_c('small',{staticClass:"text-danger",attrs:{"id":"passwordHelp"}},[_vm._v("The Office field is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Capacity")]),_c('ValidationProvider',{attrs:{"name":"price_per_month"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"step":"1","type":"number","error":failed ? 'The price per month field is required' : null,"hasSuccess":passed,"name":"price_per_month"},model:{value:(_vm.form.capacity),callback:function ($$v) {_vm.$set(_vm.form, "capacity", $$v)},expression:"form.capacity"}})]}}],null,true)})],1)]),_c('div',{staticClass:"card-footer text-right"},[_c('p-button',{attrs:{"type":"primary","native-type":"submit"}},[_vm._v("Save")])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }