<template>
  <div class="form-group" :class="[
    { 'has-danger': error },
    { 'has-success': passed }]">
    <slot>
      <el-autocomplete v-model="state" :fetch-suggestions="querySearchAsync" placeholder="Search clients" :clearable="true"
        @select="handleSelect" @change="handleChange" style="width: 100%">
        <template slot-scope="{ item }">
          <div class="value">{{ item.value }}</div>
        </template>
      </el-autocomplete>
    </slot>

    <slot name="helpBlock">
      <div class="invalid-feedback error-text">
        {{ error }}
      </div>
    </slot>
  </div>
</template>
<script>


import { User } from '@/resources/user';

export default {
  props: {
    passed: {
      type: Boolean,
      description: 'Whether form is passed',
      default: false
    },
    error: {
      type: String,
      description: 'Error message',
      default: null
    },
    user: {
      type: Object,
      description: 'User',
      default: null
    },
    roles: {
      type: Array,
      description: 'Role names',
      default: () => ['client']
    },
    has_plan: {
      type: Boolean,
      description: 'has plan',
      default: null
    },
  },
  data() {
    return {
      links: [],
      state: '',
      timeout: null
    };
  },
  created: function () {
    if (this.user) this.state = this.user.email
  },
  methods: {
    loadAll() {
      return [];
    },
    querySearchAsync(queryString, cb) {
      User.get({ params: { term: queryString, per_page: 5, roles: this.roles, has_plan: this.has_plan, simple: true } })
        .then(result => {
          let items = []
          result.data.users.forEach(element => {
            items.push({ value: element.email, id: element.id })
          });
          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            cb(items);
          }, 1000 * Math.random());

        })
        .catch(error => {
          let message = error.response.data.message || 'Unable to fetch users at the moment'
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message,
          });
        })
    },
    createFilter(queryString) {
      return (link) => {
        return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item) {
      this.$emit('update', item)
    },
    handleChange(item) {
      this.$emit('update', null)
    }
  },
  mounted() {
    this.links = this.loadAll();
  }
};
</script>

<style scoped>
.error-text {
  display: block;
}
</style>