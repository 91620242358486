<template>
  <div>
    <el-table class="table-striped"  header-row-class-name="text-primary"
                      :data="transactions"
                      style="width: 100%">
                      <el-table-column  class-name="td-actions" label="Paid on ">
              <template slot-scope="props">
                <div>
                  {{ props.row.paid_on }}
                  <!-- {{  $moment.unix(props.row.created).format('YYYY-MM-DD HH:mm:ss')  }} -->
                </div>
              </template>
            </el-table-column>
          <el-table-column  class-name="td-actions" label="Amount">
            <template slot-scope="props">
              <div>
                ${{ props.row.amount }}
              </div>
            </template>
          </el-table-column>
          <el-table-column  class-name="td-actions" label="Description">
              <template slot-scope="props">
                <div>
                  {{ props.row.description }}
                </div>
              </template>
            </el-table-column>
      </el-table>
  </div>
</template>
<script>

import { User } from '@/resources/user'

export default {
  props: ['user'],
  data() {
    return {
      loading: true,
      transactions: null
    }
  },
  watch: {
    user: function () {
      this.getTransactions()
    }
  },
  created: function () {
    this.getTransactions()
  },
  methods: {
    getTransactions() {
      User.getTransactions(this.user.id)
        .then(result => {
          this.transactions = result.data.transactions
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
  }
}
</script>
<style lang="scss"></style>
