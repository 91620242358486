<template>
  <div>
    <h5 v-if="title">{{ title }}</h5>

    <p>Please read these Terms and Conditions and Agreement for Credit Card Payment carefully before proceeding with your reservation and payment. By providing your credit card information and completing the payment process, you acknowledge and agree to be bound by these terms.
  </p>
  <br>
  <div><strong>Services Offered:</strong></div>
  <div>1.1. The virtual office business, hereinafter referred to as "the Company," offers a range of services, including but not limited to meeting room rentals, coworking space rentals, virtual address services, live receptionist services, and client directory access (collectively referred to as "Services").
  </div>
  <div>
    1.2. The specific details of the selected Services, including rates, durations, and any additional terms, will be outlined in the corresponding agreement and invoice.

  </div>
  <br>
    <div><strong>Automatic Renewal and Cancellation:</strong></div>
    <div>2.1. The Agreement will automatically renew for successive terms of the same duration unless canceled by either party.
  </div>
  <div>2.2. If the client wishes to cancel the Agreement, a written notice must be provided to the Company at least 3 days prior to the renewal date.
  </div>
  <div>2.3. Failure to provide the required cancellation notice may result in the automatic renewal and corresponding charges for the subsequent term.
  </div>
   <br>
      <div><strong>Transferability and Non-Renewal:</strong></div>
      <div>3.1. The transferability of the rental agreement to another party is subject to the approval of the management. Requests for transfer must be submitted in writing for consideration.</div>
      <div>  3.2. The Company reserves the right not to renew any agreement and will provide notice to the client via email or through the login website page.</div>
      <br>
      <div><strong>Business Competition and Data Protection:</strong></div>
      <div>  4.1. The client agrees not to carry on any business activities that directly compete with the services provided by the Company during the term of the agreement.
  </div>
  <div>  4.2. The Company and the client shall comply with applicable data protection laws and regulations regarding the storage, use, and processing of personal data.
  </div>
  <br>
        <div><strong>Confidentiality and Privacy:</strong></div>
        <div>  5.1. The client acknowledges and agrees to maintain strict confidentiality with respect to any confidential information disclosed by the Company during the term of the agreement.
  </div>
  <div>  5.2. The Company assures the client that credit card information and any personal information provided will not be sold or disclosed to any third party without the client's explicit consent, except as required by law.
  </div>
  <br>
  <div><strong>Limitation of Liability and Insurance:</strong></div>
  <div>  6.1. The Company shall not be liable for any loss or damage suffered by the client arising out of or in connection with the agreement or the use of the Services.
  </div>
  <div>
      6.2. The client is responsible for obtaining appropriate insurance coverage to protect their own property and assets.

  </div>
  <br>
    <div><strong>Taxes, Duty Charges, and Additional Fees:</strong></div>
    <div>  7.1. The client is responsible for any applicable taxes and duty charges that may arise from the agreement or the provision of the Services.
  </div>
    <div>  7.2. The Company reserves the right to charge the client an additional 20% administration fee for any additional fees incurred, such as cleaning, trash removal, or other services requested by the client.
  </div>
  <br>
      <div><strong>Returned or Declined Payments and Late Payments:</strong></div>
    <div>  8.1. The client agrees to be responsible for any fees or charges incurred due to returned or declined payments, including bank fees or processing fees.
  </div>
  <div>  8.2. Late payments may be subject to late payment fees as specified in the invoice or agreement.
</div>
  <br>
        <div><strong>Governing Law and Jurisdiction:</strong></div>
        <div>  9.1. These Terms and Conditions and Agreement for Credit Card Payment shall be governed by and construed in accordance with the laws of Nevada. Any disputes arising from or relating to these terms shall be subject to the exclusive jurisdiction of the courts of Nevada. 
  </div>
  <p>

  By proceeding with the payment, you acknowledge that you have carefully reviewed and understood all the terms and conditions stated above. You agree to be legally bound by these terms, and you accept the responsibilities and obligations outlined therein.

  Furthermore, you understand that these terms and conditions may be subject to updates or modifications from time to time. It is your responsibility to review any changes and ensure compliance with the most current version of the agreement.

  In the event of any dispute or disagreement arising from or related to these terms and conditions, both parties agree to engage in good-faith discussions and, if necessary, seek resolution through mediation or arbitration, as outlined by applicable laws and regulations.

  Please note that if you proceed with the payment, you affirm that you have the necessary authority to enter into this agreement on behalf of yourself or the entity you represent.

  We appreciate your trust and support as our valued client. Should you have any questions or concerns, please reach out to our customer support team, who will be glad to assist you.
  </p>
  <p>
      Thank you for choosing Biz-Depot.
  </p>
      <br><br>
    <el-checkbox v-model="accept" border>I accept the terms and conditions</el-checkbox>
  </div>
</template>
<script>

export default {
  props: ['title'],
  data() {
    return {
      accept: null
    }
  },
  watch: {
    accept: function () {
      this.$emit('accept', this.accept)
    }
  },
  created: function () {

  }
}

</script>
<style></style>
