import api from './api'

const API = '/api/users/companies'

export const UserCompany = {
	setAsCurrent: function(params) {
    return api.create(API+'/set_as_current', params)
	},
  get: function(params) {
    return api.get(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
