import api from './api'

const API = '/api/mails/actions'

export const MailAction = {
  open: function(params) {
    return api.get(API+'/open', params)
  },
  forward: function(params) {
    return api.get(API+'/forward', params)
  },
  trash: function(params) {
    return api.get(API+'/trash', params)
  },
  shred: function(params) {
    return api.get(API+'/shred', params)
  },
  response: function (id, formData) {
    return api.createFormData(API+'/'+id+'/response', formData)
  },
  all: function(params) {
    return api.get(API+'/all', params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, {params: params})
  },
  
  updateCompany: function(params) {
    return api.update(API+'/'+params.id+'/update-company', params)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
