<template>
  <div class="row">
    <div class="col-md-12">
      <el-card class="mt-3">
        <el-select v-model="year">
          <el-option
          v-for="item in years"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
         <el-table class="table-striped"  header-row-class-name="text-primary"
              :data="months"
              style="width: 100%">
              <el-table-column label="Month">
                <template slot-scope="props" >
                  <div>
                    <p>{{ props.row.month_name }}</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Amount">
                  <template slot-scope="props">
                    <div>
                      <p>${{ props.row.total }}</p>
                    </div>
                  </template>
                </el-table-column>
          </el-table>
      </el-card>
    </div>
  </div>
</template>
<script>

import { UserReferral } from '@/resources/user_referral'

import Vue from 'vue'
import { Table, TableColumn, Select, Option, Drawer } from 'element-ui'
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(Drawer)

export default {
  props: ['code'],
  components: {
  },
  data() {
    return {
      total: 0,
      year: null,
      years: [],
      months: [],
      loading: false,
      months_label: ['','January','February','']
    }
  },
  watch: {
    year: function () {
      this.getData()
    }
  },
  created: function () {
    let year = this.$moment().format('y')
    this.years = [year, year - 1, year - 2, year - 3, year - 4, year - 5, year - 6, year - 7, year - 8, year - 9, year - 10]
    this.year = year;
  },
  methods: {
    getData() {
      this.loading = true
      UserReferral.getMonthylCollections({ code: this.code, year: this.year })
        .then(result => {
          this.months = result.data.months
          this.months.push({
            total: result.data.total,
            month_name: 'Total'
          })
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
