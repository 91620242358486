<template>
  <ValidationObserver v-slot="{ handleSubmit }" v-loading="loading">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ form.id ? 'Update Location' : 'Setup your address' }}
          </h4>
          <small>To facilitate the forwarding of your mail, kindly complete this form</small><br>
          <hr>
          <small>Note: You can only edit this form one time</small>
        </div>
        <div class="card-body">
          <address-form label="Primary Address" :address="form.primary_address" @on-validated="setPrimaryAddress" ref="primaryAddressRef"></address-form>

          <el-checkbox v-model="use_primary" >Use Primary as Secondary address</el-checkbox>
          <address-form v-if="!use_primary" label="Secondary Address" :address="form.secondary_address" @on-validated="setSecondaryAddress" ref="secondaryAddressRef"></address-form>
        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Save</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>

import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { UserLocation } from '@/resources/user_location'
import AddressForm from '../Address/AddressForm.vue';

extend("required", required);

export default {
  props: ['user_location'],
  components: { AddressForm},
  data() {
    return {
      form: {
        name: "",
        description: null,
        status: 0,
        primary_address: {},
        secondary_address: {}
      },
      new_detail: {
        description: null
      },
      loading: false,
      use_primary: false
    };
  },
  watch: {
    use_primary: function () {
      if (this.use_primary) {
        this.form.secondary_address = Object.assign({}, this.form.primary_address)
      }
    }
  },
  created: function () {
    if (this.user_location) this.form = Object.assign({}, this.user_location)
    if (!this.form.id) {
      this.form.primary_address = {}
      this.form.secondary_address = {}
    }
  },
  watch: {
    user_location() {
      if (this.user_location) this.form = Object.assign({}, this.user_location)
      if (!this.form.id) {
        this.form.primary_address = {}
        this.form.secondary_address = {}
      }
    }
  },
  methods: {
    setPrimaryAddress(validated, address) {
      if (validated) {
        this.form.primary_address = Object.assign({}, address)
      }
    },
    setSecondaryAddress(validated, address) {
      if (validated) {
        this.form.secondary_address = Object.assign({}, address)
      }
    },
    reset() {
      this.form = {
        primary_address: {},
        secondary_address: {}
      }
    },
    submit() {
      this.loading = true
      this.setPrimaryAddress(true, this.$refs['primaryAddressRef'].form)
     
      if (this.use_primary) {
        this.form.secondary_address = Object.assign({}, this.form.primary_address)
      } else {
         this.setSecondaryAddress(true, this.$refs['secondaryAddressRef'].form)
      }

      console.log(this.form)

      let request = this.form.id ? UserLocation.update(this.form) : UserLocation.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Address has been saved',
          });
          this.reset()
          this.$emit('update', result.data.user_location)
        })
        .catch(error => {
          
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style></style>
