<template>
  <div v-loading.fullscreen.lock="loading">
    <el-drawer title="Select A MailBox Plan" :visible.sync="showPlans" direction="rtl" :before-close="handleClose" size="100%"
      :show-close="false"
      :close-on-press-escape="false"
      :append-to-body="true"
    >
      <div class="row" style="padding: 10px">

        <div class="col-md-3" v-for="plan in plans" :key="plan.id">
          <el-card>
            <el-descriptions class="margin-top" :title="plan.name" :column="1" :colon="false">
              <el-descriptions-item label="Price per month">$ {{ plan.price_per_month }}</el-descriptions-item>
              <el-descriptions-item label="Price per year">$ {{ plan.price_per_year }}</el-descriptions-item>
              <el-descriptions-item label="-" v-for="detail in plan.plan_details" :key="detail.id">{{ detail.description
              }}</el-descriptions-item>
            </el-descriptions>
            <el-button type="success" @click="selectPlan(plan)">Select {{ plan.name }}</el-button>
          </el-card>
        </div>
      </div>
    </el-drawer>


    <el-drawer title="Select Your Mail Box Location" :visible.sync="showOffices" direction="rtl" :before-close="handleClose" size="100%"
        :show-close="false"
        :close-on-press-escape="false"
        :append-to-body="true"
      >
        <div class="row" style="padding: 10px">

          <div class="col-md-3" v-for="office in offices" :key="office.id">
            <el-card>
              <el-descriptions class="margin-top" :title="office.name" :column="1" :colon="false">
                <el-descriptions-item label="Address"> {{ office.address.full_address }}</el-descriptions-item>
                
              </el-descriptions>
              <el-button type="success" @click="selectOffice(office)">Select {{ office.name }}</el-button>
            </el-card>
          </div>
        </div>
      </el-drawer>


    <el-dialog title="Payment Summary" :visible.sync="showPay" width="30%" :before-close="handleClose">
      <pay-subscription :subscription="new_subscription" @paid="success()"></pay-subscription>
    </el-dialog>

    <div class="row">
      <div class="col-md-4" v-if="!new_subscription">
        <el-button type="warning" plain @click="onShowPlans()">Select a Plan</el-button>
      </div>
      <div class="col-md-4">
        <el-card class="box-card" v-if="new_subscription">
          <div>New Subscription</div>
          <div style="font-size: 30px">{{ new_subscription.name }}</div>
          <hr>

          <el-descriptions class="margin-top" title="Information" :column="1">
            <el-descriptions-item label="Start">{{ dateFormat(new_subscription.start) }}</el-descriptions-item>
            <el-descriptions-item label="Expiration">{{ dateFormat(new_subscription.end) }}</el-descriptions-item>
            <!-- <el-descriptions-item label="Auto Renew">{{ new_subscription.auto_renew }}</el-descriptions-item> -->
            <el-descriptions-item label="Price">{{ new_subscription.price }}</el-descriptions-item>
          </el-descriptions>

          <el-radio v-model="new_subscription.option" :label="0">Monthly</el-radio>
          <el-radio v-model="new_subscription.option" :label="1">Yearly</el-radio>
          <hr>
          <div v-if="selected_office">
            <p>Mailbox Location: {{ selected_office.address.full_address }}</p>
          </div>
          <el-button type="warning" plain @click="onShowPlans()">Change Plan</el-button>
          <hr>
          <el-button type="success" plain @click="onShowPay()">Proceed to Payment</el-button>
        </el-card>

      </div>

    </div>
    <div class="row">
      <div class="col-md-6">

      </div>
    </div>
  </div>
</template>
<script>

import { Plan } from '@/resources/plan'
import PaySubscription from './PaySubscription.vue'
import Swal from 'sweetalert2'
import { mapState } from 'vuex';
import { Authenticate } from '@/resources/authenticate'
import { Office } from '@/resources/office'

export default {
  computed: mapState(['user']),
  components: { PaySubscription },
  data() {
    return {
      plans: [],
      offices: [],
      loading: false,
      subscription: {},
      new_subscription: null,
      no_plan: false,
      selected_plan: null,
      selected_office: null,
      showPlans: true,
      showPay: false,
      showOffices: false
    }
  },
  watch: {
    'new_subscription.option': {
      handler: function (newVal) {
        this.new_subscription.price = this.new_subscription.option == 0 ?
          '$ ' + this.new_subscription.price_per_month + '/Month'
          : '$ ' + this.new_subscription.price_per_year + '/Year'

        this.new_subscription.amount = this.new_subscription.option == 0 ?
          this.new_subscription.price_per_month
          : this.new_subscription.price_per_year

        let end = this.$moment()
        this.new_subscription.end = this.new_subscription.option == 0 ? end.add(1, 'M') : end.add(1, 'Y');
        if (this.selected_plan.setup_fee) this.new_subscription.price += "+ $" + this.selected_plan.setup_fee + " setup fee"
      },
      deep: true
    }
  },
  created: function () {

    let plan = this.$store.state.user?.company?.plan

    if (plan) {
      this.$router.push({ name: 'Home' })
      return false
    }

    this.getUser()
    this.getPlans()
    let company_plan = this.$store.state.user?.company?.company_plan
    if (!plan) {
      this.no_plan = true
      return false
    }
    this.selected_plan = Object.assign({}, plan)
    this.subscription = this.$store.state.user?.company?.subscription
    this.updateNewPlan()
    
  },
  methods: {
    getUser() {
      let self = this
      this.loading = true
      Authenticate.getUser(function (res) {
        self.loading = false
      })
    },
    onShowPay() {
      this.showPay = true
    },
    dateFormat(date) {
      return this.$moment(date).format('MMM DD, YYYY')
    },
    updateNewPlan() {
      let start = this.$moment()
      let end = this.$moment()

      let oldSubscription = this.$store.state.user?.company?.subscription

      this.new_subscription = {
        id: this.selected_plan.id,
        name: this.selected_plan.name,
        start: start,
        end: end,
        auto_renew: 'no',
        price: 0,
        price_per_month: this.selected_plan.price_per_month,
        price_per_year: this.selected_plan.price_per_year,
        option: 0,
        amount: 0,
        setup_fee: this.selected_plan.setup_fee,
        type: oldSubscription ? 'renew' : 'new',
        description: oldSubscription ? 'Renew Subscription' : 'New Subscription',
        office_id: this.selected_office.id,
        promo: oldSubscription ? oldSubscription.promo : null
      }

      this.new_subscription.price = this.new_subscription.option == 0 ?
        '$ ' + this.new_subscription.price_per_month + '/Month'
        : '$ ' + this.new_subscription.price_per_year + '/Year'

      this.new_subscription.amount = this.new_subscription.option == 0 ?
        this.new_subscription.price_per_month
        : this.new_subscription.price_per_year

      this.new_subscription.end = this.new_subscription.option == 0 ? end.add(1, 'M') : end.add(1, 'Y');
      if (this.selected_plan.setup_fee) this.new_subscription.price += "+ $" + this.selected_plan.setup_fee + " setup fee"
    },
    handleClose() {
      this.showPlans = false
      this.showPay = false
    },
    selectPlan(plan) {
      this.selected_plan = Object.assign({}, plan)
      this.showPlans = false
      this.showOffices = true
    },
    selectOffice(office) {
      this.selected_office = Object.assign({}, office)
      this.showOffices = false
      this.updateNewPlan()
    },
    onShowPlans() {
      this.showPlans = true
    },
    getExpiration() {
      return this.$moment(this.subscription.expiration).format('MMM DD, YYYY')
    },
    getStarted() {
      return this.$moment(this.subscription.start).format('MMM DD, YYYY')
    },
    getPlans() {
      Plan.get({ params: { status: 1, page: 1, per_page: 999 } })
        .then(result => {
          if (result.data.plans)
            this.plans = result.data.plans

          this.getOffices()
        })
        .catch(() => {

        }).finally(() => {

        })
    },
    getOffices() {
      Office.get({ params: { status: 1, page: 1, per_page: 999} })
        .then(result => {
          if (result.data.offices)
            this.offices = result.data.offices
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
    async success() {
      this.handleClose()
      let html = "<p>Thank you for proceeding with the payment. By completing the payment process," +
        "you confirm that you have read, understood, and agreed to be bound by the Terms and Conditions" +
        "and Agreement for Credit Card Payment outlined above.<p>" +
        "<p>If you have any questions or require further clarification, please contact our customer support team. We value your business and look forward to providing you with exceptional services.</p>" +
        "<p>Thank you for choosing Biz-Depot."

      const { value: res } = await Swal.fire({
        title: "Thank you for subscribing",
        html: html,
        icon: "success",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      })

      this.$router.push({ name: 'Home', query: { refresh: true } })
    },
  }
}
</script>
<style lang="scss"></style>
