<template>
  <div v-loading.fullscreen.lock="loading">
    <el-drawer title="Select A MailBox Plan" :visible.sync="showPlans" direction="rtl" :before-close="handleClose"
      size="100%" :show-close="false" :close-on-press-escape="false" :append-to-body="true">
      <div class="row" style="padding: 10px">
        
        <div class="col-md-3" v-for="plan in plans" :key="plan.id">
          <el-card>
            <el-descriptions class="margin-top" :title="plan.name" :column="1" :colon="false">
              <el-descriptions-item label="Price per month">$ {{ plan.price_per_month }}</el-descriptions-item>
              <el-descriptions-item label="Price per year">$ {{ plan.price_per_year }}</el-descriptions-item>
              <el-descriptions-item label="-" v-for="detail in plan.plan_details" :key="detail.id">{{ detail.description
              }}</el-descriptions-item>
            </el-descriptions>
            <el-button type="success" @click="selectPlan(plan)">Select {{ plan.name }}</el-button>
          </el-card>
        </div>
      </div>
    </el-drawer>


    <el-dialog title="Pay Upgrade" :visible.sync="showPay" width="30%" :before-close="handleClose">
      <pay-subscription :subscription="new_subscription" @paid="success()"></pay-subscription>
    </el-dialog>

    <div class="row">
      <div class="col-md-3" v-if="subscription">
        <el-card class="box-card">
            <div>Current Plan</div>
            <div style="font-size: 30px">{{ subscription.name }}</div>
            <hr>

            <el-descriptions class="margin-top" title="Information" :column="1">
              <el-descriptions-item label="Start">{{ dateFormat(subscription.start) }}</el-descriptions-item>
              <el-descriptions-item label="Expiration">{{ dateFormat(subscription.end) }}</el-descriptions-item>
              <el-descriptions-item label="Price">{{ subscription.price }}</el-descriptions-item>
            </el-descriptions>
          </el-card>
      </div>
      <div class="col-md-3">
        <el-card class="box-card" v-if="new_subscription">
          <div>Upgrade To</div>
          <div style="font-size: 30px">{{ new_subscription.name }}</div>
          <hr>

          <el-descriptions class="margin-top" title="Information" :column="1">
            <el-descriptions-item label="Start">{{ dateFormat(new_subscription.start) }}</el-descriptions-item>
            <el-descriptions-item label="Expiration">{{ dateFormat(new_subscription.end) }}</el-descriptions-item>
            <!-- <el-descriptions-item label="Auto Renew">{{ new_subscription.auto_renew }}</el-descriptions-item> -->
            <el-descriptions-item label="Price">{{ new_subscription.price }}</el-descriptions-item>
          </el-descriptions>

          <el-radio v-model="new_subscription.option" :label="0">Monthly</el-radio>
          <el-radio v-model="new_subscription.option" :label="1">Yearly</el-radio>

          <el-button type="warning" plain @click="onShowPlans()">Change Plan</el-button>
          
        </el-card>

      </div>

      <div class="col-md-3">
          <el-card class="box-card" v-if="new_subscription">
            <div>Summary</div>
            <hr>
            <div>
              <div>Current Plan {{ subscription.name }} {{ subscription.price }}</div>
              <div>New Plan {{ new_subscription.name }} {{ new_subscription.price }}</div>
              <div style="font-size: 20px">Amount to Pay: ${{ getAmountToPay() }}</div>
              <hr>
            <el-button type="success" plain @click="onShowPay()">Proceed to Payment</el-button>
            </div>
          </el-card>
        </div>

    </div>
    <div class="row">
      <div class="col-md-6">

      </div>
    </div>
  </div>
</template>
<script>

import { Plan } from '@/resources/plan'
import PaySubscription from './PaySubscription.vue'
import Swal from 'sweetalert2'

export default {
  components: { PaySubscription },
  data() {
    return {
      plans: [],
      loading: false,
      subscription: {},
      new_subscription: null,
      no_plan: false,
      selected_plan: null,
      showPlans: true,
      showPay: false
    }
  },
  watch: {
    'new_subscription.option': {
      handler: function (newVal) {
        this.new_subscription.price = this.new_subscription.option == 0 ?
          '$ ' + this.new_subscription.price_per_month + '/Month'
          : '$ ' + this.new_subscription.price_per_year + '/Year'

        this.new_subscription.amount = this.new_subscription.option == 0 ?
          this.new_subscription.price_per_month
          : this.new_subscription.price_per_year

        let end = this.$moment()
        this.new_subscription.end = this.new_subscription.option == 0 ? end.add(1, 'M') : end.add(1, 'Y');
        this.new_subscription.amount = this.getAmountToPay()
        this.new_subscription.price = "$"+ this.getAmountToPay()+" upgrade"
      },
      deep: true
    }
  },
  created: function () {
    let plan = this.$store.state.user?.company?.plan
    this.getPlans()
    let company_plan = this.$store.state.user?.company?.company_plan
    if (!plan) {
      this.no_plan = true
      return false
    }
    this.selected_plan = Object.assign({}, plan)
    this.subscription = this.$store.state.user?.company?.subscription
    this.subscription.price_per_month = parseFloat(plan.price_per_month)
    this.subscription.price_per_year = parseFloat(plan.price_per_year)
    this.updateNewPlan()
  },
  methods: {
    getAmountToPay() {
      let newAmount = this.new_subscription.option == 1 ? this.new_subscription.price_per_year : this.new_subscription.price_per_month
      let oldAmount = this.subscription.option == 1 ? this.subscription.price_per_year : this.subscription.price_per_month

      return parseFloat(newAmount - oldAmount).toFixed(2)
    },
    onShowPay() {
      this.showPay = true
    },
    dateFormat(date) {
      return this.$moment(date).format('MMM DD, YYYY')
    },
    updateNewPlan() {
      let start = this.$moment()
      let end = this.$moment()

      let oldSubscription = this.$store.state.user?.company?.subscription

      this.new_subscription = {
        id: this.selected_plan.id,
        name: this.selected_plan.name,
        start: start,
        end: end,
        auto_renew: 'no',
        price: 0,
        price_per_month: this.selected_plan.price_per_month,
        price_per_year: this.selected_plan.price_per_year,
        option: 0,
        amount: 0,
        type: 'upgrade',
        description: 'Upgrade Subscription'
      }

      this.new_subscription.price = this.new_subscription.option == 0 ?
        '$ ' + this.new_subscription.price_per_month + '/Month'
        : '$ ' + this.new_subscription.price_per_year + '/Year'

      this.new_subscription.amount = this.new_subscription.option == 0 ?
        this.new_subscription.price_per_month
        : this.new_subscription.price_per_year

      this.new_subscription.end = this.new_subscription.option == 0 ? end.add(1, 'M') : end.add(1, 'Y');
      this.new_subscription.amount = this.getAmountToPay()
      this.new_subscription.price = "$" + this.getAmountToPay() + " upgrade"
    },
    handleClose() {
      this.showPlans = false
      this.showPay = false
    },
    selectPlan(plan) {
      this.selected_plan = Object.assign({}, plan)
      this.showPlans = false
      this.updateNewPlan()
    },
    onShowPlans() {
      this.showPlans = true
    },
    getExpiration() {
      return this.$moment(this.subscription.expiration).format('MMM DD, YYYY')
    },
    getStarted() {
      return this.$moment(this.subscription.start).format('MMM DD, YYYY')
    },
    getPlans() {
      Plan.get({ params: { status: 1, page: 1, per_page: 999 } })
        .then(result => {
          this.plans = []
          let self = this
          result.data.plans.forEach(plan => {
            if (plan.level > self.selected_plan.level) self.plans.push(plan)
          });
        })
        .catch(() => {

        }).finally(() => {

        })
    },
    async success() {
      this.handleClose()
      const { value: res } = await Swal.fire({
        title: "Thank you for upgrading",
        text: "Click ok to redirect to your dashboard",
        icon: "success",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      })

      this.$router.push({ name: 'Home', query: { refresh: true } })
    },
  }
}
</script>
<style lang="scss"></style>
