<template>
  <div v-if="user" >
    <el-dialog
      title="Office Policy"
      :visible.sync="showPolicy"
      width="40%"
      :before-close="handleClose">

      <all-policy @accept="accept = $event"></all-policy>
      <br>
      <el-alert type="error" class="mb-3">Please read and accept the terms and conditions for Office and Card payment agreements</el-alert>
      <payment-button
      :disabled="!accept"
        v-if="booking"
              :amount="booking.price" 
              :label="'$' + booking.price" 
              @success="onSuccessPayment()" :type="'meeting_room_booking'" :code="booking.code" :description="booking.payment_description"></payment-button>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showPolicy = false">Cancel</el-button>
      </span>
    </el-dialog>

    <el-card>
      <div slot="header" class="clearfix">
        <span>Summary</span>
      </div>
      <div  v-if="booking">
        <div>
          <el-tag type="success" v-if="booking.status == 0" style="margin-left: 0">Unpaid</el-tag>
          <el-tag type="primary" v-if="booking.status == 2" style="margin-left: 0">Booked</el-tag>
        </div>
        <div>{{ booking.meeting_room.name }} - {{ booking.meeting_room.office.name }}</div>
        <div>Capacity: {{ booking.meeting_room.capacity }} people</div>
        <div>Address: {{ booking.meeting_room.office.address.full_address }}</div>
        <div>Total Hours: {{ booking.hours.length }} hrs</div>
        <div>
          <el-tag class="ml-0">{{ booking.date }} | {{ booking.time }}</el-tag>
        </div>
        <div v-if="booking && booking.status == 0">
          <el-button  class="btn-block mb-3 " type="success" @click="proceedToPayment()">Proceed to Payment</el-button>
        </div>
      </div>
      <div v-if="!booking">No booking selected yet.</div>
    </el-card>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import PaymentButton from '../../../UIComponents/PaymentButton.vue';
import { MeetingRoom } from '@/resources/meeting_room'
import Swal from 'sweetalert2'
import AllPolicy from '../../Policies/AllPolicy.vue';


export default {
  components: { PaymentButton, AllPolicy },
  props: ['selected'],
  data () {
    return {
      accept: false,
      loading: false,
      booking: null,
      showPolicy: false
    }
  },
  watch: {
    selected: function () {
      if (this.selected) {
        this.booking = Object.assign({}, this.selected)
      }
    },
    booking: function () {
      let currentCode = this.$route.query.booking_code
      if (this.booking && this.booking.code !== currentCode)
       this.$router.replace({
        path: this.$route.path,
        query: { booking_code: this.booking.code },
        replace: true
      })
    }
  },
  created: function () {
    if (this.selected) this.booking = Object.assign({}, this.selected)
    if (this.$route.query.booking_code) {
      this.getBooking(this.$route.query.booking_code)
    }
  },  
  methods: {
    handleClose() {
      this.showPolicy = false
    },
    proceedToPayment() {
      this.showPolicy = true
    },
    getBooking(code) {
      this.loading = false
      MeetingRoom.getBookingByCode(code, {})
        .then(result => {
          this.booking = result.data.booking
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
    getTimeSlotText(selected_hours) {
      let hours = []
      selected_hours.forEach(sh => {
        hours.push(sh)
      });

      return this.getTimeSlotsFromHours(hours)
    },
    async onSuccessPayment() {
      let txt = "You have successfully booked a room"
      if (this.booking && this.booking.meeting_room.type == 1) txt = "You have successfully booked a co-working space"

      let html = "<p>"+txt+"</p>"
      html += "<p>Thank you for proceeding with the payment. By completing the payment process,"+
        "you confirm that you have read, understood, and agreed to be bound by the Terms and Conditions" +
        "and Agreement for Credit Card Payment outlined above.<p>" +
        "<p>If you have any questions or require further clarification, please contact our customer support team. We value your business and look forward to providing you with exceptional services.</p>" +
        "<p>Thank you for choosing Biz-Depot."
            
      const { value: res } = await Swal.fire({
        title: "Success",
        html: html,
        icon: "success",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      })

      if (this.booking && this.booking.meeting_room.type == 1) this.$router.push({ name: 'DeskReservationBookingView', params: { code: this.booking.code } })
      if (this.booking && this.booking.meeting_room.type != 1) this.$router.push({ name: 'MeetingRoomBookingView', params: { code: this.booking.code } })
    },
  },
  computed: mapState(['user']),
}
</script>
<style lang="scss"></style>
