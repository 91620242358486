<template>
  <div class="row">

    <el-dialog
      title="Set Referrer"
      :visible.sync="showReferrer"
      width="400px"
      >
      <set-referrer :user="selected" v-if="selected" @update="getUsers()"></set-referrer>
    </el-dialog>
    
  <el-drawer
    
    title="Attention"
    :visible.sync="showDelete"
    direction="rtl"
    :before-close="handleClose">
    <div style="padding: 10px;">
      <p>Remove this item? </p>
      <p-button type="success" size="sm" icon @click="deleteItem()">
        <i class="fa fa-edit"></i>
      </p-button>
    </div>
    
  </el-drawer>

  <el-drawer title="Download Report" :visible.sync="show_download" direction="rtl" :before-close="handleClose">
    <div style="padding: 10px;">
      <download-commissions-report></download-commissions-report>
    </div>
  </el-drawer>


    <div class="col-md-12">
      <div>
        <h4>Users with Referrals</h4>
        </div>
    </div>
    <div class="col-md-12 card">
      
      <div class="card-body row">
        <div class="col-sm-7">
          <el-select
            class="select-default"
            v-model="pagination.perPage"
            placeholder="Per page">
            <el-option
              class="select-default"
              v-for="item in perPageOptions"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="col-md-2">
            <p-button type="primary" size="sm" style="float:right" @click="handleShowReport()">
              <i class="fa fa-download"></i> Report
            </p-button>
          </div>
        <div class="col-sm-3">
          <div class="pull-right">
            <fg-input class="input-sm"
                      placeholder="Search"
                      v-model="term"
                      addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <!-- <div class="col-md-12">
          <el-radio v-model="status" :label="1">With Referrals</el-radio>
          <el-radio v-model="status" :label="2">No Referrals</el-radio>
        </div> -->
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped"  header-row-class-name="text-primary"
                    :data="users"
                    style="width: 100%">
                    <el-table-column type="expand">
                  <template slot-scope="props">
                    <div style="padding-left: 50px">
                      <p>Roles: {{ props.row.role_names.join(", ") }}</p>
                    </div>
                  </template>
                </el-table-column>
            <el-table-column v-for="column in userColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :prop="column.prop"
                             :label="column.label">
            </el-table-column>
             <el-table-column
                  :min-width="120"
                  class-name="td-actions"
                  label="Referral Code">
                  <template slot-scope="props">
                    <span v-if="props.row.user_referral">{{ props.row.user_referral.code }}</span>
                  </template>
                </el-table-column>
            <el-table-column
                :min-width="120"
                class-name="td-actions"
                label="Referrals Count">
                <template slot-scope="props">
                  <el-tag v-if="props.row.referred_clients_count">{{ props.row.referred_clients_count }}</el-tag>
                  <span v-else class="gray-text">0</span>
                </template>
              </el-table-column>

               <el-table-column
                  :min-width="120"
                  class-name="td-actions"
                  label="Referred by">
                  <template slot-scope="props">
                    <div v-if="props.row.referred_by">{{  props.row.referred_by.name }}</div>
                    <div v-else>
                      <el-link @click="onShowSetReferrer(props.row)">Set</el-link>
                    </div>
                  </template>
                </el-table-column>

              
            <el-table-column
              :min-width="150"
              fixed="right"
              class-name="td-actions"
              label="Actions">
              <template slot-scope="props">
                <el-tooltip class="item" effect="dark" content="View Commissions" placement="top-start" v-if="props.row.user_referral">
                  <el-link type="danger" size="small" class="ml-1" :href="'user-referrals/'+props.row.user_referral.code+'/commissions'"><i class="el-icon-s-goods"></i></el-link>
                </el-tooltip>

                
               
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  import { UserReferral } from '@/resources/user_referral'

  import Vue from 'vue'
  import {Table, TableColumn, Select, Option, Drawer} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
import SetReferrer from './SetReferrer.vue'
import DownloadCommissionsReport from './DownloadCommissionsReport.vue'
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(Drawer)

  export default{
    components: {
      PPagination,
      SetReferrer,
      DownloadCommissionsReport
    },
    data () {
      return {
        status: 1,
        showForm: false,
        showDelete: false,
        users: [],
        perPageOptions: [5,10,15],
        pagination: {
          perPage: 10,
          currentPage: 1,
          total: 0
        },
        showReferrer: false,
        term: null,
        selected: null,
        searchQuery: '',
        show_download: false,
        propsToSearch: ['name', 'email', 'age'],
        userColumns: [
          {
            prop: 'name',
            label: 'Name',
            minWidth: 200
          },
          {
            prop: 'email',
            label: 'Email',
            minWidth: 250
          }
        ],
      }
  },
  watch: {
    status: function () {
      this.getUsers()
    },
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getUsers()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getUsers()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getUsers()
    }
  },
    created: function() {
      this.getUsers()
    },
  methods: {
    onShowSetReferrer(user) {
      this.selected = Object.assign({}, user)
      this.showReferrer = true
    },
      changeStatus(user, status) {
        User.update( {id: user.id, status: status, status_only: true})
            .then(result => {
              this.getUsers()
            })
            .catch(() => {

            }).finally(() => {
              this.loading = false
            })
      },
      handleShowReport() {
        this.show_download = true
        this.selected = {}
      },
      handleClose() {
        this.show_download = false
      },
      handleDelete(data) {
        this.showDelete = true
      },
      deleteItem() {

      },
    getUsers() {
      this.showReferrer = false
        this.selected = null
        UserReferral.get({
          params: {
            term: this.term, status: this.status, page:
              this.pagination.currentPage, per_page: this.pagination.perPage
          }
        })
        .then(result => {
        if(result.data.users)
            this.users = result.data.users
            this.pagination = result.data.meta
        })
        .catch( () => {

        }).finally( () => {
          this.loading = false
        })
      }
    }
  }
</script>
<style lang="scss">

</style>
